@import '../../../styles/fonts';
@import '../../../styles/colors';
@import '../../../styles/layout';

.role-name {
  font-size: var(--fs-md);
  color: var(--dark);
}

.role-description {
  font-size: var(--fs-sm);
  color: var(--gray);
}
