@import '../../styles/fonts.css';
@import '../../styles/colors.css';
@import '../../styles/layout.css';

:root {
  --trayWidth: 760px;
  --interiorContainerHeight: calc(100vh - var(--fixedHeaderOffset));
}

.tray-container {
  box-sizing: border-box;
  width: var(--trayWidth);
  margin-top: 110px;
  height: var(--interiorContainerHeight);
  border-left: 1px solid var(--ash);
  z-index: 200;
  background-color: var(--white);
  overflow: hidden;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
