@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';
@import '../../../styles/layout.css';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.thumbnail-container {
  position: relative;
}

.status-background {
  position: absolute;
  top: 23px;
  left: 20px;
  height: 12px;
  width: 12px;
  background-color: var(--white);
  border-radius: 50%;
}
