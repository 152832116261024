@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';
@import '../../../../styles/layout.css';

.team-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.team-left {
  display: flex;
  align-items: center;
}

.team-right {
  display: flex;
  align-items: center;
}

.section-title {
  font-size: var(--fs-md);
  font-weight: 500;
}

.total-count {
  margin-left: 1ch;
}

.empty-section-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  color: var(--gray);
}

.user-container {
  min-height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }
}

.user-content-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user-details {
  flex-grow: 1;
  line-height: 1rem;
  text-indent: 1em;
  padding-right: 0.5rem;
  white-space: nowrap;
  overflow: hidden;

  & .name {
    padding-bottom: 3px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & .email {
    color: var(--gray);
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: var(--fs-sm);
  }
}

.spacer {
  padding: 0 0.5ch;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 1rem 1rem;
}
