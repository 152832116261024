@import '../../styles/fonts.css';
@import '../../styles/colors.css';
@import '../../styles/layout.css';

:root {
  --interiorContainerHeight: calc(100vh - var(--fixedHeaderOffset));
  --divider: 1px solid var(--typhoon);
}

.header-container {
  position: fixed;
  top: 0;
  margin-top: var(--topNavHeight);
  z-index: 300;
  width: 100vw;
  background-color: var(--blue);
  height: var(--subNavHeight);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-container {
  display: flex;
  align-items: center;
}

.name-container {
  box-sizing: border-box;
  width: var(--sidePaneWidth);
  padding-left: 1rem;
  color: var(--white);
  font-size: var(--fs-lg);
  font-weight: 500;
}

.tab-button {
  display: inline-block;
  color: var(--white);
  font-weight: 500;
  font-size: var(--fs-md);
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  text-transform: uppercase;

  &.selected {
    background-color: var(--highlight);
  }

  &:hover {
    cursor: pointer;
    background-color: var(--highlight);
  }
}

.button-container {
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
  padding-right: 0.675rem;
  text-align: right;
}

.left-pane {
  display: inline-block;
  width: var(--sidePaneWidth);
  height: var(--interiorContainerHeight);
  background-color: var(--typhoon);
  overflow: auto;
  margin-top: var(--fixedHeaderOffset);
  color: var(--dim);
}

.calendar-container {
  margin-top: var(--fixedHeaderOffset);
  overflow: auto;
}

.table-container {
  margin-top: var(--fixedHeaderOffset);
}

.calendar-toolbar-container {
  height: 3.75rem;
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.calendar-toolbar-left-controls {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.date-label {
  font-weight: 500;
  font-size: var(--fs-lg);
  padding: 0 1rem;
}

.right-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.select-field-container {
  width: 8rem;
}

.loading-container {
  padding: 1rem;
}

.loading-work-type {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 1rem 1rem;
}

.work-order-name:hover {
  cursor: pointer;
}

.column-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
}

.column-row-description {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.column-row-number {
  width: 1rem;
  text-align: center;
}

.add-search-container {
  padding: 1rem;
}

.add-list-container {
  width: 15.875rem;
}

.column-title {
  margin-left: 0.8rem;
  text-transform: capitalize;
}

.additional-column-section-name {
  padding-top: 0.5rem;
  font-size: var(--fs-sm);
  color: var(--gray);
  text-transform: capitalize;
  padding-left: 0.25rem;
}

.column-header {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
