@import '../../../../../styles/layout.css';
@import '../../../../../styles/fonts.css';
@import '../../../../../styles/colors.css';

.section-header {
  text-transform: uppercase;
  color: var(--gray);
  font-size: var(--fs-md);
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.section-container {
  margin-bottom: 1rem;
}

.group-path-title {
  color: var(--gray);
  font-size: var(--fs-sm);
  margin-bottom: 0.5rem;
}

.group-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;

  &:hover {
    cursor: pointer;
  }
}

.left-container {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.name-container {
  display: flex;
  flex-flow: column;
  padding-left: 0.75rem;
}

.title {
  font-size: var(--fs-md);
  font-weight: 500;
  color: var(--dim);
  text-transform: capitalize;
  padding-right: 0.75rem;

  &.selected {
    color: var(--blue);
  }

  &.disabled {
    color: var(--ash);
  }
}
