@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.tree-modal-container {
  height: 21.875rem;
  position: relative;
  padding: 0.5rem;
  overflow: auto;
}

.folder-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
  min-height: 2.5rem;
  padding-right: 0.75rem;

  &:hover {
    cursor: pointer;
  }

  &.selected {
    background-color: var(--ash);
  }
}

.folder-name-container {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row-name-container {
  display: flex;
  flex-flow: column;
  padding-left: 0.75rem;
}

.row-title {
  font-size: var(--fs-md);
  font-weight: 500;
  color: var(--dim);

  &.disabled {
    color: var(--ash);
  }
}

.template-tile {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tile-content {
  display: flex;
  flex-flow: column nowrap;
}

.tile-title {
  font-size: var(--fs-md);
  font-weight: 500;
  color: var(--dim);
}
