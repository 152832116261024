@import '../../../../styles/colors.css';
@import '../../../../styles/fonts.css';

.filter-container-label {
  font-size: var(--fs-sm);
  color: var(--gray);
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  font-weight: 500;
}

.filter-values {
  font-size: var(--fs-md);
  color: var(--dark);
  margin-bottom: 0.5rem;
  line-height: 1.25rem;
}

.button-container {
  margin: 0.325rem 0;
}

.button-divider {
  padding: 0 0.5rem;
}
