@import '../../../styles/fonts';
@import '../../../styles/colors';
@import '../../../styles/layout';

.page {
  height: 100vh;
  width: 100vw;
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--topNavHeight);
  box-shadow: inset 0 3px 0 0 var(--white), 0 1px 3px 0 rgb(0 0 0 / 20%);
}

.content-container {
  box-sizing: border-box;
  height: calc(100vh - var(--topNavHeight));
  display: flex;
  justify-content: center;
  padding-top: 12.5rem;
}

.content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.title {
  font-size: 40px;
  font-weight: 500;
  color: var(--dark);
  margin: 3rem 0;
}

.subtext {
  font-size: var(--fs-md);
  color: var(--dark);
}
