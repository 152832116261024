@import '../../../../../styles/layout.css';
@import '../../../../../styles/fonts.css';
@import '../../../../../styles/colors.css';

:root {
  --taskDetailHeaderHeight: 70px;
}

.task-header-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem 0 1.5rem;
}

.task-header-title {
  flex: 1 1 auto;
  font-size: var(--fs-xl);
  font-weight: 500;
  border-bottom: 1px solid var(--white);
  padding: 5px 0;
}

.task-header-title-hover {
  flex: 1 1 auto;
  font-size: var(--fs-xl);
  font-weight: 500;
  border-bottom: 1px solid var(--white);
  padding: 5px 0;

  &:hover {
    cursor: pointer;
    border-bottom: 1px solid var(--silver);
    background: var(--typhoon);
  }
}

.action-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--ash);
  border-radius: 2px;
  height: 2.25rem;
  padding: 0 0.625rem;
  margin-right: 0.6125rem;
  font-weight: 500;
  cursor: pointer;
  user-select: none;

  &.completed {
    background-color: var(--ash);
  }
}

.options-wrapper {
  display: inline-block;
}

.action-button-text {
  text-transform: uppercase;
  white-space: nowrap;
  padding-left: 0.25rem;
}

.modal-container {
  padding: 0 2.5rem 2.5rem 2.5rem;
  margin-bottom: 2rem;
}

.modal-spinner-container {
  padding-top: 2rem;
  padding-bottom: 4rem;
  height: 20vh;
}

.action-buttons-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  padding-left: 1rem;
  margin-right: -0.625rem;
}

.task-name-textfield-container {
  flex: 1 1 auto;
  position: relative;
}

.button-container {
  right: 0;
  bottom: -1.5rem;
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background: var(--white);
  z-index: 300;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  margin-left: 0.25rem;
  box-shadow: var(--muiDropdownBoxShadow);

  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.2) 0 1px 6px, rgba(0, 0, 0, 0.2) 0 1px 4px;
  }
}

.controls-spinner-container {
  margin-right: 2rem;
  width: 10rem;
}

.required-modal {
  position: relative;

  &:after {
    content: '*';
    color: var(--red);
    position: absolute;
    right: -1em;
    top: 50%;
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 1rem 1rem;
}
