@import '../../../styles/colors.css';
@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';

.modal-container {
  display: flex;
  flex-flow: column nowrap;
  padding: 0 2rem 2rem 2rem;
  overflow: hidden;
}
