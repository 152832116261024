@import '../../styles/layout.css';

.thumbnail-container {
  position: relative;
}

.pending-background {
  position: absolute;
  top: 18px;
  left: 20px;
  height: 20px;
  width: 20px;
  background-color: var(--white);
  border-radius: 50%;
}

.user-thumbnail {
  box-sizing: border-box;
  display: inline-block;
  width: var(--userThumbnail);
  height: var(--userThumbnail);
  border-radius: 50%;

  &.large {
    width: 60px;
    height: 60px;
  }
}
