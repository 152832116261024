@import '../../styles/layout.css';
@import '../../styles/colors.css';
@import '../../styles/fonts.css';

.empty-dashboard-container {
  height: 75vh;
  min-height: 350px;
  margin: 0 2rem;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: var(--mist);
  overflow: hidden;
}

.empty-dashboard-title {
  margin: 0.5rem 0;
  font-size: var(--fs-xxxl);
  color: var(--dark);
  font-weight: 300;
}

.empty-dashboard-subtitle {
  font-size: var(--fs-md);
  color: var(--dim);
}

.empty-dashboard-customize-button {
  color: var(--white);
  background-color: var(--deep-blue);
}
