@import '../../../../../../../styles/colors.css';
@import '../../../../../../../styles/layout.css';
@import '../../../../../../../styles/fonts.css';

.section-header {
  box-sizing: border-box;
  display: flex;
  background: var(--white);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.25rem;
  border-bottom: 1px solid var(--ash);
  width: 100%;
}

.section-content {
  box-sizing: border-box;
  display: flex;
  background: var(--white);
  flex-direction: column;
  align-items: flex-start;
  font-size: var(--fs-md);
  line-height: var(--lh-lg);
  color: var(--dark);
  padding: 0.75rem 1.25rem 0.75rem 2.5rem;
  width: 100%;

  &.summary {
    border-bottom: 1px solid var(--ash);
  }

  &.hide {
    display: none;
  }
}

.empty-list {
  color: var(--gray);
}

.summary-item-header {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  padding-bottom: 0.75rem;
  width: 100%;
}

.summary-item-content {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  white-space: pre-line;
  overflow-wrap: break-word;
  padding-bottom: 1.25rem;
  width: 100%;
}

.field-container {
  display: flex;
  align-items: center;
  max-width: 33.75rem;
  padding: 0.5rem 0.5rem 0.5rem 0;
  width: 100%;
}

.enum-single-nested-container {
  display: flex;
  flex-flow: row wrap;
}
