@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.title-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 3.125rem;
  padding: 0 1.25rem;
  border-bottom: 1px solid var(--ash);
  border-top: 1px solid var(--ash);
}

.content-container {
  padding: 1.25rem 3.125rem;
  color: var(--dark);
  display: flex;
  flex-direction: column;
}

.spinner-container {
  position: absolute;
  top: 1rem;
  right: 7rem;
  width: 100%;
}

.list-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 0.3125em 0;
}

.label {
  color: var(--gray);
  width: 50%;

  &.edit {
    padding-bottom: 0.7rem;
  }
}

.value {
  width: 50%;
  color: var(--dark);
  white-space: pre-line;
}

.time-approvers-modal {
  padding: 1.5rem 0;
}
