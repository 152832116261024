@import '../../../../../styles/layout.css';
@import '../../../../../styles/fonts.css';
@import '../../../../../styles/colors.css';

.view-row {
  margin-bottom: 1.25rem;
}

.replenishment-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}

.row-title {
  font-size: var(--fs-sm);
  color: var(--dim);
  padding-bottom: 0.25rem;
}

.created-subtext {
  color: var(--dim);
}

.replenishment-form {
  box-sizing: border-box;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--ash);
  border-radius: 4px;
  margin-bottom: 1rem;
}

.replenishment-cell {
  width: 7rem;

  &.long {
    width: 12rem;
  }
}

.replenishment-title {
  font-weight: 500;
  color: var(--dark);
  margin-bottom: 1.25rem;
}

.add-replenishment-buttons {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.approval-controls {
  margin-top: 1rem;
}

.sub-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.approval-form {
  margin-top: 1rem;
  border: 1px solid var(--ash);
  padding: 1rem;
}

.form-title {
  font-weight: 500;
  margin-bottom: 1rem;
}

.input-row {
  margin-bottom: 1rem;

  &.quantity {
    margin-bottom: 2rem;
  }
}

.approve-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.form-container {
  box-sizing: border-box;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--ash);
  border-radius: 4px;
}

.form-subtext {
  color: var(--gray);
}

.remaining-subtext {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 3rem;
  width: 100%;
  margin-top: 1.5rem;
  padding: 0 1rem;
  border-radius: 4px;
  background-color: var(--lightGreenHighlight);

  &.warning {
    background-color: var(--lightOrangeHighlight);
  }
}

.calculate-button {
  color: var(--blue);
  font-weight: 500;
  font-size: var(--fs-md);

  &:hover {
    cursor: pointer;
  }
}

.create-buttons {
  margin-top: 1.5rem;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.form-input-row {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.input-container {
  margin-top: 1.5rem;
}
