@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

:root {
  --interiorContainerHeight: calc(100vh - var(--detailFixedHeaderOffset));
  --headerHeight: 50px;
}

.center-pane {
  height: var(--interiorContainerHeight);
  background-color: var(--white);
  width: 70%;
  margin-left: 15%;
  overflow: auto;
}

.documents-container {
  height: var(--interiorContainerHeight);
  background-color: var(--typhoon);
}
