@import '../../styles/colors.css';
@import '../../styles/fonts.css';
@import '../../styles/layout.css';

:root {
  --topMargin: calc(var(--fixedHeaderOffset));
}

.header-container {
  position: fixed;
  top: 0;
  margin-top: var(--topNavHeight);
  z-index: 200;
  width: 100vw;
  height: var(--subNavHeight);
  background-color: var(--green);
}

.header-content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
}

.header-title {
  font-weight: 500;
  color: var(--white);
  font-size: var(--fs-lg);
}

.analytics-portal-content {
  box-sizing: border-box;
  background-color: var(--fog);
  padding-top: var(--topMargin);
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
}

.analytics-folder-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  background: var(--ash);
  height: 2.5rem;
  margin-top: 0.5rem;
  border-left: 3px solid var(--blue);
  padding-left: 1rem;

  &:hover {
    cursor: pointer;
  }
}

.left-block {
  width: var(--sidePaneWidth);
  height: 100%;
  background-color: var(--typhoon);
  overflow-y: auto;
}

.right-block {
  display: flex;
  width: 100%;
  background-color: var(--white);
  flex-direction: column;
}

.analytics-subheader {
  margin: 0 1rem;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
  font-weight: 500;
}

.loading-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-container {
  color: var(--gray);
  display: flex;
  background-color: var(--typhoon);
  border-radius: 2px;
  flex-flow: row nowrap;
  align-items: center;
  font-size: var(--fs-md);
  cursor: pointer;
  position: relative;
  height: 30px;

  input {
    background: none;
    border: none;
    outline: none;
    width: 400px;
  }
}

.search-icon {
  display: flex;
  padding-left: 0.625rem;
}

.search-text {
  padding-left: 0.625rem;
}
