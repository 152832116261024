@import '../../../styles/colors.css';
@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';

:root {
  --drawerHeight: calc(100vh - var(--topNavHeight));
  --drawerWidth: 340px;
}

.history-drawer-container {
  position: absolute;
  top: -4px;
  right: -8px;
  width: var(--drawerWidth);
  height: var(--drawerHeight);
  background-color: var(--white);
  z-index: 300;
  box-shadow: var(--muiDropdownBoxShadow);
  display: none;

  &.open {
    display: inline-block;
  }
}

.drawer-header {
  box-sizing: border-box;
  height: 60px;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--ash);
}

.drawer-title-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.drawer-title {
  color: var(--dark);
  font-weight: 500;
  font-size: var(--fs-md);
  padding-left: 0.5rem;
}

.history-items-container {
  height: calc(var(--drawerHeight) - 60px - var(--paginationHeight));
  overflow: auto;
  border-bottom: 1px solid var(--ash);
}

.history-item-container {
  color: var(--dark);
  box-sizing: border-box;
  padding: 10px 20px 20px 20px;
  width: 100%;
  border-bottom: 1px solid var(--ash);
  display: flex;
  flex-flow: column nowrap;
}

.history-item-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
}

.history-item-header-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.profile-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.user-name {
  color: var(--dark);
  font-size: var(--fs-md);
  font-weight: 400;
  padding-left: 0.5rem;
}

.history-date {
  color: var(--gray);
  font-size: var(--fs-md);
  font-weight: 400;
  padding-left: 0.5rem;
}

.history-message {
  font-size: var(--fs-md);
  font-weight: 400;
}

.history-pagination-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 0;
  color: var(--dark);
  font-weight: 400;
  font-size: var(--fs-md);
}
