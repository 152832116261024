@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.header-container {
  box-sizing: border-box;
  position: fixed;
  margin-top: var(--topNavHeight);
  z-index: 200;
  width: 100vw;
  background-color: var(--red);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  height: var(--detailSubNavHeight);
  color: var(--white);
  font-weight: 500;
}

.top-bar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.title-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.navigation-bar {
  margin-top: 0.5rem;
  margin-left: 3.125rem;
}

.icon-container {
  cursor: pointer;
  text-align: center;
  display: inline-block;
  min-width: 6rem;
  opacity: 0.4;
  padding-bottom: 0.6rem;
  margin-right: 0.5rem;

  &.active {
    opacity: 1;
    border-bottom: 3px solid var(--white);
  }

  & .label {
    font-size: var(--fs-sm);
    text-transform: uppercase;
    margin: -0.5rem 0;
    white-space: nowrap;
  }
}

.profile-image-container {
  position: relative;
  z-index: 200;
  display: inline-block;
  width: 3.25rem;
}

.profile-image {
  width: 3.75rem;
  height: 3.75rem;
  border: 8px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  border-radius: 50%;
}

.info-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 0.75rem;
}

.title-content {
  margin-left: 3rem;
}

.title {
  font-size: var(--fs-xl);
  line-height: var(--fs-xxl);
}

.modal-content {
  padding: 0 1.75rem 2rem 1.75rem;
}
