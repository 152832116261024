@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.roles-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.125rem;
  padding-left: 1.5rem;
}

.loading {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.modal-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}
