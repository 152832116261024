@import '../../../../styles/colors.css';
@import '../../../../styles/fonts.css';

.filter-section {
  padding: 0.5rem 1.5rem;
  font-size: var(--fs-md);
  border-bottom: 1px solid var(--ash);
  margin-bottom: 1rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sticky-top {
  position: sticky;
  top: 0;
  background-color: var(--typhoon);
  z-index: 999;
}

.header-title {
  font-size: var(--fs-lg);
  font-weight: 500;
  color: var(--dark);
}

.filter-section-header,
.filter-button-pair,
.filter-date-range-container,
.filter-duration-range-container {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.header-button-container {
  display: flex;
}

.filter-section-header {
  align-items: center;
  font-weight: 500;
  margin-bottom: 1.5rem;
}

.filter-container {
  margin-bottom: 1.5rem;
  padding-left: 1.5rem;
}

.filter-label {
  font-size: var(--fs-sm);
  color: var(--dim);
}

.filter-duration-range-container{
  align-items: flex-end;
}
