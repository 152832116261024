@import '../../styles/colors.css';
@import '../../styles/fonts.css';
@import '../../styles/layout.css';

:root {
  --topMargin: calc(var(--fixedHeaderOffset) + 0.625rem);
}

.analytics-portal-content {
  box-sizing: border-box;
  background-color: var(--fog);
  padding-top: var(--topMargin);
  height: 100%;
  overflow: auto;
}

.analytics-tiles {
  display: flex;
  flex-flow: row wrap;
  background-color: var(--fog);
  margin: 0 auto;

  &.normal {
    width: 88.75rem;
  }

  &.large {
    width: 82.5rem;
  }
}

.analytics-tile-container {
  display: flex;
  flex-flow: column nowrap;
  box-shadow: inset 0 3px 0 0 var(--white), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--white);
  margin: 0.625rem;

  &.normal {
    min-height: 34rem;
    min-width: 43.125rem;
  }

  &.large {
    min-height: 53.125rem;
    min-width: 81.25rem;
  }
}

.loading-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-container {
  position: fixed;
  top: 0;
  margin-top: var(--topNavHeight);
  z-index: 200;
  width: 100vw;
  background-color: var(--green);
  height: var(--subNavHeight);
  display: flex;
  align-items: center;
  color: var(--white);
}

.header-buttons {
  flex: 1 0 auto;
  text-align: right;
}
