@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.header-container {
  box-sizing: border-box;
  position: fixed;
  margin-top: var(--topNavHeight);
  z-index: 500;
  width: 100vw;
  background-color: var(--purple);
  height: var(--subNavHeight);
  color: var(--white);
  padding: 0 1rem;

  &.preview {
    height: var(--detailSubNavHeight);
  }
}

.top-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-right {
  display: flex;
  align-items: center;
}

.title-container {
  display: flex;
  align-items: center;
}

.title-text {
  padding: 0 1rem;
  font-size: var(--fs-lg);
  font-weight: 500;
}

.info-boxes-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  height: 2.5rem;
}

.info-box {
  margin-right: 1.25rem;

  &.label {
    width: 10.5rem;
    white-space: nowrap;
  }

  &.label:first-of-type {
    margin-left: 3rem;
  }

  &.value {
    margin-right: 3rem;
  }

  &.value > div {
    white-space: nowrap;
  }
}

.icon-row {
  margin-top: 0.3rem;
  margin-left: 2rem;
}

.icon-container {
  cursor: pointer;
  text-align: center;
  display: inline-block;
  width: 5rem;
  opacity: 0.4;
  padding-bottom: 0.6rem;
  margin-right: 1.25rem;

  &.active {
    opacity: 1;
    border-bottom: 3px solid var(--white);
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
}

.button-label {
  font-size: var(--fs-sm);
  text-transform: uppercase;
  margin: -6px 0;
}

.status-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border: 1px solid var(--white);
  margin-left: 1rem;
  padding: 5px;
  border-radius: 4px;
}

.status-text {
  font-size: var(--fs-md);
  color: var(--white);
  font-weight: 500;
  text-transform: uppercase;
  padding-left: 0.5rem;
}

.status-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
