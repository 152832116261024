@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.time-sheet-header {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 3.125rem;
  border-bottom: 1px solid var(--ash);
}

.pay-periods-header {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
  height: 3.125rem;
  border-bottom: 1px solid var(--ash);
}

.list-bulk-controls-header {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
  height: 3.125rem;
  border-bottom: 1px solid var(--ash);
}

.left-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &.bold {
    font-weight: 500;
  }
}

.button-container {
  display: flex;
}

.title {
  font-size: var(--fs-md);
  padding-left: 1.25rem;

  &.full-name {
    padding-left: 0;
  }
}

.pay-period-select {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--typhoon);
  padding: 0.5rem 1rem;
  border-radius: 2px;
}

.menu-container {
  margin-left: 1rem;
}

.navigation-container {
  margin-left: 1rem;
}

.summary-row {
  display: flex;
  flex-flow: row nowrap;
  padding: 1.25rem;
  border-bottom: 1px solid var(--ash);
  justify-content: space-between;
}

.summary-item {
  display: flex;
  flex-flow: column nowrap;
  margin-right: 2rem;
}

.common-cells {
  display: flex;
}

.elastic-cells {
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  max-height: 3rem;
  overflow: hidden;
}

.link-cell {
  display: flex;
  align-items: center;
}

.link-cell .summary-item {
  margin-right: 0;
}

.summary-item-title {
  font-size: var(--fs-sm);
  margin-bottom: 0.5rem;
  text-wrap: nowrap;

  &.status {
    margin-bottom: 0.3125rem;
  }
}

.summary-item-value button {
  background: none;
  border: none;
  color: var(--activeBlue);
  padding: 0;
  margin: 0;
  vertical-align: middle;
  cursor: pointer;
}

.summary-item-value {
  text-wrap: nowrap;
}

.status-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.status-name {
  text-transform: capitalize;
  margin-left: 0.3125rem;
}

.empty-title {
  margin-top: 3px;
  font-style: italic;
}

.cutoff-types-list {
  list-style-type: none;
  padding: 0;
  margin: 0.5rem;
  font-size: var(--fs-sm);

  li {
    padding: 0;
    margin: 0;
  }
}
