@import './colors.css';
@import './layout.css';
@import './fonts.css';

:root {
  --interiorContainerHeight: calc(100vh - var(--fixedHeaderOffset));
  --divider: 1px solid var(--typhoon);
}

.left-pane {
  display: inline-block;
  width: var(--sidePaneWidth);
  height: var(--interiorContainerHeight);
  background-color: var(--typhoon);
  overflow: auto;
  margin-top: var(--fixedHeaderOffset);
  color: var(--dim);

  & .detail-left-pane {
    margin-top: 0;
  }
}

.filters-header {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 3rem;
  background-color: var(--typhoon);
  color: var(--dark);
  font-size: var(--fs-lg);
  font-weight: 500;
}

.filters-header-label {
  flex-grow: 1;
  width: 2rem;
  margin: 1rem 0 2rem 1.25rem;
}

.select-filter-wrapper {
  margin: 1rem 1.25rem 0 1.25rem;
}

.select-filter-description-text {
  color: var(--gray);
}

.top-level-asset-filter-toggle {
  margin: 1rem 1.25rem 0 1.25rem;
}

.filter-nav {
  list-style-type: none;
  line-height: 1.75em;
}

.filter-nav-nested {
  list-style-type: none;
  margin-top: 1em;
}

.rotate {
  transform: rotate(-90deg);
}

.attribute-filters-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.inventory-filter-group-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0.25rem 0.75rem 0.25rem 1.25rem;
  border-bottom: 1px solid var(--silver);

  &:first-child {
    border-top: 1px solid var(--silver);
  }
}

.inventory-filter-group-header {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
}

.inventory-filter-group-name {
  color: var(--dark);
  font-size: var(--fs-lg);
  font-weight: 500;
  line-height: 2.875rem;
}

.asset-filter-toggle-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.asset-filter-box {
  width: 55%;
  padding: 0 0.5rem;
  height: 2.5rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.all-inventory {
    width: 40%;
  }

  &.selected {
    color: var(--dark);
    border-radius: 2px;
    background-color: var(--highlight);
  }
}

/* Create / Edit */

.create-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.create-header {
  width: 100%;
  border-bottom: var(--divider);

  & .title {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    font-weight: 500;
    color: var(--dark);
    font-size: var(--fs-lg);
    padding: 20px;
    width: 70%;
  }

  & .buttons {
    display: inline-block;
    width: 30%;
    padding-top: 0.75rem;
  }
}

.create-body {
  margin: 0 3rem;

  & .half {
    width: 50%;
    display: inline-block;
    position: relative;
  }

  & .row {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
  }

  & .duration-container {
    position: relative;
    width: 70%;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
  }

  & .duration-input {
    width: 50px;
    padding-left: 1rem;
  }

  & .duration-suffix {
    padding: 0 1rem 0.75rem 0.5rem;
  }

  & .date-section {
    width: 25%;
    display: inline-block;
    position: relative;
    margin-right: 5%;
  }

  & .estimated-cost:before {
    content: '$';
    font-size: var(--fs-lg);
    color: var(--dark);
    position: absolute;
    top: calc(50% + 0.25em);
    left: -1em;
  }
}

.required {
  position: relative;

  &:after {
    content: '*';
    color: var(--red);
    position: absolute;
    right: -1em;
    top: 50%;
  }
}

.date-range-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 0 1.25rem;
}

.date-range-title {
  color: var(--black0_6);
  margin-left: 1.25rem;
  margin-top: 1.5rem;
  font-size: var(--fs-sm);
}

.category-filter-container {
  width: 90%;
  padding-left: 1rem;
}

.toggle-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  border-top: 1px solid var(--silver);
}

.toggle-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--black);
}
