@import '../../../../../styles/colors.css';
@import '../../../../../styles/layout.css';
@import '../../../../../styles/fonts.css';

.modal-header {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  height: 2rem;
  font-size: var(--fs-xxl);
  color: var(--black);
}

.modal-content {
  display: flex;
  flex-flow: row nowrap;
}

.sidebar-container {
  box-sizing: border-box;
  width: 21.25rem;
  height: 44.75rem;
  border-right: 1px solid var(--ash);
  padding: 1rem 1.25rem;
  overflow: auto;
}

.map-container {
  flex: 1 1 auto;
  height: 44.75rem;
}

.info-row {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: start;
  font-size: var(--fs-sm);
  color: var(--dim);
  padding: 1rem 0;
}

.info-content {
  font-size: var(--fs-sm);
  color: var(--dim);
}

.input-section {
  padding: 1rem 0;
}

.loading-row {
  display: flex;
  align-items: center;
}

.loading-text {
  padding-left: 1rem;
}

.input-row {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 1.5rem;
}

.range-subtext {
  font-size: var(--fs-sm);
  margin-top: 5px;
}
