@import '../../../../../styles/colors.css';
@import '../../../../../styles/layout.css';
@import '../../../../../styles/fonts.css';

.form-container {
  background-color: var(--white);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 40rem;
  width: 100%;
  padding: 0;
}

.form-content {
  display: flex;
  align-items: flex-start;
  flex-flow: row nowrap;
  height: 100%;
  width: 100%;
}

.form-stepper {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-right: 1px solid var(--ash);
  width: 14rem;
  padding: 0.5rem 0;
  height: 100%;
}

.form-spinner {
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 40rem;
  width: 100%;
}
