@import '../../../../styles/layout.css';
@import '../../../../styles/colors.css';

.tab-content {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - var(--detailFixedHeaderOffset));
  max-height: calc(100vh - var(--detailFixedHeaderOffset));
  margin-top: var(--detailFixedHeaderOffset);
  display: inline-block;
}

.filter-panel-container,
.table-container {
  display: inline-block;
  height: 100%;
  overflow: auto;
}

.filter-panel-container {
  width: var(--sidePaneWidth);
  background-color: var(--typhoon);
}

.table-container {
  width: calc(100% - var(--sidePaneWidth));
}
