@import '../../../../../styles/colors.css';
@import '../../../../../styles/layout.css';
@import '../../../../../styles/fonts.css';

:root {
  --containerHeight: 2.25rem;
}

.container {
  height: 2.25rem;
  width: 3.75rem;
  margin-top: var(--mapControlsTopOffset);
  margin-left: 0.625rem;
  background-color: var(--white);
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.menu-container {
  padding: 0.5rem 0;
  width: 168px;
  display: table-cell;
  user-select: none;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.3125rem 1rem;
  font-weight: 600;
  font-size: var(--fs-md);
  box-sizing: border-box;
  overflow: hidden;

  &.selected {
    color: var(--blue);
  }

  &:hover {
    cursor: pointer;
    background-color: var(--fog);
  }
}

.tooltip-wrapper {
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

.tooltip-container {
  visibility: hidden;
  padding: 0 0.3125rem;
  opacity: 0;
  position: absolute;
  background: var(--black0_6);
  color: var(--white);
  font-size: var(--fs-sm);
  white-space: nowrap;
  box-shadow: var(--muiDropdownBoxShadow);
  transition: visibility 0s, opacity 0.25s linear;
  transition-delay: 0.3s;
  top: 3.5625rem;
  right: 0;
}

.tooltip-wrapper:hover .tooltip-container {
  visibility: visible;
  opacity: 1;
}
