@import '../../../../../../styles/layout.css';
@import '../../../../../../styles/fonts.css';
@import '../../../../../../styles/colors.css';

.input-container {
  margin-top: 1.5rem;
}

.input-row {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.data-cell {
  width: 13.75rem;
}

.data-cell-title {
  font-size: var(--fs-sm);
  color: var(--dim);
  padding-bottom: 0.25rem;
}

.quantity-subtext {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 3rem;
  width: 100%;
  margin-top: 1.5rem;
  padding: 0 1rem;
  background-color: var(--blueHighlight);

  &.error {
    background-color: var(--errorBackground);
  }
}

.asset-option-subtext {
  font-size: var(--fs-sm);
  color: var(--gray);
}

.subtext {
  font-size: var(--fs-sm);
  color: var(--dim);

  &.selected-asset {
    margin-top: 5px;
  }

  &.title {
    margin-bottom: 0.5rem;
  }
}

.quantity-transfer-subtext {
  margin-top: 1.5rem;
  font-size: var(--fs-md);
  color: var(--gray);
}
