@import '../../../../../styles/layout.css';
@import '../../../../../styles/fonts.css';
@import '../../../../../styles/colors.css';

.users-list {
  padding: 0.5rem 0;
}

.empty-users-list {
  font-size: var(--fs-md);
  font-weight: 400;
  color: var(--gray);
}

.user-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
  gap: 0.5rem;

  &:hover {
    cursor: pointer;
  }
}

.left-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex-grow: 1;
}

.title {
  font-size: var(--fs-md);
  font-weight: 500;
  color: var(--dim);
  text-transform: capitalize;
  padding-left: 0.75rem;

  &.selected {
    color: var(--blue);
  }

  &.disabled {
    color: var(--ash);
  }
}

.subtext {
  font-size: var(--fs-sm);
  padding-left: 0.75rem;
  color: var(--gray);

  &.disabled {
    color: var(--ash);
  }
}
