@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.header-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.25rem;
  padding-right: 0.5rem;
  height: 3.125rem;
  border-bottom: 1px solid var(--ash);
}

.content {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
}

.empty-container {
  display: flex;
  width: 100%;
  padding-top: 3.75rem;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: var(--gray);
}
