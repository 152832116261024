@import '../../styles/colors.css';
@import '../../styles/fonts.css';
@import '../../styles/layout.css';

:root {
  --toolbarHeight: 2.5rem;
  --headerOffset: 6.875rem;
  --topBarOffset: calc(var(--toolbarHeight) + var(--headerOffset));
  --formContentHeight: calc(100vh - var(--topBarOffset));
  --sidebarWidth: 15rem;
}

.spinner-container {
  position: relative;
  height: 50vh;
}

.form-edit-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.form-edit-section {
  padding-top: var(--topBarOffset);
  width: 100%;
}

.form-pages-container {
  padding-top: var(--headerOffset);
  width: var(--sidebarWidth);
  border-right: 1px solid var(--ash);
}

.form-edit-canvas {
  background-color: var(--typhoon);
  height: var(--formContentHeight);
  width: 100%;
  overflow: auto;
}

.toolbar-container {
  position: fixed;
  top: 0;
  margin-top: var(--headerOffset);
  background-color: var(--white);
  z-index: 200;
  width: 100vw;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: var(--toolbarHeight);
  border-bottom: 1px solid var(--ash);
}

.toolbar-section {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border-right: 1px solid var(--ash);
  padding-left: 0.5rem;
}

.dropdown-button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.625rem;

  &:hover {
    cursor: pointer;
  }
}

.menu-button-title {
  font-size: var(--fs-md);
  color: var(--dark);
  line-height: var(--lh-sm);
  padding: 0 0.625rem;

  &.disabled {
    color: var(--gray);
  }
}

.toolbar-button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.625rem;

  &:hover {
    cursor: pointer;
  }
}

.form-pages-navigation {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: var(--toolbarHeight);
}

.add-tab-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.add-tab-text {
  font-size: var(--fs-md);
  color: var(--gray);
  text-transform: uppercase;
}

/* min-height is 96% to fit paper to screen with small space
underneath when a small amount of form fields are added */

.form-edit-paper {
  box-sizing: border-box;
  width: 80%;
  min-height: 96%;
  padding: 1.875rem 3.125rem;
  margin: 1rem 10%;
  background: var(--white);
  box-shadow: var(--muiDropdownBoxShadow);
}

.field-container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  margin-bottom: 1rem;
  border: 1px solid var(--fog);
  background-color: var(--white);
}

.field-box-header {
  box-sizing: border-box;
  width: 100%;
  height: 2.5rem;
  padding-right: 1rem;
  background-color: var(--fog);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.field-content-container {
  padding: 1.25rem 2.5rem;
}

.field-left-icons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.type-title-container {
  padding-left: 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.type-title-text {
  padding-left: 0.5rem;
  text-transform: capitalize;
  font-size: var(--fs-md);
  color: var(--gray);
}

.field-right-icons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.multi-input-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.options-input-container {
  width: 66%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.options-number {
  padding-right: 1rem;
}

.section-text {
  padding-top: 1rem;
}

.add-option-text {
  color: var(--gray);
}

/* Non-standard percentages below are used to leverage flexbox space-between.
example: two div with 48% width next to each other with space-between will
allow for dynamic 4% worth of padding in between without having to set padding. */

.double-input {
  width: 48%;
}

.numeric-title {
  width: 34%;
}

.numeric-unit {
  width: 12%;
}

.numeric-description {
  width: 48%;
}

.text-limit-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-content: center;
  width: 100%;

  &.option {
    width: 60%;
  }
}

.text-limit {
  color: var(--gray);
  font-size: var(--fs-sm);

  &.red {
    color: var(--red);
  }
}

.page-list-item {
  box-sizing: border-box;
  font-size: var(--fs-md);
  padding: 0.5rem 0.5rem 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  min-height: 2.5rem;
  border-left: 3px solid transparent;
  background-color: var(--white);
  width: var(--sidebarWidth);

  &.selected {
    background-color: var(--typhoon);
    border-left: 3px solid var(--blue);
  }

  &.module-sub-page {
    padding-left: 1.5rem;
  }
}

.page-item-left-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.page-name-container {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 9rem;
}

.page-item-left-text {
  padding-left: 1rem;
}

.toggle-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.modal-spinner-container {
  padding: 2.5rem;
  height: 20vh;
}

.modal-container {
  padding: 0 2.5rem 2.5rem 2.5rem;
  margin-bottom: 2rem;
}

.icon-container {
  display: inline-block;
}

.schema-menu-item-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
  padding: 0.25rem 1.5rem;

  &.selected {
    padding: 0;
  }
}

.schema-menu-item-title {
  padding-left: 0.3125rem;
  font-size: var(--fs-md);
  color: var(--black);
}

.modal-content-container {
  padding: 1.5rem 0;
}

.select-field-container {
  width: 66%;
}

.schema-sub-text {
  font-size: var(--fs-sm);
}

.modal-section-title {
  font-size: var(--fs-md);
  color: var(--dark);
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.checkbox-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.schema-field-container {
  border: 1px solid var(--typhoon);
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
}

.schema-field-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.schema-left-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.schema-header-title {
  padding-left: 0.5rem;
  font-size: var(--fs-md);
  font-weight: 500;
}

.attribute-group-block-container {
  padding: 0.5rem 0;
}

.attribute-list-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

.attribute-block {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  color: var(--dark);
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  height: 2.125rem;

  &:hover {
    cursor: pointer;
  }
}

.attribute-group-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.select-all-button {
  font-weight: 500;
  color: var(--blue);
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
}

.module-modal-container {
  padding: 1.5rem;
}

.form-module-item {
  background-color: var(--white);
  border: solid 1px var(--ash);
  border-radius: 6px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-bottom: 1rem;

  &.selected {
    border: 1px solid var(--blue);
    background-color: var(--blueHighlight);
  }

  &.disabled {
    cursor: default;
  }
}

.ci-view {
  margin-top: 1rem;
}

.form-module-content {
  color: var(--dark);
  font-size: var(--fs-md);
  margin: 1rem 0;
}

.form-module-info {
  width: 100%;
  padding-left: 1.25rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.form-module-modal-title {
  padding-bottom: 0.3125rem;
  font-size: var(--fs-md);
  color: var(--dark);
  font-weight: 500;

  &.disabled {
    color: var(--gray);
  }
}

.form-module-description {
  font-size: var(--fs-sm);
  color: var(--gray);

  &.disabled {
    color: var(--gray);
  }
}

.form-module-added {
  font-style: italic;
  color: var(--gray);
  font-size: var(--fs-sm);
}

.form-module-title-block {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.form-module-title {
  font-size: var(--fs-xl);
  margin-left: 1rem;
  font-weight: 500;
}

.form-module-description-block {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.form-module-description-text {
  font-size: var(--fs-md);
}

.iframe-size-container {
  display: flex;
  align-items: center;
  color: var(--dark);
  font-size: var(--fs-md);
  margin-top: 1rem;
}

.iframe-size-label {
  vertical-align: bottom;
  margin-right: 1rem;
}

.iframe-preview {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.header-container {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  margin-top: var(--topNavHeight);
  z-index: 200;
  width: 100vw;
  background-color: var(--blue);
  height: var(--subNavHeight);
  padding: 0 1rem;
}

.name-container {
  display: flex;
  align-items: center;
  color: var(--white);
  font-size: var(--fs-lg);
  font-weight: 500;
}

.form-builder-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.edit-modal {
  padding-top: 2rem;
}

.edit-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-bottom: 2rem;
}

.schema-option {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
