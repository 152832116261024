@import '../../../styles/fonts';
@import '../../../styles/colors';
@import '../../../styles/layout';

.modal-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &.work-template {
    margin-bottom: 2rem;
  }

  &.name {
    margin-bottom: 2rem;
  }

  &.due-date {
    margin-bottom: 1rem;
  }

  &.checkbox {
    margin-top: 0.5rem;
    padding-left: 2.75rem;
  }
}

.required {
  position: relative;
  width: 100%;

  &:after {
    content: '*';
    color: var(--red);
    position: absolute;
    right: -1em;
    top: 50%;
  }
}

.modal-checkbox-label {
  color: var(--dark);
  font-size: var(--fs-md);
}

.modal-custom-date-label {
  color: var(--dark);
  font-size: var(--fs-md);
}

.modal-custom-due-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 3.5rem;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
}

.empty-work-templates-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.empty-work-templates {
  color: var(--gray);
  font-size: var(--fs-md);
  line-height: 1.25rem;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
