@import '../../styles/colors.css';
@import '../../styles/fonts.css';
@import '../../styles/layout.css';

.background {
  height: auto;
}

.main {
  width: 52rem;
  min-height: 70.125rem;
  background-color: var(--white);
  margin: 0 auto;
}

.collapse-border {
  width: 100%;
  border-collapse: collapse;
}

.details {
  display: flex;
  flex-wrap: wrap;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.col-1 {
  flex: 0 0 20rem;
  margin-right: 0.3125rem;
}

.col-2 {
  flex: 1;
}

.empty-photo-section {
  background-color: var(--ash);

  &.gwrr {
    height: 18rem;
  }

  &.idl {
    height: 100%;
  }

  &.odot {
    height: 18rem;
  }
}

.group-container {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.header-title {
  font-size: 0.75rem;
  font-weight: 900;
  color: var(--dark);
  background-color: var(--blueHighlight);
  border: 1px solid var(--typhoon);
  padding-left: 0.625rem;
  height: 1.625rem;
  display: flex;
  align-items: center;
  page-break-after: avoid;
}

.task-title {
  font-weight: 900;
  font-size: 1rem;
  color: var(--black);
  padding-top: 1.5rem;
}

.task-subtitle {
  color: var(--black);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.task-group-header {
  background-color: var(--highlight);
  font-weight: 900;
  text-align: center;
  border: solid 1px var(--ash);
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.fhwa-element-title {
  display: flex;
  font-size: 0.75rem;
  font-weight: 900;
  color: var(--dark);
  border: 1px solid var(--typhoon);
  height: 1.625rem;
  text-align: left;
  vertical-align: middle;
  align-items: center;
}

.textbox-table-title {
  font-size: 0.75rem;
  font-weight: 900;
  color: var(--dark);
  background-color: var(--blueHighlight);
  border: 1px solid var(--typhoon);
  padding-left: 0.625rem;
  height: 1.625rem;
  text-align: left;
  vertical-align: middle;
}

.textbox-table-row {
  padding-left: 0.625rem;
  font-size: 0.625rem;
  overflow-wrap: break-word;
}

.textbox-table-row-title {
  padding-left: 0.625rem;
}

.content {
  border: 1px solid var(--typhoon);
  margin-bottom: 0.5rem;
}

.row {
  display: flex;
  padding-left: 0.625rem;
  font-size: 0.625rem;
}

.value {
  color: var(--black);
  white-space: pre-line;
  display: block;
  text-align: left;
  overflow: hidden;
  overflow-wrap: break-word;
  position: relative;
  align-self: flex-end;
}

.table-header {
  font-size: 0.75rem;
  font-weight: 700;
}

.table-header-cell {
  font-size: var(--fs-sm);

  &.comment {
    width: 100%;
  }
}

.table-subheader {
  font-size: var(--fs-sm);

  &.left {
    text-align: left;
  }
}

.table-value {
  font-size: 0.6875rem;

  &.no-bottom-border {
    border-bottom: solid 1px var(--white);
  }

  &.add-bottom-border {
    border-bottom: solid 1px var(--ash);
  }
}

.table-wrapper {

  & table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0.5rem;
    margin-bottom: 0.5rem;

    & tr,
    & th {
      border: solid 1px var(--ash);
      padding: 0.5rem;
    }

    & th {
      background-color: var(--highlight);
    }
  }

  & tr > td {
    border-left: solid 1px var(--ash);
    border-right: solid 1px var(--ash);
    padding: 0 0.5rem;
    white-space: pre-line;
  }
}

.photos-container {
  width: 100%;
  border: 1px solid var(--typhoon);
}

.photo-tile-container {
  display: flex;
  flex-flow: wrap;
  margin-bottom: 0.3125rem;
}

.main-photo-container {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  margin-bottom: 0.5rem;
  text-align: right;
}

.photo-tile {

  &.gwrr {
    display: flex;
    flex-flow: column;
    width: 23.5rem;
    height: 22rem;
  }

  &.odot {
    height: 15rem;
    width: 18rem;
    padding: 1rem 2rem 0.5rem 3rem;
  }

  &.idl {
    margin: 0.5rem auto;
    height: 23rem;
    width: 27rem;
    text-align: center;
  }
}

.img-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.img {
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
}

.page-break-avoid {
  max-width: 100%;
  margin-bottom: 0.5rem;
  display: inline;
}

@media print {
  
  .page-break {
    page-break-after: always;
  }

  .page-break-avoid {
    page-break-inside: avoid;
  }
}
