@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 3.125rem;
  padding: 0 0.5rem 0 1.25rem;
  border-bottom: 1px solid var(--ash);
}

.content {
  padding: 1rem 3rem;
}

.role-label-container {
  display: flex;
  flex-direction: column;
  color: var(--dark);

  &.disabled {
    color: var(--gray);
  }
}

.role-description {
  display: flex;
  font-weight: 400;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  color: var(--gray);
  font-size: var(--fs-md);
}

.content-container {
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  height: 36.25rem;
  width: 100%;
  border-top: 1px solid var(--ash);
  border-bottom: 1px solid var(--ash);
}

.content-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 3.75rem;
}

.legend-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-right: 1.25rem;
}

.legend-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2.5rem;
}

.legend-item-title {
  font-size: var(--fs-md);
  color: var(--dark);
  padding-right: 0.5rem;
}

.table-header {
  box-sizing: border-box;
  width: 100%;
  min-height: 3.75rem;
  background-color: var(--typhoon);
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2rem;
  font-size: var(--fs-md);
  font-weight: 500;
  color: var(--dark);
}

.column {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;

  &.type {
    width: 100px;
    padding-right: 15px;
  }

  &.access {
    width: 270px;
  }

  &.large-role {
    width: 7.8125rem;
    justify-content: center;
  }

  &.small-role {
    width: 5.625rem;
    justify-content: center;
  }

  &.bold {
    font-weight: 500;
  }
}

.table-container {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.table-row {
  height: 50px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2rem;
  border-bottom: 1px solid var(--typhoon);
  font-size: var(--fs-md);
  color: var(--dark);
}
