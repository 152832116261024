@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.container {
  padding: 0 2rem;
  color: var(--dark);
  height: auto;
  margin: 0 auto;
  width: 52rem;
  font-size: var(--fs-md);
}

.cover-section {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 0.5rem;
}

.general-data-section {
  width: 50%;
  border: 1px solid var(--ash);

  & th,
  & td {
    width: 33%;
  }
}

.table-wrapper {
  
  & table {
    width: 100%;
    border-collapse: collapse;
  }

  & th,
  & td {
    padding-left: 0.5rem;
    vertical-align: middle;
  }

  & tr {
    height: 2rem;
  }

  & th {
    white-space: nowrap;
    text-align: left;
    background-color: var(--lightblueHighlight);
    font-size: var(--fs-md);
    font-weight: 500;
  }
}

.cover-photo-section {
  width: 50%;
  border: 1px solid var(--ash);
}

.main-photo-container {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  text-align: center;
  height: 100%;
}

.double-section-container {
  border: 1px solid var(--ash);
  margin-bottom: 0.5rem;

  & th,
  & td {
    width: 25%;
  }
}

.left-value {
  border-right: 1px solid var(--ash);
}

.single-section-container {
  margin-bottom: 0.5rem;
  border: 1px solid var(--ash);
}

.single-left-column {
  width: 18rem;
}

.photos-section {
  page-break-before: always;
}

.photos-section-title {
  font-size: var(--fs-lg);
  font-weight: 500;
  margin-bottom: 1rem;
}

.photo-block {
  display: flex;
  margin-top: 1rem;
}

.photo-container {
  width: 65%;
}

.photo {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  width: 31.25rem;
  height: 25rem;
}
