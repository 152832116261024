@import '../../../../styles/layout.css';
@import '../../../../styles/colors.css';
@import '../../../../styles/fonts.css';

.type-option-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.type-option-content {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

.type-option-text {
  margin-left: 1rem;
  font-weight: 500;
}

.type-option-subtext {
  font-weight: 400;
  font-size: var(--fs-sm);
  color: var(--gray);
}
