@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

:root {
  --interiorContainerHeight: calc(100vh - var(--detailFixedHeaderOffset));
  --headerHeight: 50px;
  --folderTreeHeight: 350px;
  --innerFolderTreeHeight: 300px;
  --containerIndent: 1.25rem;
  --breadcrumbHeight: 3rem;
  --tableCellHeight: 2.5rem;
  --listViewTableWidth: calc(100% - var(--sidePaneWidth));
}

/* stylelint-disable selector-no-qualifying-type */

.body-container {
  width: 100%;
  height: 100%;
  min-height: var(--interiorContainerHeight);
  max-height: var(--interiorContainerHeight);
  margin-top: var(--detailFixedHeaderOffset);
  display: inline-block;
}

.left-body-pane-fixed,
.right-body-pane-fill {
  display: inline-block;
  height: 100%;
  overflow: auto;
}

.left-body-pane-fixed {
  width: var(--sidePaneWidth);
  height: 100%;
  background-color: var(--typhoon);
}

.right-body-pane-fill {
  width: calc(100% - var(--sidePaneWidth));
}

.folders-container {
  height: var(--folderTreeHeight);
  border-bottom: 1px solid var(--ash);
  overflow: hidden;
}

.file-categories-container {
  border-bottom: 1px solid var(--ash);
}

.section-header {
  font-size: var(--fs-lg);
  padding: 0.9375rem 1.25rem;
  font-weight: 500;
  color: var(--dark);
}

.right-pane-header {
  height: var(--headerHeight);
  padding: 0 1.25rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.breadcrumb {
  color: var(--gray);
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  &.selected {
    color: var(--dark);
  }
}

.button-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.breadcrumb-text {
  font-size: var(--fs-md);
  padding-right: 1rem;
}

.folder-tree-container {
  overflow: auto;
  height: var(--innerFolderTreeHeight);
}

.media-totals-text-container {
  margin-left: 2.8125rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.media-totals-type {
  text-transform: capitalize;
  font-size: var(--fs-md);
}

.media-totals-count {
  font-size: var(--fs-md);
  color: var(--gray);
}

.media-totals-icon {
  margin-left: 0;
}

.folder-breadcrumbs {
  line-height: var(--breadcrumbHeight);
  overflow: hidden;
  text-overflow: ellipsis;
}

.breadcrumb-item {
  display: inline-block;
  padding-right: 0.25rem;
  cursor: pointer;
  color: var(--gray);

  &:not(:last-of-type):after {
    display: inline-block;
    vertical-align: middle;
    padding-left: 0.25rem;
    cursor: default;
    font-family: 'Material Icons';
    content: '\E5CC';
    line-height: var(--breadcrumbHeight);
    font-size: var(--fs-xxxl);
  }

  &:last-of-type {
    color: var(--dark);
    cursor: default;
  }
}

.hidden {
  visibility: hidden;
}

.detail {
  width: 100%;
  height: calc(100% - var(--headerHeight));
  overflow: auto;
}

.name-row-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.name-row-icon {
  margin-right: 1rem;
}

.detail,
.main-pane {
  display: inline-block;
  height: calc(100% - var(--headerHeight));
  background-color: var(--white);

  & table {
    border-collapse: collapse;
    cursor: default;
    width: 100%;
    table-layout: auto;
    padding-left: 2.5rem;
  }

  & table.sticky {
    position: sticky;
    z-index: 100;
    top: 0;
  }

  & table.sticky.footer {
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background: var(--white);
  }

  & tr.title-header {
    background-color: var(--white);
    z-index: 100;
    position: relative;
  }

  & tr:hover {
    background-color: var(--highlight);
  }

  & th {
    font-size: var(--fs-sm);
    font-weight: 500;
    color: var(--gray);
    text-transform: uppercase;
    background-color: var(--fog);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: var(--tableCellHeight);
    padding-left: 1.25rem;
    border-bottom: 1px solid var(--typhoon);
    vertical-align: middle;
    text-align: left;
  }

  & th.is-sortable {
    cursor: pointer;
  }

  & th.is-sortable:after {
    font-family: 'Material Icons';
    content: '\E5DB'; /* arrow_downward */
    vertical-align: middle;
    margin-left: 1em;
    font-size: var(--fs-md);
  }

  & th.is-sortable.sort-descending,
  & th.is-sortable.sort-ascending {
    color: var(--dark);
    font-weight: 500;
  }

  & th.is-sortable.sort-ascending:after {
    content: '\E5D8'; /* arrow_upward */
  }

  & td {
    height: var(--tableCellHeight);
    padding-left: 1.25rem;
    border-bottom: 1px solid var(--typhoon);
    vertical-align: middle;
    text-align: left;
    font-weight: 400;
    color: var(--dark);
  }

  & td.load-more-wrapper {
    padding: 0;
    height: inherit;
  }

  & td.additional-options {
    text-align: right;
    padding-right: 1rem;
    min-width: 2rem;
    max-width: 2rem;
  }

  & td.button-controls {
    text-align: right;
    padding-right: 2rem;
    min-width: 5rem;
    max-width: 5rem;
  }

  & td.header-buttons {
    text-align: right;
    min-width: 2rem;
    position: absolute;
    padding-right: 1rem;
    right: 0;
  }

  & td.status-pill {
    position: relative;
    min-width: 5rem;
    max-width: 5rem;
  }

  & td.assigned-users {
    min-width: 12rem;
    max-width: 12rem;
  }

  & td.clickable {
    cursor: pointer;
    font-weight: 500;
    overflow: visible;
    white-space: nowrap;
  }

  & .clickable-link {
    color: inherit;
    text-decoration: none;
  }

  & .selected-items {
    color: var(--blue);
  }

  /* stylelint-disable declaration-no-important, selector-max-compound-selectors */

  & .hidden td,
  & .hidden td > *,
  & .hidden th {
    height: 0;
    line-height: 0;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
  }

  & .hidden td.checkbox > button {
    height: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.download-link {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

.modal-container {
  padding: 0 2.5rem 0 2.5rem;
  margin-bottom: 2rem;
}

.modal-spinner-container {
  padding: 2.5rem;
  height: 20vh;
}

.schema-menu-item-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
}

.schema-menu-item-title {
  padding-left: 0.3125rem;
}

.search-bar-box {
  width: 21.875rem;
  height: 2.125rem;
  background-color: var(--typhoon);
  margin-right: 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.close-button:hover {
  cursor: pointer;
}

.header-cell {
  font-size: var(--fs-md);

  &.selected {
    color: var(--dark);
    font-weight: 500;
  }

  &.sort-descending:after {
    font-family: 'Material Icons';
    content: '\E5DB'; /* arrow_downward */
    vertical-align: middle;
    margin-left: 1em;
  }

  &.sort-ascending:after {
    font-family: 'Material Icons';
    content: '\E5D8'; /* arrow_upward */
    vertical-align: middle;
    margin-left: 1em;
  }

  &:hover {
    cursor: pointer;
  }
}

.modal-tree-container {
  height: 21.875rem;
  position: relative;
  padding: 0.5rem;
  overflow: auto;
}

.media-tree-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 1.5rem;

  &.edit {
    justify-content: flex-start;
  }
}

.media-tree-text-container {
  display: flex;
  align-items: center;

  &.disabled {
    color: var(--gray);
  }
}

.media-tree-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
