@import '../../../styles/colors.css';
@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';

.container {
  height: 100%;
}

.icon {
  color: var(--gray);
}

.logo-testEnv {
  display: flex;
}

.testDiv {
  border: 1px solid var(--blue);
  border-radius: 0.2rem;
  margin-left: 1.5rem;
  color: var(--blue);
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.left-divider {
  position: absolute;
  top: -0.625rem;
  height: var(--topNavHeight);
  border-left: 2px solid var(--fog);
  left: 0%;
}

.right-divider {
  position: absolute;
  top: -0.625rem;
  height: var(--topNavHeight);
  border-left: 2px solid var(--fog);
  left: 100%;
}

.logo {
  cursor: pointer;
  width: 5rem;
  margin-left: 0.5rem;
}

.logo-text {
  display: inline-block;
  height: 100%;
  color: var(--white);
  font-size: var(--fs-lg);
  margin-top: 0.5em;
  margin-left: 2em;
}

.mask {
  opacity: 0;
  z-index: 100;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
}

.additional-buttons-wrapper {
  display: inline-block;
  margin-top: -5px;
  height: 100%;
}

.additional-buttons-container {
  height: 100%;
  padding: 0 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  &.create {
    padding: 0 0.3125rem;
  }
}

.additional-buttons-left-divider {
  position: absolute;
  top: -0.625rem;
  height: calc(var(--topNavHeight) + 5px);
  border-left: 2px solid var(--fog);
  left: 0%;
}

.menu-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  font-size: var(--fs-md);
  box-sizing: border-box;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    background-color: var(--fog);
  }
}

.profile-wrapper {
  display: inline-flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  margin-right: -0.625rem;
  margin-top: -5px;
}

.profile {
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
}

.portal-wrapper {
  display: inline-block;
}

.portal-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: calc(100% - 11px);
  cursor: pointer;
  position: relative;
  z-index: 200;
}

.dashboard-menu {
  box-sizing: border-box;
}

.dashboard-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px var(--ash) solid;
  padding: 1rem;
  box-sizing: border-box;
  cursor: pointer;
}

.dashboard-text {
  color: var(--dark);
}

.portals-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
}

.portal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33%;
  margin-top: 1rem;
  cursor: pointer;
}

.portal-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;

  &.header {
    margin: 0 0.5rem;
  }
}

.portal-text {
  font-size: var(--fs-sm);
  margin-top: 0.25rem;
}
