:root {
    --headerHeight: 3.125rem;
}

.table-header-spacer {
  height: var(--headerHeight);
  padding: 0 1.25rem;
  display: flex;
  align-items: center;
}
