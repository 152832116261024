@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

.content {
  width: 100%;
}

.field-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 1rem 0;
}

.field-drag-container {
  display: flex;
  align-items: center;
  width: 36.75rem;
  padding: 0.5rem 0.5rem 0.5rem 0;

  &.preview {
    width: 24.5rem;
  }
}

.enum-single-nested-container {
  display: flex;
  flex-flow: row wrap;
}

.enum-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 1rem;
}

.enum-label {
  margin-right: 1rem;
  white-space: nowrap;
}

.label {
  color: var(--gray);
}

.helper-text {
  display: flex;
  justify-content: flex-end;
}

.menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modal-title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.modal-title-path {
  padding-left: 0.5rem;
  font-size: var(--fs-md);
  font-weight: 400;
}

.required-container {
  margin-top: 1rem;
}

.hyperlink-container {
  display: 'flex';
  flex-direction: 'column';
}

.hyperlink-title {
  margin-bottom: '0.5rem';
  font-size: var(--fs-sm);
  color: var(--gray);
}
