@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

.left-body-pane-half {
  display: inline-block;
  height: 100%;
  overflow: auto;
  background-color: var(--white);
  width: 50%;
  position: relative;
  overflow-x: hidden;
}

.right-body-pane-half {
  display: inline-block;
  height: 100%;
  overflow: auto;
  width: 50%;
}

.center-pane-container {
  background-color: var(--typhoon);
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
}

.center-pane {
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  min-height: 100%;
  width: 60%;
  margin: 0 auto;
}

.location-section {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--mist);
  padding: 0 2rem;
}

.location-image {
  height: 13rem;
  width: 15.5rem;
}

.location-content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.location-title {
  padding-top: 1rem;
  font-weight: 500;
  font-size: var(--fs-md);
  color: var(--dark);
}

.location-text {
  padding-top: 1rem;
  text-align: center;
  font-size: var(--fs-md);
  color: var(--gray);
  max-width: 20rem;
}
