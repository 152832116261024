@import '../../../../../styles/fonts.css';
@import '../../../../../styles/colors.css';
@import '../../../../../styles/layout.css';

:root {
  --iconDimension: 24px;
  --leftTaskSectionWidth: calc(100vw - 56.5rem);
}

.section-container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 0.75rem 0 1.5rem;
}

.tile-list {
  display: flex;
  flex-flow: row wrap;
  margin-left: 4.25rem;
}

.buttons-container {
  margin-left: 0.5rem;
}

.section-title {
  margin-left: 1.25rem;
  font-size: var(--fs-md);
  font-weight: 500;
}

.empty-section-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  color: var(--gray);
}

.location-require {
  display: flex;
  align-items: center;
}
