@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

.modal-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.material-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.material-title {
  font-size: var(--fs-lg);
  color: var(--dark);
  font-weight: 500;
}

.material-subtext {
  font-size: var(--fs-md);
  color: var(--gray);
  font-weight: 400;
  padding-top: 0.5rem;
}

.material-unit {
  text-transform: uppercase;
}

.entry-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.entry-info-section {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.entry-button-section {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.label {
  font-size: var(--fs-sm);
  color: var(--gray);
  padding-bottom: 5px;
}

.display-container {
  display: flex;
  flex-flow: column nowrap;
  align-self: flex-start;
  white-space: nowrap;
  width: 7.5rem;
  margin-right: 1rem;

  &.name {
    width: 10.5rem;
    white-space: nowrap;
  }

  &.cost {
    width: 6rem;
  }
}

.entry-data-section {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.input-container {
  display: flex;
  align-items: center;
  width: 7.5rem;
  margin-right: 1rem;

  &.name {
    width: 10.5rem;
  }
}

.no-user-message {
  color: var(--gray);
  margin-bottom: 1rem;
}

.readings-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.readings-input-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.readings-helper-text-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.readings-error {
  color: var(--mui-error);
}
