@import '../../../styles/fonts';
@import '../../../styles/colors';

:root {
  --categoryListRowHeight: 3.5em;
}

.category-filter-trigger {
  width: 100%;
  color: var(--dim);
  line-height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  & .text {
    text-indent: 1rem;
  }
}

.category-filter-selection-button {
  text-transform: uppercase;
  color: var(--blue);
  font-weight: 500;
  flex: 1;
  text-align: right;
}

.category-list-row-container {
  min-height: var(--categoryListRowHeight);
  display: flex;
  flex-direction: column;
}

.category-list-row {
  height: var(--categoryListRowHeight);
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  min-width: 7rem;
}

.category-modal-selection-backdrop {
  width: fit-content;
  min-width: 100%;
  color: var(--dim);
  background: var(--fog);
  font-size: var(--fs-md);
  line-height: 2.5em;
  margin-top: 1.5rem;
  padding: 1em 0 1em 5em;
  box-sizing: border-box;
}

.sub-category-list-container {
  padding-left: 5em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  page-break-before: always;
}

.category-label {
  width: 100%;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  display: flex;
  position: relative;

  &.disabled {
    cursor: default;
  }
}

.category-toggle-icon {
  display: inline-block;
  margin-left: -1.75em;
  width: 24px;
  position: relative;
  cursor: pointer;
}
