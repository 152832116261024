@import '../../styles/layout.css';
@import '../../styles/colors.css';
@import '../../styles/fonts.css';

.portal-container {
  display: flex;
  flex-flow: row nowrap;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: var(--topNavHeight);
}

.pane {
  height: 100%;
  overflow: hidden;

  &.left {
    width: var(--sidePaneWidth);
    background-color: var(--typhoon);
  }

  &.right {
    width: calc(100% - var(--sidePaneWidth));
    background-color: var(--white);
  }
}

.sub-nav-header {
  display: flex;
  align-items: center;
  padding-left: 1.25rem;
  color: var(--white);
  font-size: var(--fs-lg);
  height: var(--subNavHeight);
  background-color: var(--orange);
  font-weight: 500;
}

.calendar-toolbar-container {
  height: 3.125rem;
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--orange);
}

.calendar-toolbar-left-controls {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.date-label {
  font-weight: 500;
  font-size: var(--fs-lg);
  color: var(--white);
  padding: 0 1rem;
}

.right-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.loading-container {
  padding: 1rem;
}
