@import '../../../styles/detail-header.css';
@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.header-container {
  position: fixed;
  margin-top: var(--topNavHeight);
  z-index: 200;
  width: 100vw;
  background-color: var(--purple);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  height: var(--detailSubNavHeight);
  color: var(--white);
  font-weight: 500;
}

.header-icon {
  display: flex;
}

.title-bar {
  box-sizing: content-box;
  display: inline-block;
  font-size: var(--fs-lg);
  margin: 0.875rem 0 1rem 0;
}

.icon-bar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  right: 0.5rem;
  top: 0.25rem;
}

.info-boxes-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  height: 2.5rem;
}

.breadcrumbs-label {
  width: 7.85rem;
}

.info-box {
  margin-right: 1.25rem;

  &.label {
    width: 3.5rem;
    white-space: nowrap;
  }

  &.label:first-of-type {
    margin-left: 3rem;
    width: 6rem;
  }

  &.value {
    margin-right: 3rem;
  }

  &.value > div {
    white-space: nowrap;
  }

  &.breadcrumbs {
    overflow: auto;
    white-space: nowrap;
  }
}

.approval-spin-container {
  align-self: center;
}

.cost-text {
  text-transform: uppercase;
}

.modal-container {
  padding: 0 2.5rem 2.5rem 2.5rem;
  margin-bottom: 2rem;
}

.modal-spinner-container {
  height: 100%;
}

.icon-row {
  margin-top: 0.5rem;
  margin-left: 3rem;
}

.icon-container {
  cursor: pointer;
  text-align: center;
  display: inline-block;
  width: 5.125rem;
  opacity: 0.4;
  padding-bottom: 0.6rem;
  margin-right: 1.25rem;

  &.active {
    opacity: 1;
    border-bottom: 2px solid var(--white);
  }

  & .label {
    font-size: var(--fs-sm);
    text-transform: uppercase;
    margin: -0.5rem 0;
    white-space: nowrap;
  }
}

.pagination-container {
  height: 100%;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: var(--white);
  font-size: var(--fs-md);
}

.pagination-divider {
  display: inline-block;
  background: var(--white0_4);
  width: 1px;
  height: 1.5625rem;
  margin: 0 0.75rem;
}

.inventory-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 1rem 0;

  &.quantity {
    padding: 0 0 1rem 3rem;
    width: 15.625rem;
  }
}

.cost-input-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
}

.separator {
  margin-bottom: -1rem;
  padding: 0 0.5rem;
}

.quantity-suffix {
  margin-left: 1rem;
  white-space: nowrap;
  text-transform: uppercase;
}
