@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.content-container {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;
}

.sidebar-container {
  height: 100%;
  overflow: hidden;
  width: var(--sidePaneWidth);
  background-color: var(--typhoon);
}

.right-pane {
  height: 100%;
  overflow: hidden;
  width: calc(100% - var(--sidePaneWidth));
  background-color: var(--white);
}

.title {
  display: flex;
  align-items: center;
  height: 3.125rem;
  padding-left: 1.25rem;
}
