@import '../../styles/layout.css';
@import '../../styles/fonts.css';
@import '../../styles/colors.css';

.breadcrumb-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  overflow: hidden;
  height: 2.5rem;
  color: var(--dark);
}

.breadcrumb {
  font-weight: 500;

  &:last-of-type {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.breadcrumb-menu-item {
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  font-size: var(--fs-md);
  box-sizing: border-box;
  width: 336px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;

  &:hover {
    cursor: pointer;
    background-color: var(--fog);
  }
}
