@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.header {
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 20px;
  border-bottom: 1px solid var(--ash);
}

.notifications-container {
  padding: 1.25rem 3.125rem;
  display: flex;
  flex-direction: column;
}

.notifications-heading {
  color: var(--dark);
  margin-bottom: 1rem;
}

.notification-label-container {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
}

.notification-subheading {
  color: var(--gray);
}
