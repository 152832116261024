/* stylelint-disable declaration-no-important */
@import '../../../../../styles/colors.css';
@import '../../../../../styles/layout.css';
@import '../../../../../styles/fonts.css';

:root {
  --containerHeight: 2.25rem;
}

.container {
  height: 2.25rem;
  width: 2.5rem;
  margin-top: var(--mapControlsTopOffset);
  margin-left: 0.625rem;
  background-color: var(--white);
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  &.disabled {
    background: var(--ash);
  }
}

.icon {
  cursor: inherit !important;
}
