@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

.modal-container {
  overflow: auto;
}

.header-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 41rem;
}

.header-section {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.header-title {
  font-size: var(--fs-lg);
  line-height: var(--fs-xxl);
  font-weight: 500;
}

.title-container {
  padding-left: 1rem;
  height: 3.75rem;
}

.header-subtext {
  font-size: var(--fs-md);
  color: var(--gray);
  line-height: var(--fs-xxl);
  font-weight: 400;
}

.header-status {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-size: var(--fs-xs);
  border-radius: 9px;
  font-weight: 700;
  text-transform: uppercase;
  height: 1.125rem;
  width: max-content;
  padding: 0 0.5rem;
  margin: 4px 0;
}

.toggle-label {
  font-size: var(--fs-md);
}

.toggle-subtext {
  margin-left: 3rem;
  font-size: var(--fs-md);
  color: var(--dark);
}

.action-buttons-container {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.time-entry-row {
  padding: 1rem 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.time-entry-data-section {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.time-entry-button-section {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.label {
  font-size: var(--fs-sm);
  color: var(--gray);
  padding-bottom: 5px;
}

.display-container {
  display: flex;
  flex-flow: column nowrap;
  width: 7.5rem;
  margin-right: 1rem;

  &.work-time {
    width: 7rem;
  }
}

.input-container {
  display: flex;
  align-items: center;
  width: 7.5rem;
  margin-right: 1rem;

  &.work-time {
    width: 7rem;
  }
}

.modal-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tooltip-container {
  padding: 0.5rem 0.5rem 0 0.5rem;
}

.tooltip-row {
  font-size: var(--fs-md);
  padding-bottom: 0.5rem;
}

.snapshot-suffix {
  padding-left: 1ch;
  color: var(--gray);
}

.hours-suffix {
  margin-top: 1rem;
}
