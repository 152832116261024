@import '../../../../../styles/fonts.css';
@import '../../../../../styles/colors.css';
@import '../../../../../styles/layout.css';

:root {
  --iconDimension: 24px;
  --leftTaskSectionWidth: calc(100vw - 56.5rem);
}

.section-container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 0.75rem 0 1.5rem;
}

.right-container {
  display: flex;
  flex-flow: column;
  margin-left: 1.25rem;
  width: var(--leftTaskSectionWidth);
}

.header-container {
  display: flex;
  align-items: center;
  padding: 0 0.5rem 0.5rem 0;
}

.change-indicator-container {
  position: relative;
}

.change-indicator {
  height: 0.3125rem;
  width: 0.3125rem;
  background: var(--blue);
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
}

.tile-list {
  display: flex;
  flex-flow: row wrap;
  margin-left: 4.25rem;
}

.lock-container {
  margin-top: 3px;
  margin-right: 0.5rem;
}

.buttons-container {
  margin-left: 0.5rem;
}

.section-title {
  margin-left: 1.25rem;
  font-size: var(--fs-md);
  font-weight: 500;
}

.total-count {
  margin-left: 1ch;
}

.empty-section-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  color: var(--gray);
}
