@import '../../../../../styles/layout.css';
@import '../../../../../styles/fonts.css';
@import '../../../../../styles/colors.css';

:root {
  --contentHeight: calc(100% - 100px);
}

.header-container {
  display: flex;
  flex-flow: column nowrap;
  border-bottom: 1px solid var(--ash);
}

.header-title {
  padding: 1rem;
  font-size: var(--fs-lg);
  font-weight: 500;
}

.icon-container {
  cursor: pointer;
  text-align: center;
  display: inline-block;
  width: 5.125rem;
  margin-right: 1.5rem;

  &.active {
    border-bottom: 2px solid var(--blue);
  }
}

.content-container {
  height: var(--contentHeight);
  overflow: auto;
}

.attribute-group-header {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 3.125rem;
  border-bottom: 1px solid var(--ash);
  padding: 0 0.5rem 0 1.25rem;
}

.attribute-section {
  display: none;
  border-bottom: 1px solid var(--ash);
  padding: 0 1.25rem;
  flex-flow: column nowrap;

  &.expanded {
    display: flex;
  }
}

.spinner-container {
  height: 100%;
  width: 100%;
}

.files-container {
  width: 100%;
  height: 100%;
}

.files-controls {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.files-header {
  height: 3.125rem;
  padding: 0 1.25rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.dropdown-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}

.media-total-text {
  padding-left: 2rem;
  text-transform: capitalize;
}
