@import '../../../../../styles/colors.css';
@import '../../../../../styles/layout.css';
@import '../../../../../styles/fonts.css';

:root {
  --component-height: 2.25rem;
  --component-width: 23rem;
}

.container {
  margin-top: var(--mapControlsTopOffset);
  margin-left: 0.625rem;
  width: var(--component-width);
}

.search-box-container {
  height: var(--component-height);
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  background-color: var(--white);
  overflow: hidden;
}

.content {
  display: inline-flex;
  justify-content: flex-start;
}

.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  height: var(--component-height);

  &.clickable {
    cursor: pointer;
  }
}

.search-input {
  display: inline-flex;
  width: 18rem;
  height: var(--component-height);
}

.dropdown-item-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.3125rem 0.3125rem;
  width: var(--component-width);
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
}

.dropdown-item-container:hover {
  background: var(--typhoon);
}

.dropdown-icon-container {
  display: flex;
  align-items: center;
  margin-right: 0.625rem;
}

.dropdown-item-title {
  font-size: var(--fs-sm);
  line-height: var(--fs-lg);
  font-weight: 700;
  color: var(--dark);
}

.dropdown-item-address {
  font-size: var(--fs-sm);
  line-height: var(--fs-lg);
  color: var(--dark);
}

.dropdown-item-coordinates {
  font-size: var(--fs-sm);
  color: var(--gray);
  line-height: var(--fs-lg);
}

.dropdown-item-primary-text {
  display: flex;
  flex-flow: column wrap;
  white-space: normal;
  flex: auto;
  margin-right: 0.3125rem;
}

.edit-action-controls-container {
  display: flex;
  align-items: center;
  margin-top: var(--mapControlsTopOffset);
  margin-left: 2px;
  height: var(--component-height);
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  background-color: var(--white);
  overflow: hidden;
}
