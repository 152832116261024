@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.menu-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  font-size: var(--fs-md);
  box-sizing: border-box;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    background-color: var(--fog);
  }
}

.modal-content {
  padding: 0 1.75rem 2rem 1.75rem;
}

.color-title {
  font-size: var(--fs-sm);
  color: var(--dim);
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.color-container {
  border: 1px solid var(--ash);
  padding: 0.5rem;
  display: flex;
  align-items: space-between;
  flex-flow: row wrap;
}

.color-swatch {
  font-size: var(--fs-xxxl);
  border: 18px solid var(--white);
  border-radius: 2rem;
  width: 1.5rem;
  height: 1.5rem;
  margin: 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  &.selected {
    border-color: rgba(0, 125, 239, 0.3);
  }

  &:hover {
    border-color: rgba(0, 125, 239, 0.3);
    cursor: pointer;
  }
}

.options-wrapper {
  display: inline-block;
}

.search-content {
  box-sizing: border-box;
  padding: 0.5rem 3rem 0 3rem;
}

.search-box {
  box-sizing: border-box;
  padding-top: 0.5rem;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-bottom: 1px solid var(--ash);
}

.users-modal-content {
  padding: 0;
  border-top: 1px solid var(--ash);
  border-bottom: 1px solid var(--ash);
}

.users-list {
  padding: 0.5rem 0;
  height: 500px;
  overflow: auto;
}

.user-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 3rem;
}

.users-left-content {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.info-container {
  padding-left: 1rem;

  &:hover {
    cursor: pointer;
  }
}

.user-name {
  color: var(--dim);
  font-weight: 500;
  font-size: var(--fs-md);

  &.disabled {
    color: var(--ash);
  }
}

.user-email {
  color: var(--gray);
  font-size: var(--fs-sm);

  &.disabled {
    color: var(--ash);
  }
}

.user-label {
  color: var(--gray);
  font-size: var(--fs-md);
  font-style: italic;
  white-space: nowrap;
}

.access-text {
  padding: 1.25rem 3rem 0 3rem;
  color: var(--dim);
}
