@import '../../../../../styles/layout.css';
@import '../../../../../styles/fonts.css';
@import '../../../../../styles/colors.css';

:root {
  --iconDimension: 24px;
  --leftTaskSectionWidth: calc(100vw - 56.5rem);
}

.section-container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 1.25rem 0 1.5rem;
  width: '100%';
}

.title-container {
  display: flex;
  align-items: center;
}

.buttons-container {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.section-title {
  margin-left: 1.25rem;
  font-size: var(--fs-md);
  font-weight: 500;
}

.total-count {
  margin-left: 1ch;
  color: var(--gray);
}

.empty-section-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  color: var(--gray);
}

.tile-list {
  display: flex;
  margin-left: 4.25rem;
}

.required-content {
  display: flex;
  align-items: center;
  color: var(--gray);
  margin-left: 1ch;
}

.help-icon-container {
  display: flex;
  align-items: center;
  margin-left: 0.5ch;

  &:hover {
    cursor: pointer;
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
