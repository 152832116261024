@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

:root {
  --interiorContainerHeight: calc(100vh - var(--detailFixedHeaderOffset));
  --usageHeaderHeight: 80px;
  --tableOffset: calc(var(--usageHeaderHeight) + 40px);
}

.body-container {
  width: 100%;
  height: 100%;
  min-height: var(--interiorContainerHeight);
  max-height: var(--interiorContainerHeight);
  margin-top: var(--detailFixedHeaderOffset);
  display: inline-block;
}

.left-body-pane-fixed,
.right-body-pane-fill {
  display: inline-block;
  height: 100%;
  overflow: hidden;
}

.left-body-pane-fixed {
  width: var(--sidePaneWidth);
  height: 100%;
  background-color: var(--typhoon);
}

.right-body-pane-fill {
  width: calc(100% - var(--sidePaneWidth));
}

.right-pane-header {
  height: var(--usageHeaderHeight);
  padding: 0 2rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.header-info-block {
  text-align: right;
}

.header-section-title {
  font-size: var(--fs-sm);
  color: var(--dim);
  padding-bottom: 0.5rem;
  white-space: nowrap;
}

.header-section-info {
  font-weight: 500;
  text-transform: uppercase;
  font-size: var(--fs-lg);
  color: var(--dark);
}

.button-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.content-container {
  height: calc(100% - var(--tableOffset));
  max-width: calc(100vw - var(--sidePaneWidth));
}

.usage-unit {
  text-transform: uppercase;
}
