@import '../../../../styles/fonts';
@import '../../../../styles/colors';
@import '../../../../styles/layout';

.center-pane-container {
  background-color: var(--typhoon);
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}

.center-pane {
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  width: 60%;
  overflow: auto;
}

.cost-title {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
  width: 100%;
  height: 3.125rem;
  border-bottom: 1px solid var(--ash);
  color: var(--dark);
}

.cost-container {
  display: flex;
  flex-flow: column nowrap;
  padding: 1.25rem 2.5rem;
  border-bottom: 1px solid var(--ash);
}

.cost-input-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 1rem;
  margin-left: 2rem;
}

.separator {
  margin-bottom: -1rem;
  padding: 0 0.5rem;
}

.option-subtext {
  color: var(--gray);
  margin-top: -0.5rem;
  margin-left: 2rem;
}

.readings-container {
  display: flex;
  flex-flow: column nowrap;
}

.readings-subtext {
  color: var(--gray);
}

.readings-bold {
  font-weight: 500;
}
