@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

:root {
  --interiorContainerHeight: calc(100vh - 3.125rem);
}

.center-pane {
  height: var(--interiorContainerHeight);
  background-color: var(--white);
  width: 70%;
  margin-left: 15%;
  overflow: auto;
}

.team-container {
  height: var(--interiorContainerHeight);
  background-color: var(--typhoon);
}

.active {
  color: var(--blue);
}

.name {
  font-size: var(--fs-md);
  font-weight: 500;
  color: var(--dim);
}

.subtext {
  font-size: var(--fs-sm);
  color: var(--gray);
}

.team-lead-column {
  display: flex;
  align-items: center;
  padding-right: 1rem;
  white-space: nowrap;
}

.lead-filler {
  width: 4.25rem;
}

.team-total {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  color: var(--dark);
  height: 3.125rem;
  border-bottom: 1px solid var(--ash);
}

.label-column {
  cursor: pointer;
  font-weight: 500;
  overflow: visible;
  white-space: nowrap;
}
