@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

:root {
  --interiorContainerHeight: calc(100vh - var(--detailFixedHeaderOffset));
}

.body-container {
  width: 100%;
  height: 100%;
  min-height: var(--interiorContainerHeight);
  max-height: var(--interiorContainerHeight);
  margin-top: var(--detailFixedHeaderOffset);
  display: inline-block;
}

.left-body-pane-fixed,
.right-body-pane-fill {
  display: inline-block;
  height: 100%;
  overflow: hidden;
}

.left-body-pane-fixed {
  width: var(--sidePaneWidth);
  height: 100%;
  background-color: var(--typhoon);
}

.right-body-pane-fill {
  width: calc(100% - var(--sidePaneWidth));
}

.requests-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  border-bottom: 1px solid var(--ash);
  padding: 0 1rem;
}

.progress-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
