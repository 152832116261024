@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.filters-header {
  display: flex;
  position: sticky;
  align-items: center;
  justify-content: space-between;
  width: var(--sidePaneWidth);
  height: 3rem;
  color: var(--dark);
  font-size: var(--fs-lg);
  font-weight: 500;
}

.header-title {
  padding-left: 1rem;
  color: var(--dark);
  font-size: var(--fs-lg);
  font-weight: 500;
}

.filters-container {
  border-top: 1px solid var(--ash);
  padding: 1.25rem;
}

.category-filter {
  margin: 1rem 0;
}
