@import '../../../../styles/colors.css';
@import '../../../../styles/fonts.css';

.static-search-bar-container {
  color: var(--gray);
  display: flex;
  background-color: var(--typhoon);
  width: 70%;
  max-width: 37.5rem;
  line-height: 40px;
  height: 40px;
  border-radius: 2px;
  flex-flow: row nowrap;
  align-items: center;
  font-size: var(--fs-md);
  cursor: pointer;
  position: relative;
}

.static-search-bar-icon {
  display: flex;
  padding-left: 0.625rem;
}

.static-clear-bar-icon {
  display: flex;
  justify-content: flex-end;
  padding-right: 0.625rem;
  width: 100%;
}

.static-search-bar-text {
  padding-left: 0.625rem;
}

.search-results-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 37.5rem;
  background-color: var(--white);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  z-index: 600;
}

.search-input-control-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: var(--fs-md);
}

.search-results-query-container {
  border-top: 1px solid var(--ash);
  border-bottom: 1px solid var(--ash);
}

.loading-container {
  padding: 0.5rem;
}

.search-results-query-title {
  box-sizing: border-box;
  display: flex;
  padding: 0.625rem;
}

.empty-search {
  white-space: normal;
  line-height: 1.5rem;
  font-size: var(--fs-md);
}

.search-results-query-text {
  padding-left: 12px;
  white-space: normal;
  line-height: 1.5rem;
  font-size: var(--fs-md);
  color: var(--blue);
}

.search-entity-results-heading {
  color: var(--gray);
  margin-left: 2rem;
}

.work-order-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  height: 34px;
  width: 34px;
}
