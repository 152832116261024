@import './layout.css';
@import './colors.css';
@import './fonts.css';

.header-container {
  position: fixed;
  top: 0;
  margin-top: var(--topNavHeight);
  z-index: 200;
  width: 100vw;
  background-color: var(--blue);
  height: var(--subNavHeight);

  &.team-header {
    background-color: var(--red);
  }

  &.inventory-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--purple);
  }

  &.analytics-header {
    background-color: var(--green);
  }

  &.budgeting-header,
  &.budgets-header {
    display: flex;
    justify-content: space-between;
    background: var(--turquoise);
  }
}

.name-container {
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
  padding-left: 1rem;
  color: var(--white);
  font-size: var(--fs-lg);
  line-height: var(--subNavHeight);
  font-weight: 500;

  &.inventory-name-container {
    display: flex;
    flex: auto;
    flex-flow: row wrap;
  }

  &.budgets-name-container {
    width: 80%;
  }

  &.no-padding {
    padding-left: 0;
  }
}

.breadcrumb-name-container {
  display: inline-block;
  box-sizing: border-box;
  padding-left: 1rem;
  font-weight: 500;
  color: var(--white0_6);
  font-size: var(--fs-lg);
  line-height: var(--subNavHeight);

  &:not(:last-of-type):after {
    display: inline-block;
    padding-left: 1rem;
    cursor: default;
    font-family: 'Material Icons';
    content: '\E5CC';
    font-size: var(--fs-xxxl);
  }

  &:last-of-type {
    color: var(--white);
    font-weight: 500;
    cursor: default;
  }
}

.preview-header-container {
  position: fixed;
  top: 0;
  margin-top: var(--topNavHeight);
  z-index: 200;
  width: 100vw;
  background-color: var(--blue);
  height: var(--subNavHeight);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.preview-name-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1rem;
  color: var(--white);
  font-size: var(--fs-lg);
  line-height: var(--subNavHeight);
}

.preview-name-text {
  padding-left: 1rem;
  font-weight: 500;
}

.back-to-edit-button {
  color: var(--white);
  text-transform: uppercase;
  font-weight: 500;
  margin-left: 0.5rem;

  &:hover {
    cursor: pointer;
  }
}

.header-container.admin-header {
  background-color: var(--white);

  & .name-container {
    color: var(--dark);
  }

  & .portal-icon {
    width: 2rem;
    margin-top: 0.5rem;
    margin-right: 1.5rem;
  }
}

.action-container {
  float: right;
  padding: 0.4375rem 1.25rem 0.4375rem 0;
}

.action-icon-container {
  float: right;
  padding-right: 0.75rem;
}

.additional-options-container {
  float: right;
  padding-right: 1.5em;
}

.button-container {
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
  padding-right: 0.675rem;
  text-align: right;

  &.inventory-button-container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}

.form-builder-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.mode-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 2.125rem;
  padding: 0 0.625rem;
  background-color: rgba(0, 0, 0, 0.1);
}

.mode-container-text {
  font-size: var(--fs-md);
  padding-left: 0.625rem;
  color: var(--white);
  font-weight: 500;
  line-height: 24px;
}

.saving-text {
  color: var(--white);
  font-size: var(--fs-md);
}

.in-use-text {
  font-size: var(--fs-md);
  color: var(--white);
}

.published-block {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-left: 8.125rem;
}

.published-text {
  font-size: var(--fs-md);
  color: var(--white);
  font-weight: 600;
  text-transform: uppercase;
  padding-left: 0.5rem;
}

.menu-heading {
  font-size: var(--fs-sm);
  color: var(--gray);
  font-weight: 500;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
}

.menu-item {
  font-size: var(--fs-md);
  padding: 0.5rem 1rem;
  color: var(--black);
  font-weight: 500;
  display: block;
  cursor: pointer;

  &:hover,
  &.selected {
    background-color: var(--lightblueHighlight);
    color: var(--blue);
  }
}
