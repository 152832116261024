@import '../../../styles/colors.css';
@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';

.tooltip-wrapper {
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

.tooltip-container {
  padding: 0.5rem;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  background: var(--white);
  box-shadow: var(--muiDropdownBoxShadow);
  transition: visibility 0s, opacity 0.25s linear;
  transition-delay: 0.5s;
  z-index: 1000;
}

.tooltip-wrapper:hover .tooltip-container {
  visibility: visible;
  opacity: 1;
}

.overflow-container {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  white-space: nowrap;
  align-items: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
}

.bold {
  font-weight: 500;
}
