@import '../../../styles/colors.css';
@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';

:root {
  --interiorContainerHeight: calc(100vh - var(--fixedHeaderOffset));
  --divider: 1px solid var(--typhoon);
}

.container {
  display: inline-block;
  width: var(--sidePaneWidth);
  height: var(--interiorContainerHeight);
  background-color: var(--white);
  overflow: auto;
  margin-top: var(--fixedHeaderOffset);
  color: var(--dim);
  border-right: 1px solid var(--ash);
  box-sizing: border-box;

  & .detail-left-pane {
    margin-top: 0;
  }
}

.tray-container {
  height: 100%;
  background-color: var(--white);
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
}

.header-container {
  border-top: var(--divider);
  padding: 1rem 0 0.5rem 0;
  vertical-align: middle;
  position: sticky;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  top: 0;
  z-index: 100;
  color: var(--dark);
  font-size: var(--fs-lg);
  font-weight: 500;
  background-color: var(--white);
  box-shadow: 4px 0 10px -6px rgba(0, 0, 0, 0.2);
}

.header-title-container {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.25rem;
}

.header-title {
  width: 70%;
  align-self: center;
  padding-left: 1.25rem;
}

.exit-container {
  display: inline-flex;
  padding-right: 0.75rem;
  justify-content: flex-end;
}

.tray-content-container {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 auto;
}

.summary-image-container {
  width: var(--sidePaneWidth);
  height: 160px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
