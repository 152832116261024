@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 1rem 1rem;
}

.task-template-creation-modal-content {
  display: flex;
  flex-direction: column;
}

.task-template-creation-modal-schema-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -4px;
}

.task-template-creation-modal-value {
  font-size: var(--fs-md);
  color: var(--dark);
  font-weight: 500;
  margin-left: 0.3125rem;
  margin-top: 0.1875rem;
}

.subtext {
  margin-bottom: 1rem;
}
