@import '../../../styles/colors.css';
@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';

:root {
  --navSidePaneWidth: 60px;
  --spinnerPadding: 30px;
  --listSidePaneWidth: calc(var(--sidePaneWidth) - var(--navSidePaneWidth));
  --widgetHeight: calc(100% - var(--dashboardWidgetTitleHeight));
  --listHeight: 100%;
  --divider: 1px solid var(--typhoon);
  --listIndent: 1.25rem;
}

.task-completion-icon {
  width: 15%;
}

.task-row-container {
  padding: 1.3125rem 1rem 1.3125rem 1.25rem;
  display: flex;
  align-items: center;
  border-bottom: var(--divider);
}

.task-row-content {
  width: 85%;
}

.task-row-description {
  color: var(--dark);
}

.task-row-details-container {
  color: var(--gray);
  display: flex;
  flex-direction: row;
  font-size: var(--fs-sm);
}

.task-detail-content {
  padding-top: 0.5rem;

  &:not(:last-child):after {
    content: ' • ';
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}

.user-row-container {
  padding: 1rem 1rem 1rem 1.25rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  border-bottom: var(--divider);
}

.user-thumbnail-container {
  width: 20%;
}

.user-detail-content {
  width: 80%;
}

.user-name-container {
  color: var(--dark);
}

.user-email-container {
  color: var(--gray);
}

.info-container {
  border-top: var(--divider);
  height: 100%;
  background-color: var(--white);
}

.info-detail-section {
  padding: 1rem;
  display: flex;
  height: 100%;
  flex-direction: column;
  border-top: var(--divider);
  border-bottom: var(--divider);
}

.summary-section-container {
  border-top: var(--divider);
  padding: 0.9375rem 1.25rem;
  display: flex;
  flex-direction: column;
}

.summary-section {
  display: flex;
  flex-direction: column;
}

.summary-section-title {
  display: flex;
  font-weight: 500;
  color: var(--dark);
}

.summary-section-contents {
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  display: flex;
  color: var(--gray);
}

.task-container {
  border-top: var(--divider);
  height: 100%;
  background-color: var(--white);
}

.task-detail-section {
  border-top: var(--divider);
  display: flex;
  flex-direction: column;
}

.user-container {
  border-top: var(--divider);
  height: 100%;
  background-color: var(--white);
}

.user-detail-section {
  border-top: var(--divider);
  display: flex;
  flex-direction: column;
}

.spinner-container {
  height: var(--listHeight);
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: var(--spinnerPadding);
}

.tray-container {
  height: 100%;
  background-color: var(--white);
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
}

.header-container {
  border-top: var(--divider);
  padding: 1rem 0 0.5rem 0;
  vertical-align: middle;
  position: sticky;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  top: 0;
  z-index: 100;
  color: var(--dark);
  font-size: var(--fs-lg);
  font-weight: 500;
  background-color: var(--white);
  box-shadow: 4px 0 10px -6px rgba(0, 0, 0, 0.2);
}

.header-title-container {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-title {
  width: 70%;
  padding-left: 1.25rem;
}

.exit-container {
  display: inline-flex;
  padding-right: 0.75rem;
  justify-content: flex-end;
}

.priority-status-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2rem;
  padding-left: 1.25rem;

  & .priority-container {
    margin-right: 1rem;
  }
}

.tray-content-container {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 auto;
}

.summary-image-container {
  width: var(--sidePaneWidth);
  height: 160px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.container {
  width: var(--sidePaneWidth);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
}
