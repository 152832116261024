@import '../../styles/layout.css';
@import '../../styles/colors.css';
@import '../../styles/fonts.css';

:root {
  --dashboard-title-bar-height: 50px;
}

.portal-container {
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  padding-top: var(--topNavHeight);
}

.dashboard-title-bar {
  margin: 0.5rem 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--dashboard-title-bar-height);
  margin-top: 60px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 300;
  background-color: var(--white);
}

.dashboard-title {
  padding-left: 2rem;
  font-size: var(--fs-lg);
}

.dashboard-icons {
  display: flex;
  align-items: center;
  padding-right: 1.25rem;
  color: var(--gray);
}

.dashboard-hello {
  margin: 0.5rem 2rem;
  font-size: var(--fs-xxxl);
  line-height: 32px;
  padding-top: var(--dashboard-title-bar-height);
}

.grid-container {
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  margin: 1rem 2rem;
  gap: 1rem;
}

.col {
  height: 100%;
  overflow: hidden;

  &.col-large {
    flex: 0 1 75%;
  }

  &.col-small {
    flex: 0 1 25%;
  }
}
