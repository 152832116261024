@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

.name-column {
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }
}

.status-pill {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--fs-sm);
  font-weight: 500;
  color: var(--white);
  height: 1.25rem;
  border-radius: 4rem;
  padding: 0 0.5rem;
  white-space: nowrap;
}
