@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';
@import '../../../styles/layout.css';

:root {
  --interiorContainerHeight: calc(100vh - var(--fixedHeaderOffset));
}

.filters-container {
  display: inline-block;
  width: var(--sidePaneWidth);
  height: var(--interiorContainerHeight);
  background-color: var(--typhoon);
  margin-top: var(--fixedHeaderOffset);
  color: var(--dim);

  & .detail-left-pane {
    margin-top: 0;
  }

  &.collapsed {
    width: 0;
  }
}

.filters-content {
  overflow: auto;
  height: calc(var(--interiorContainerHeight) - 3.125rem);
}

.filters-header {
  font-size: var(--fs-lg);
  font-weight: 500;
  color: var(--dark);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.125rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
  box-sizing: border-box;
  border-bottom: 2px solid var(--ash);
}

.filters-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.expand-button {
  position: absolute;
  top: 120px;
  left: var(--sidePaneWidth);
  background-color: var(--white);
  z-index: 100;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 1.25rem;
  box-shadow: 0 1px 3px 0 #00000040;

  &.collapsed {
    left: 0;
  }

  &:hover {
    cursor: pointer;
  }
}

.filters-date-range {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.filters-number-range {
  display: flex;
  flex-direction: column;
}

.filters-section {
  padding: 0 1.5rem;
  border-bottom: 1px solid var(--ash);
}

.filters-section-header {
  font-size: var(--fs-md);
  font-weight: 500;
  color: var(--dark);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.toggle {
    justify-content: space-between;
  }
}

.filters-section-content {
  padding-bottom: 1rem;
  padding-left: 2.5rem;
}

.custom-field-container {
  margin-top: 1rem;
}

.filter-container {
  margin-top: 1rem;

  &.select {
    display: flex;
    align-items: center;
    justify-content: space-between;

    /* stylelint-disable-next-line declaration-no-important */
    vertical-align: middle !important;
    font-size: var(--fs-md);
    font-weight: 500;
    color: var(--dark);
  }
}

.filter-loading-container {
  padding-top: 5rem;
}

.filter-header {
  display: flex;
  align-items: center;
}

.filter-popover {
  width: 17.25rem;
  padding-bottom: 0.5rem;
}

.filter-popover-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
  padding: 0 1rem;
  border-bottom: 1px solid var(--ash);
  margin-bottom: 0.5rem;
}

.filter-value {
  color: var(--gray);
}
