@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.new-favorites-list-button button {
  font-size: var(--fs-md);
  border-radius: 0;
  color: var(--blue);
}

.new-favorites-list-icon {
  margin-right: 0.5rem;
}
