@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

:root {
  --leftTaskSectionWidth: calc(100vw - 53.5rem);
}

.task-fields-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding-bottom: 1.25rem;

  &.hide {
    display: none;
  }
}

.time-row-item {
  padding-right: 1.875rem;
  height: 3rem;
  padding-bottom: 1.25rem;

  &.input {
    width: 9rem;
  }

  &.duration {
    width: 7rem;
  }
}

.label {
  font-size: var(--fs-sm);
  color: var(--gray);
  white-space: nowrap;
  padding-right: 0.5rem;
}

.date-display {
  padding-top: 2px;
  white-space: nowrap;
}

.duration-label {
  padding: 0 1rem 0 0.5rem;
  color: var(--black);
  font-size: var(--fs-md);
}

.duration-content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}

.duration-display {
  border-bottom: 1px solid var(--white);
  padding: 2px 0;

  &:hover {
    cursor: pointer;
    background: var(--typhoon);
  }
}

.task-multi-line-field-display {
  border-bottom: 1px solid var(--white);
  max-width: 31.25rem;

  &:hover {
    cursor: text;
    border-bottom: 1px solid var(--silver);
    background: var(--typhoon);
  }
}

.duration-textfield {
  width: 3rem;
}

.date-container:hover {
  background: var(--typhoon);
  cursor: pointer;
}

.label-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.tooltip-content {
  font-size: var(--fs-sm);
  width: 13rem;
}

.task-multi-line-field-textfield {
  position: relative;
  max-width: 31.25rem;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  margin-left: 0.25rem;
  box-shadow: var(--muiDropdownBoxShadow);

  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.2) 0 1px 6px, rgba(0, 0, 0, 0.2) 0 1px 4px;
  }
}

.time-picker-container {
  margin-top: -3px;
}

.estimated-cost-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 19px;
}

.estimated-cost-value {
  padding-right: 1.5rem;
}

.task-cost-textfield-container {
  flex: 1 1 auto;
  position: relative;
  margin-top: -12px;
  margin-right: 2rem;
}

.button-container {
  right: 0;
  bottom: -2.1rem;
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background: var(--white);
  z-index: 200;
}

.task-container {
  padding: 1rem 1rem 0.5rem 0;
}

.content-container {
  display: flex;
  padding-top: 1rem;
}

.left-column {
  width: var(--leftTaskSectionWidth);
}

.right-column {
  width: 21.25rem;
  margin-left: 1.25rem;
}

.task-detail-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding: 0 1.25rem 1.25rem 1.5rem;
}

.task-detail-row-content {
  width: 100%;
  padding-left: 1.25rem;
}

.time-detail-row {
  display: flex;
  padding-top: 0.625rem;
  padding-left: 1.5rem;
}

.time-row-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-left: 1.25rem;
}

.cost-container {
  display: flex;
  padding: 0.625rem 1.25rem;
  background-color: var(--fog);
}

.cost-content-left {
  width: 60%;
}

.cost-content-right {
  width: 40%;
}
