@import '../../../styles/colors.css';
@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.progress-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.load-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.favorites-tree-view-container {
  margin-top: 1.25rem;
  height: 29rem;
  overflow: auto;
}
