@import '../../styles/colors.css';
@import '../../styles/layout.css';

.container {
  position: absolute;
  left: 60px;
  z-index: 200;
  height: calc(100% - var(--fullHeaderHeight));
}

.content {
  position: relative;
  z-index: 300;
  height: 100%;
}

.drawer-arrow {
  left: 100%;
  top: 0.625rem;
  position: absolute;
  border-radius: 2px;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 1.5rem;
  background-color: var(--white);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  margin-left: -1px;
  cursor: pointer;
}

.expanded {
  transform: rotate(90deg);
}

.collapsed {
  transform: rotate(-90deg);
}

.content > * {
  height: 100%;
}
