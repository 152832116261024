@import '../../../../../../styles/colors.css';
@import '../../../../../../styles/layout.css';
@import '../../../../../../styles/fonts.css';

.body {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0;
  width: 100%;
}

.body-header {
  border-bottom: 1px solid var(--ash);
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 1rem 1.25rem;
  gap: 0.5rem;
  width: 100%;
}

.body-header-title {
  color: var(--black);
  font-size: var(--fs-lg);
  line-height: var(--lh-xxl);
  font-weight: 500;
}

.body-header-meta-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0 0 0;
  width: 100%;
}

.body-header-meta-field {
  color: var(--dim);
  font-weight: 400;
  font-size: var(--fs-md);
  line-height: var(--lh-lg);
}

.body-content {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  /* form-container height is 40rem, but we must remove from that
     the body header title height (which is 1.5rem of text +
     1rem top padding and 1rem bottom padding), that gives 36.5rem
     but remove if bit more because of the 1px border. */
  max-height: 36.4rem;
  min-height: 30rem;
  overflow-y: scroll;
  width: 100%;
}

.body-content-two-column {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.body-content-section {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0.625rem 1.25rem 0.375rem 1.25rem;
  width: 100%;
}
