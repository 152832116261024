@import '../../styles/layout.css';
@import '../../styles/colors.css';
@import '../../styles/fonts.css';

.folder-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
  min-height: 2.5rem;
  padding-right: 0.75rem;

  &:hover {
    cursor: pointer;
    background-color: var(--ash);
  }

  &.selected {
    background-color: var(--ash);
  }
}

.folder-name-container {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row-name-container {
  display: flex;
  flex-flow: column;
  padding-left: 0.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.options-wrapper {
  display: inline-block;
}

.icon-button {
  padding: 0;
  margin: 0;
  border: 0;
  width: 1.25rem;
  height: 1.5rem;
}

.menu-item {
  text-transform: capitalize;
  height: 2.25rem;
  padding: 0;
  overflow: hidden;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.icon-box {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.primary-text-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
}

.primary-text {
  margin: 0 1rem;
  color: var(--dark);
  font-size: var(--fs-md);
}
