@import '../../styles/layout.css';
@import '../../styles/colors.css';
@import '../../styles/fonts.css';

.card {
  border: 1px solid var(--ash);
  padding: 0;
  margin: 0 0 1rem;
  display: flex;
  flex-flow: column nowrap;
  border-radius: 4px;

  .card-action-icon {
    visibility: hidden;
  }

  &:hover {
    box-shadow: 0 1px 3px 0 #00000040;
    background: url('../../images/drag_indicator.png');
    background-position: left 4.5px top 17.5px;
    background-repeat: no-repeat;
    background-size: 7.5px;

    .card-action-icon {
      visibility: visible;
    }
  }
}

.card-titlebar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-title {
  font-weight: 500;
  padding: 0.75rem 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.card-title-icons {
  display: flex;
}

.card-content,
.card-body-empty,
.card-body-loading,
.card-body-loading-chart {
  display: flex;
  flex-flow: column;
  text-align: center;
  justify-content: center;
  color: var(--dim);
}

.card-body-loading {
  border-top: 1px solid var(--ash);
  overflow: hidden;
  justify-content: normal;
}

.card-body-loading-chart {
  align-items: center;
  color: var(--gray);
  background-color: var(--fog);
  overflow: hidden;
}

.card-list {
  overflow-y: auto;
  border-top: 1px solid var(--ash);
}
