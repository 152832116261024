@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

:root {
  --iconDimension: 24px;
  --leftTaskSectionWidth: calc(100vw - 56.5rem);
}

.section-container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 0.75rem 0 1.5rem;
}

.buttons-container {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.section-title {
  margin-left: 1.25rem;
  font-size: var(--fs-md);
  font-weight: 500;
}

.total-count {
  margin-left: 1ch;
  color: var(--gray);
}

.empty-section-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  color: var(--gray);
}

.tile-list {
  display: flex;
  flex-flow: row wrap;
  margin-left: 4.25rem;
}

.required-content {
  display: flex;
  align-items: center;
  color: var(--gray);
  margin-left: 1ch;
}

.help-icon-container {
  display: flex;
  align-items: center;
  margin-left: 0.5ch;

  &:hover {
    cursor: pointer;
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.asset-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  padding: 0.5rem;

  &:hover {
    background-color: var(--fog);
    cursor: pointer;
  }
}

.action-buttons {
  display: none;
}

.asset-row:hover > .action-buttons {
  display: inline-block;
}

.asset-content {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
}

.asset-image {
  box-sizing: border-box;
  height: 3.75rem;
  width: 3.75rem;
  margin: 0 1rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
}

.asset-name {
  font-size: var(--fs-md);
  font-weight: 500;
  color: var(--dark);
}

.schema-section {
  display: flex;
  align-items: center;
}

.asset-subtext {
  font-size: var(--fs-sm);
  color: var(--gray);
}

.sidebar-container {
  width: 21.25rem;
  height: 44.75rem;
  border-right: 1px solid var(--ash);
  background-color: var(--white);
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
}

.tray-header-container {
  vertical-align: middle;
  position: sticky;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  top: 0;
  z-index: 100;
  color: var(--dark);
  font-size: var(--fs-lg);
  font-weight: 500;
  background-color: var(--white);
  border-bottom: 1px solid var(--ash);
}

.tray-header-title-container {
  box-sizing: border-box;
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem 0 1.25rem;
}

.tray-content-container {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 auto;
}

.view-button {
  flex: 0 0 auto;
  height: 2.75rem;
  width: var(--sidePaneWidth);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blue);
  color: var(--white);
  text-transform: uppercase;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
}

.summary-image-container {
  width: var(--sidePaneWidth);
  height: 160px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.action-button-container {
  display: flex;
  align-items: center;
}
