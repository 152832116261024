@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

:root {
  --interiorContainerHeight: calc(100vh - var(--fixedHeaderOffset));
  --tableCellHeight: 2.5rem;
  --listViewTableWidth: calc(100% - var(--sidePaneWidth));
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.125rem;
  padding: 0 1.25rem;
}

.breadcrumb-container {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  overflow: hidden;
  height: 3.125rem;
  width: 100%;
}

.breadcrumb {
  cursor: pointer;
  color: var(--dim);
  font-weight: 500;
  text-transform: capitalize;

  &:last-of-type {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.breadcrumb-menu-item {
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  font-size: var(--fs-md);
  box-sizing: border-box;
  width: 336px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;

  &:hover {
    cursor: pointer;
    background-color: var(--fog);
  }
}

.group-controls-container {
  display: flex;
  flex-flow: row nowrap;
}

.title {
  font-weight: 500;

  &.pending {
    color: var(--gray);
  }

  &:hover {
    cursor: pointer;
  }
}

.detail,
.list-view,
.main-pane {
  display: inline-block;
  background-color: var(--white);

  &table {
    border-collapse: collapse;
    cursor: default;
    width: 100%;
    table-layout: auto;
    padding-left: 2.5rem;
  }

  &table.sticky {
    position: sticky;
    z-index: 100;
    top: 0;
  }

  &table.sticky.footer {
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background: var(--white);
  }

  &tr.title-header {
    background-color: var(--white);
    z-index: 100;
    position: relative;
  }

  &tr:hover {
    background-color: var(--highlight);
  }

  &th {
    font-size: var(--fs-sm);
    font-weight: 500;
    color: var(--gray);
    text-transform: uppercase;
    background-color: var(--fog);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: var(--tableCellHeight);
    padding-left: 1.25rem;
    border-bottom: 1px solid var(--typhoon);
    vertical-align: middle;
    text-align: left;
  }

  &th.is-sortable {
    cursor: pointer;
  }

  &th.is-sortable:after {
    font-family: 'Material Icons';
    content: '\E5DB'; /* arrow_downward */
    vertical-align: middle;
    margin-left: 1em;
    font-size: var(--fs-md);
  }

  &th.is-sortable.sort-descending,
  &th.is-sortable.sort-ascending {
    color: var(--dark);
    font-weight: 500;
  }

  &th.is-sortable.sort-ascending:after {
    content: '\E5D8'; /* arrow_upward */
  }

  &td {
    height: var(--tableCellHeight);
    padding-left: 1.25rem;
    border-bottom: 1px solid var(--typhoon);
    vertical-align: middle;
    text-align: left;
    font-weight: 400;
    color: var(--dark);
  }

  &td.additional-options {
    text-align: right;
    padding-right: 1rem;
    min-width: 2rem;
    max-width: 2rem;
  }

  &td.button-controls {
    text-align: right;
    padding-right: 2rem;
    min-width: 5rem;
    max-width: 5rem;
  }

  &td.clickable {
    cursor: pointer;
    font-weight: 500;
    overflow: visible;
    white-space: nowrap;
  }
}

.extra-budget-button {
  color: var(--gray);
  padding: 0 10px;

  &:hover {
    cursor: pointer;
  }
}
