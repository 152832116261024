@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';
@import '../../../styles/layout.css';

.header-container {
  border-bottom: 1px solid var(--ash);
  padding: 0.5rem 1.5rem 1.25rem 1.25rem;
}

.top-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.work-order-name {
  font-size: var(--fs-xl);
  font-weight: 500;
  color: var(--dark);
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 1rem 1rem;
}

.tools-container {
  display: flex;
  align-items: center;
}

.header-info-section {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  font-size: var(--fs-md);
  color: var(--dark);
  padding-top: 1.25rem;
  white-space: nowrap;
}

.info-section-block {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  margin-right: 1.25rem;
  width: 25%;
}

.info-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inventory-name:hover {
  cursor: pointer;
}
