@import '../../styles/fonts.css';
@import '../../styles/colors.css';
@import '../../styles/layout.css';

:root {
  --interiorContainerHeight: calc(100vh - var(--fixedHeaderOffset));
  --topMargin: calc(var(--fixedHeaderOffset));
  --divider: 1px solid var(--typhoon);
}

.container {
  display: flex;
  flex-direction: row;
  padding-top: var(--topMargin);
  height: 100%;
}

.options-wrapper {
  display: inline-block;
}

.icon-button {
  padding: 0;
  margin: 0;
  border: 0;
  width: 1.25rem;
  height: 1.5rem;
}

.menu-item {
  text-transform: capitalize;
  height: 2.25rem;
  padding: 0;
  overflow: hidden;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.icon-box {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.primary-text-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
}

.primary-text {
  margin: 0 1rem;
  color: var(--dark);
  font-size: var(--fs-md);
}

.container-no-budgets {
  width: 100%;
  height: 75vh;
  margin: 0 2rem;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  overflow: hidden;
}
