@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.skeleton-list-row {
  display: flex;
  justify-content: center;
  padding: 1rem;
  margin: 0.5rem 0;
}

.skeleton-list-bullet {
  flex: 0 0 18px;
  padding: 0 0.5rem;
}

.skeleton-list-item {
  flex: 1;
  padding: 0 0.5rem;
}

.skeleton-table-row {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.skeleton-table-row:not(:last-child) {
  border-bottom: 1px solid var(--ash);
}

.skeleton-table-cell-1 {
  flex: 0 1 250px;
  padding: 0 0.5rem;
}

.skeleton-table-cell-2 {
  flex: 1 1 512px;
  padding: 0 0.5rem;
}

.skeleton-table-cell-3 {
  flex: 0 1 120px;
  padding: 0 0.5rem;
}

.skeleton-table-cell-4 {
  flex: 0 1 120px;
  padding: 0 0.5rem;
}
