@import '../../styles/layout.css';
@import '../../styles/fonts.css';
@import '../../styles/colors.css';

:root {
  --control-width: 60%;
  --category-list-row-height: 3.5em;
  --icon-dimension: 24px;
}

.subnav-container {
  margin-top: var(--fixedHeaderOffset);
  height: 3.125rem;
  width: 100%;
}

.subnav-controls-container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: flex-end;
  border-bottom: 2px solid var(--typhoon);
}

.subnav-control {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
  height: 100%;
  color: var(--dark);
  width: 6.25rem;
  font-weight: 500;
  border-bottom: 3px solid var(--white);
  user-select: none;

  &.active {
    color: var(--blue);
    border-bottom: 3px solid var(--blue);
  }
}

.modal-container {
  height: 100%;
  border-top: 1px solid var(--ash);
  border-bottom: 1px solid var(--ash);
}

.modal-content {
  padding: 1rem 2.5rem 4.5rem 2.5rem;
  overflow: auto;
}

.name-control {
  width: var(--control-width);
  position: relative;
}

.cost-control {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: var(--control-width);
  position: relative;
}

.cost-text {
  padding-top: 1.75rem;
  text-transform: uppercase;
  white-space: nowrap;

  &.left {
    padding-right: 0.5rem;
  }

  &.right {
    padding-left: 0.5rem;
  }
}

.cost-subtext {
  padding-left: 1rem;
  font-size: var(--fs-sm);
}

.upload-button-control-container {
  width: 80%;
}

.data-type-control {
  width: var(--control-width);
  position: relative;
  margin-bottom: 2rem;
}

.modal-spinner-container {
  padding: 2.5rem;
  height: 40vh;
}

.step-heading,
.folder-heading {
  font-weight: 500;
  font-size: var(--fs-md);
  color: var(--dark);
}

.info-text-heading {
  font-size: var(--fs-md);
  color: var(--dark);
  margin-bottom: 1rem;
}

.category-list-row-container {
  min-height: var(--category-list-row-height);
  display: flex;
  flex-direction: column;
}

.category-list-row {
  height: var(--category-list-row-height);
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  min-width: 7rem;

  &.selected {
    font-weight: 500;
    background-color: var(--highlight);
  }
}

.category-modal-selection-backdrop {
  width: fit-content;
  min-width: 100%;
  height: 15rem;
  color: var(--dim);
  background: var(--fog);
  font-size: var(--fs-md);
  line-height: 2.5em;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}

.sub-category-list-container {
  padding-left: 2.5em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  page-break-before: always;
}

.folder-label-container {
  width: 100%;
  cursor: pointer;
  user-select: none;
  display: flex;
  position: relative;
}

.category-label {
  width: 100%;
  margin-left: 0.5rem;
  margin-top: 0.125rem;
  display: flex;
  position: relative;
}

.category-toggle-icon {
  display: inline-block;
  margin-left: -1.75em;
  margin-top: 0.5em;
  width: 24px;
  position: relative;
}

.tile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 1px 3px 0 var(--black0_2);
  background-color: var(--fog);
  border-radius: 2px;
  cursor: pointer;
}

.tile-icon-container {
  width: var(--icon-dimension);
  height: var(--icon-dimension);
}

.tile-title {
  flex-grow: 1;
  line-height: 3rem;
  text-indent: 1em;
  font-weight: 400;
  color: var(--dark);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.csv-error-message {
  width: 100%;
  color: var(--error);
  font-size: var(--lh-xs);
}

.file-container {
  width: 100%;
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.validation-container {
  margin-bottom: 1rem;
  padding-left: 1rem;
  width: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
}

.icon-container {
  width: 30px;
}

.validation-text {
  padding-left: 0.5rem;
  font-size: var(--fs-md);
}

.error {
  color: var(--red);
}

.succeed {
  color: var(--green);
}

.validating {
  color: var(--blue);
}

.csv-error-container {
  width: 100%;
}

.error-box {
  margin-top: 0.5rem;
  padding: 0.75rem;
  background-color: rgba(237, 54, 32, 0.05);
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
}

.error-body {
  width: 100%;
  margin-left: 1rem;
}

.error-icon {
  margin-top: -3px;
}

.error-header {
  font-size: var(--fs-md);
  font-weight: 500;
  color: var(--red);
}

.error-message-text {
  font-size: var(--fs-md);
  color: var(--red);
  line-height: 1.3125rem;
  margin-top: 0.75rem;
}

.schema-menu-item-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
}

.schema-menu-item-title {
  padding-left: 0.3125rem;
}

.new-schema-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.choose-button {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  margin: 1rem 0;
  color: var(--blue);

  &:hover {
    cursor: pointer;
  }
}

.bulk-modal-container {
  padding: 0 2rem;
}

.bulk-modal-row {
  display: flex;
  align-items: center;

  &.checkbox {
    margin-top: 0.5rem;
    padding-left: 2.75rem;
  }
}

.bulk-modal-checkbox-label {
  color: var(--dark);
  font-size: var(--fs-md);
}

.bulk-modal-custom-date-label {
  color: var(--dark);
  font-size: var(--fs-md);
}

.bulk-modal-custom-due-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 3.5rem;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
}

.template-tile-container {
  width: 100%;
  margin: 1rem -0.625rem 1rem 0;
}

.back-title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0;
}

.folder-icon-container {
  position: relative;
  padding: 0.125rem;
}

.folder-changes-icon {
  height: 0.375rem;
  width: 0.375rem;
  background: var(--inprogress);
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
}

.breadcrumb-container {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  overflow: hidden;
  height: 3.125rem;
}

.breadcrumb {
  cursor: pointer;
  color: var(--white);
  font-weight: 500;

  &:last-of-type {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.breadcrumb-menu-item {
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  font-size: var(--fs-md);
  box-sizing: border-box;
  width: 336px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;

  &:hover {
    cursor: pointer;
    background-color: var(--fog);
  }
}

.bulk-header-container {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  margin-top: var(--topNavHeight);
  z-index: 200;
  width: 100vw;
  background-color: var(--white);
  height: var(--subNavHeight);
  border-bottom: 1px solid var(--ash);
}

.bulk-left-container {
  display: flex;
  align-items: center;
  font-size: var(--fs-lg);
  font-weight: 500;
}
