@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.form-row {
  margin-bottom: 1.5rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
}

.separator {
  margin: 0 1rem;
}

.helper-text {
  font-size: var(--fs-sm);
}

.error {
  color: var(--error);
}
