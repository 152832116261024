@import '../../styles/layout.css';
@import '../../styles/colors.css';
@import '../../styles/fonts.css';

:root {
  --offset: calc(var(--teamDetailHeaderOffset) + 22px);
  --interiorContainerHeight: calc(100vh - var(--offset));
}

.container {
  height: 100%;
}

.spinner-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-container {
  width: 100%;
  height: 100%;
  min-height: var(--interiorContainerHeight);
  max-height: var(--interiorContainerHeight);
  margin-top: var(--offset);
  display: inline-block;
}

.center-pane-container {
  background-color: var(--typhoon);
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}

.center-pane {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  width: 70%;
}
