@import '../../styles/layout.css';
@import '../../styles/fonts.css';
@import '../../styles/colors.css';

:root {
  --navigationBarHeight: 2.5rem;
  --headerOffset: 180px;
  --containerHeight: calc(100vh - var(--containerOffset));
  --containerOffset: calc(var(--fixedHeaderOffset) + 0.25rem);
}

.portal-wrapper {
  overflow: hidden;
}

.navigation-bar-container {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  top: 0;
  margin-top: var(--topNavHeight);
  z-index: 200;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: var(--purple);
  height: var(--subNavHeight);
  padding-right: 1rem;
}

.back-navigation-container {
  display: flex;
  flex-flow: row nowrap;
  line-height: 3.125rem;
  height: 3.125rem;
  align-items: center;
  cursor: pointer;
  user-select: none;
  color: var(--white);
  font-size: var(--fs-lg);

  & .back-button-icon {
    display: flex;
    padding-left: 1.625rem;
  }

  & .back-button-text {
    display: flex;
    font-size: var(--fs-lg);
    padding-left: 1rem;
  }
}

.controls-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  & .asset-total {
    font-size: var(--fs-md);
    font-weight: 500;
    color: var(--white);
    margin-right: 1rem;
  }
}

.content-container {
  padding-top: var(--fixedHeaderOffset);
  height: var(--containerHeight);
  display: flex;
  flex-flow: row nowrap;
}

.section-pane {
  display: flex;
  position: relative;
  width: 34%;
  overflow: hidden;
  box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.2);
  flex-direction: column;
  z-index: 200;

  &.center {
    z-index: 100;
  }

  &.right {
    width: 33%;
  }
}

.pane-header {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 3.125rem;
  padding: 1.25rem;
  font-weight: 500;
  border-bottom: 1px solid var(--ash);
}

.item-tree-container {
  overflow: auto;
  display: inline-block;
  height: 100%;
  padding-bottom: 2rem;
}

.row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 0.5rem 0 1rem;
  height: 3.125rem;
  min-height: 3.125rem;

  &.selected {
    background-color: var(--fog);
  }
}

.schema-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 0.5rem 0 1rem;
  height: 3.125rem;
  min-height: 3.125rem;

  &:hover {
    cursor: pointer;
    background-color: var(--typhoon);
  }

  &.selected {
    background-color: var(--fog);
  }
}

.category-row-name {
  font-weight: 500;
  margin-left: 0.75rem;
}

.schema-row-name {
  font-weight: 500;
  margin-left: 0.75rem;

  &.group {
    text-transform: uppercase;
    color: var(--gray);
  }
}

.schema-row-count {
  font-weight: 500;
  color: var(--blue);
}

.schema-row-name-container {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
}

.name-container {
  display: flex;
  flex-flow: column nowrap;
}

.category-row-subtext {
  margin-left: 0.75rem;
  color: var(--gray);
  font-size: var(--fs-sm);
  text-transform: capitalize;
}

.attribute-group-header {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 3.125rem;
  border-bottom: 1px solid var(--ash);
  padding: 0 0.5rem 0 1.25rem;
}

.attribute-section {
  display: none;
  border-bottom: 1px solid var(--ash);
  padding: 0 20px;
  flex-flow: column nowrap;

  &.open {
    display: flex;
  }
}

.attribute-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0.3125rem 0;
}
