@import '../../styles/layout.css';
@import '../../styles/colors.css';
@import '../../styles/fonts.css';

.portal-container {
  display: flex;
  flex-flow: row nowrap;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 6.875rem;
}

.pane {
  height: 100%;
  overflow: hidden;

  &.left {
    width: var(--sidePaneWidth);
    background-color: var(--typhoon);
    overflow-y: auto;
  }

  &.right {
    width: calc(100% - var(--sidePaneWidth));
    background-color: var(--white);
  }
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  margin-top: var(--topNavHeight);
  z-index: 200;
  width: 100vw;
  background-color: var(--blue);
  height: var(--subNavHeight);
}

.breadcrumb-name-container {
  display: inline-block;
  box-sizing: border-box;
  padding-left: 1rem;
  font-weight: 500;
  color: var(--white0_6);
  font-size: var(--fs-lg);
  line-height: var(--subNavHeight);

  &:not(:last-of-type):after {
    display: inline-block;
    padding-left: 1rem;
    cursor: default;
    font-family: 'Material Icons';
    content: '\E5CC';
    font-size: var(--fs-xxxl);
  }

  &:last-of-type {
    color: var(--white);
    font-weight: 500;
    cursor: default;
  }
}
