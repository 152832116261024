@import '../../../../styles/layout.css';
@import '../../../../styles/colors.css';
@import '../../../../styles/fonts.css';

.schema-icon {
  padding-right: 0.5rem;
}

.sub-item-content {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: 400;
  font-size: var(--fs-md);
}

.sub-item-action-buttons {
  padding-left: 0.5rem;
}

.item-tree-container {
  overflow: auto;
  display: inline-block;
  height: 100%;
  min-width: 100%;
  padding-bottom: 2rem;
}

.sub-item-name {
  white-space: nowrap;
  max-width: 270px;

  &.group {
    max-width: 300px;
  }

  &.root {
    max-width: 330px;
  }
}

.sub-item-overflow-container {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;

  &.subtext {
    font-size: var(--fs-sm);
    color: var(--gray);
  }
}

.root-button-container {
  white-space: nowrap;
}

.sub-item-subtext {
  font-size: var(--fs-sm);
  color: var(--gray);
}
