@import './layout.css';
@import './fonts.css';
@import './colors.css';

:root {
  --interiorContainerHeight: calc(100vh - var(--detailFixedHeaderOffset));
  --headerHeight: 50px;
  --thumbnailWidth: 180px;
  --thumbnailHeight: 180px;
  --videoPlaceholderWidth: 250px;
  --videoPlaceholderHeight: 150px;
}

.body-container {
  width: 100%;
  height: 100%;
  min-height: var(--interiorContainerHeight);
  max-height: var(--interiorContainerHeight);
  margin-top: var(--detailFixedHeaderOffset);
  display: inline-block;
}

.left-body-pane-half,
.right-body-pane-half,
.left-body-pane-fixed,
.right-body-pane-fill {
  display: inline-block;
  height: 100%;
  overflow: auto;
}

.left-body-pane-half {
  background-color: var(--white);
  width: 50%;
  position: relative;
  overflow-x: hidden;
}

.right-body-pane-half {
  width: 50%;
}

.center-pane-container {
  background-color: var(--typhoon);
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}

.center-pane {
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  width: 60%;
}

.left-body-pane-fixed {
  width: var(--sidePaneWidth);
  background-color: var(--typhoon);
}

.right-body-pane-fill {
  width: calc(100% - var(--sidePaneWidth));
  background-color: var(--fog);

  &.comments {
    background-color: var(--white);
  }
}

.title-header {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 200;
  height: var(--headerHeight);
  box-sizing: border-box;
  line-height: var(--headerHeight);
  background-color: var(--white);
  border-top: 1px solid var(--typhoon);
  user-select: none;
  border-bottom: 1px solid var(--ash);
}

.info-header-label {
  padding-left: 1.25rem;
  display: inline-block;
  box-sizing: border-box;
  color: var(--dark);
  width: 80%;

  &.selected-items {
    color: var(--blue);
  }
}

.header-label {
  padding-left: 1.25rem;
  display: inline-block;
  box-sizing: border-box;
  width: 80%;

  &.selected-items {
    color: var(--blue);
  }
}

.header-buttons {
  display: inline-block;
  box-sizing: border-box;
  padding-right: 1rem;
  min-width: 2rem;
  width: 20%;
  margin-top: 1px;
  text-align: right;
}

.text {
  display: inline-block;
  color: var(--dark);
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.additional-options {
  display: inline-block;
  padding-top: 0.75rem;
}

.photos-container,
.videos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin: 2em 1em 1em 2em;
}

.photo-container {
  display: flex;
  flex-direction: row;
  position: relative;
  width: var(--thumbnailWidth);
  height: var(--thumbnailHeight);
  margin-bottom: 1em;
  margin-right: 1em;

  & img {
    object-fit: cover;
    width: var(--thumbnailWidth);
    height: 100%;
    min-height: var(--thumbnailHeight);
  }
}

.video-container {
  display: flex;
  flex-direction: row;
  position: relative;
  width: var(--videoPlaceholderWidth);
  height: var(--videoPlaceholderHeight);
  margin-bottom: 4.25rem;
  margin-right: 1.25rem;
  background-color: var(--ash);

  & .link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

.video-overlay {
  position: absolute;
  bottom: -3rem;
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  background-color: var(--fog);

  & .text {
    width: 11.25rem;
  }
}

.photo-overlay {
  z-index: 100;
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  background-color: var(--white0_8);

  & .text {
    width: 6.5rem;
  }

  & .main-media-text {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    width: 4rem;
  }
}

.download-link {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

.sub-body-pane-fill {
  background-color: var(--typhoon);
  height: 100%;
  overflow: auto;
}

.location-section {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--mist);
  padding: 0 2rem;
}

.location-image {
  height: 13rem;
  width: 15.5rem;
}

.location-content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.location-title {
  padding-top: 1rem;
  font-weight: 500;
  font-size: var(--fs-md);
  color: var(--dark);
}

.location-text {
  padding-top: 1rem;
  text-align: center;
  font-size: var(--fs-md);
  color: var(--gray);
  max-width: 20rem;
}
