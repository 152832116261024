@import '../../../../styles/layout.css';
@import '../../../../styles/colors.css';
@import '../../../../styles/fonts.css';

.status-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border: 1px solid var(--white);
  margin-left: 1rem;
  padding: 5px;
  border-radius: 4px;
}

.status-text {
  font-size: var(--fs-md);
  color: var(--white);
  font-weight: 500;
  text-transform: uppercase;
  padding-left: 0.5rem;
}

.status-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
