@import '../../../../../styles/colors.css';
@import '../../../../../styles/layout.css';
@import '../../../../../styles/fonts.css';

.step {
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-direction: row;
  padding: 0.75rem 1rem;
  gap: 0.75rem;
  width: 14rem;
}

.step-title-container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 400;
  width: 14rem;
}

.step-title {
  color: var(--dark);
  line-height: var(--lh-lg);
  font-size: var(--fs-md);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 10rem;
}

.step-subtext {
  align-items: flex-start;
  color: var(--dim);
  font-size: var(--fs-sm);
  line-height: var(--lh-sm);
  width: 10rem;
}

.step-active {
  background-color: var(--highlight);
}

.active {
  color: var(--activeBlue);
}

.invalid {
  color: var(--red);
}
