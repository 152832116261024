@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.builder-column {
  display: inline-block;
  box-sizing: border-box;
  border-right: 1px solid var(--ash);
  width: calc(100% / 3);
  height: 100%;
  overflow: auto;

  &:nth-child(3) {
    border-right: none;
  }
}
