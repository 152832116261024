@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

.attributes-tab-container {
  flex: 1 1 auto;
  height: 27.25rem;
  padding: 0;
  overflow: auto;
}

.attribute-group-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  padding: 0 1rem 0 1.5rem;
  border-bottom: 1px solid var(--ash);
}

.attribute-list {
  padding: 0.5rem 0 1rem 0;
  border-bottom: 1px solid var(--ash);
}

.attribute-row {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem 0.5rem 1.5rem;
  min-height: 3rem;
}

.attribute-name {
  width: 15rem;
}

.attribute-input {
  width: 20.5rem;
}

.action-container {
  display: flex;
  align-items: center;
  color: var(--red);
}
