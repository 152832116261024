@import '../styles/fonts';
@import '../styles/colors';
@import '../styles/layout';

.attribute-change-container {
  display: flex;
  flex-flow: row nowrap;
  max-width: 20rem;

  &.column {
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column;
  }
}

.attribute-strike-through {
  display: flex;
  position: relative;
  text-decoration: line-through;
  text-decoration-color: var(--blue);
  text-decoration-style: solid;
}

.attribute-new-value {
  display: flex;
  color: var(--blue);
  font-weight: 500;
}

.attribute-new-value-offset {
  margin-left: 0.75em;
}

.cost-container {
  white-space: nowrap;
}

.cost-unit {
  text-transform: uppercase;
}
