@import '../../styles/layout.css';
@import '../../styles/fonts.css';
@import '../../styles/colors.css';

.section {
  display: flex;
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
  align-items: center;
}

.update-inventory-text {
  margin-top: 0.8rem;
}

.dropzone-section {
  margin-top: 1rem;
}

.error-bullet {
  background: var(--red);
  border-radius: 50%;
  height: 3px;
  width: 3px;
  margin-right: 0.5rem;
  flex: none;
}

.error-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--red);
  font-weight: 400;
  font-size: var(--fs-md);
  line-height: 1.25rem;
}
