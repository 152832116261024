@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';
@import '../../../../styles/layout.css';

.collapsed-row {
  border-bottom: 1px solid var(--ash);
  padding: 0.5rem 1.5rem 1.25rem 1.25rem;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-header-section {
  display: flex;
  align-items: center;
}

.right-header-section {
  display: flex;
  align-items: center;
}

.task-title {
  font-size: var(--fs-lg);
  font-weight: 500;
  color: var(--dim);
  padding-left: 1rem;
}

.change-indicator-container {
  position: relative;
}

.change-indicator {
  height: 0.3125rem;
  width: 0.3125rem;
  background: var(--blue);
  position: absolute;
  top: 0;
  right: -0.1875rem;
  border-radius: 50%;
}

.no-assignees-message {
  color: var(--gray);
}

.users-section {
  margin-left: 3rem;
}

.task-dates {
  padding-top: 0.75rem;
  margin-left: 3rem;
  display: flex;
  flex-flow: row nowrap;
  color: var(--gray);
}

.date-spacer {
  padding: 0 0.5rem;
}
