@import '../../../../styles/colors.css';
@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';

:root {
  --navSidePaneWidth: 60px; /* TODO: [AM-309] Commonize navSidePaneWidth) into layouts.css */
  --listHeight: 100%;
  --divider: 1px solid var(--typhoon);
  --sectionGutter: 1.25rem;
  --headerGutter: 0.65rem;
}

.container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: var(--sidePaneWidth);
  background-color: var(--white);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  height: 100%;
}

.container-footer {
  padding: 0.5rem 0;
}

.main-header {
  display: flex;
  padding: 1rem 0;
  width: 100%;
  height: 1.25rem;
  min-height: 1.25rem;
  text-indent: var(--sectionGutter);
  color: var(--dark);
  border-top: var(--divider);
  border-bottom: var(--divider);
}

.section-container {
  padding: 0.9375rem 0.625rem 1rem 0;
  border-bottom: var(--divider);
}

.section-header-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--headerGutter);
}

.toggle-label {
  font-weight: 500;
  display: flex;
  flex-flow: row nowrap;
}

.section-header-icon {
  display: flex;
  margin-right: 0.625rem;
  margin-left: 1rem;
  width: 1.5rem;
}

.section-header-toggle {
  display: flex;
  flex-basis: 100%;
}

.section-filters {
  margin-top: 0.5rem;
  display: none;
  flex-flow: column nowrap;

  &.expanded {
    display: flex;
  }
}

.section-filter-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: var(--sectionGutter);
  min-height: 2.5rem;
}

.section-filter-row-text {
  margin-left: 0.75rem;
  color: var(--dark);
}

.section-filter-col {
  width: 100%;
  display: flex;
  align-items: center;
}

.select-all-container {
  height: 1.875rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: var(--sectionGutter);
  box-sizing: border-box;
}

.select-all-divider {
  width: 1px;
  background: var(--silver);
  height: 1.125rem;
  margin: 0 0.625rem;
}

.select-all-label {
  font-size: var(--fs-sm);
  color: var(--blue);
  cursor: pointer;

  &.disabled {
    color: var(--gray);
    cursor: default;
  }
}

.filter-container {
  margin-top: 1rem;
  padding-left: var(--sectionGutter);
}

.section-label {
  font-size: var(--fs-sm);
  color: var(--gray);
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  font-weight: 500;
  padding-left: var(--sectionGutter);
}
