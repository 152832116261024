@import '../../../../styles/layout.css';
@import '../../../../styles/colors.css';
@import '../../../../styles/fonts.css';

.header-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 41rem;
}

.modal-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.title {
  font-size: var(--fs-xl);
  color: var(--dark);
  font-weight: 500;
}

.tooltip {
  font-size: var(--fs-sm);
  font-weight: 400;
  padding: 0.625rem;
  padding-bottom: 0;
}

.tooltip-row {
  display: flex;
  flex-flow: row;
  padding-bottom: 0.625rem;
}

.tooltip-content {
  padding-left: 0.625rem;
}

.content-row {
  margin-bottom: 1rem;
  font-size: var(--fs-md);
  color: var(--dark);
}

.modal-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;

  &.folder {
    margin: 1rem 0;
  }
}

.tile-container {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  height: 50px;
  width: 100%;
}

.choose-button {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-left: 1rem;
  color: var(--blue);

  &:hover {
    cursor: pointer;
  }

  &.align-left {
    margin-left: 0;
  }
}

.schema-menu-item-title {
  padding-left: 0.5rem;
  font-size: var(--fs-md);

  &.empty {
    color: var(--gray);
  }
}

.schema-menu-item-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
}

.empty-icon {
  color: var(--gray);
  padding: 0 14px;
}

.create-container {
  padding: 2rem;
}

.settings-content-row {
  margin-bottom: 1rem;
  font-size: var(--fs-md);
  color: var(--dark);
  
  &.italics {
    font-style: italic;
  }
}

.selection-title {
  color: var(--dark);
  font-size: var(--fs-sm);
  padding-bottom: 1rem;
}

.settings-buttons-container {
  display: flex;
  flex-flow: row nowrap;
  padding-bottom: 1rem;
}

.settings-button {
  font-size: var(--fs-sm);
  color: var(--gray);
  padding-left: 0.625rem;

  &.enabled {
    color: var(--blue);
  }

  &.left {
    border-right: 1px solid var(--silver);
    padding-right: 0.625rem;
    padding-left: 0;
  }

  &:hover {
    cursor: pointer;
  }
}

.section-title {
  font-weight: 500;
  color: var(--dark);
  font-size: var(--fs-md);
  padding-bottom: 0.5rem;
}

.selection-container {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 0.5rem;
}

.type-container {
  border: 1px solid var(--ash);
  border-radius: 4px;
  margin-bottom: 1rem;
}

.section-header {
  font-weight: 500;
  margin-bottom: 1rem;
}

.section-subtext {
  font-weight: 400;
  font-size: var(--fs-sm);
}
