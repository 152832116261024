@import '../../../../../styles/colors.css';
@import '../../../../../styles/layout.css';
@import '../../../../../styles/fonts.css';

.layer-controls-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 2.25rem;
  margin-top: var(--mapControlsTopOffset);
  margin-left: 0.625rem;
  background-color: var(--white);
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
}

.layer-controls {
  padding-left: 0.5rem;
  padding-top: 0.375rem;
}

.icon-container {
  padding: 0 0.25rem;
  cursor: pointer;
  z-index: 0;
  user-select: none;
}

.tooltip-wrapper {
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

.tooltip-container {
  visibility: hidden;
  padding: 0 0.3125rem;
  opacity: 0;
  position: absolute;
  background: var(--black0_6);
  color: var(--white);
  font-size: var(--fs-sm);
  white-space: nowrap;
  box-shadow: var(--muiDropdownBoxShadow);
  transition: visibility 0s, opacity 0.25s linear;
  transition-delay: 0.3s;
  top: 3rem;
  right: 0;
}

.tooltip-wrapper:hover .tooltip-container {
  visibility: visible;
  opacity: 1;
}
