@import '../../../../styles/colors.css';
@import '../../../../styles/fonts.css';

.work-order-status-pill {
  color: var(--white);
  font-size: var(--fs-sm);
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  border-radius: 3px;
  line-height: 2em;
  margin: 1em 0 1em 1.25rem;
  max-height: 1.5rem;

  &.table {
    display: block;
    width: 6rem;
    height: 1.5rem;
    margin-left: 0;
  }

  &.bulk-modal {
    display: inline-block;
    width: 5.75rem;
    margin: 0;
  }

  &.detail {
    display: inline-block;
    width: 5.75rem;
  }

  &.detail-pane {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 5.75rem;
    margin: 0.75em 0 0.75em 1rem;
    line-height: 1.5rem;
  }

  &.dashboard-map-list {
    display: block;
    width: 6rem;
    height: 1.5rem;
    margin-left: 0;
  }
}
