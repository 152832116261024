@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';
@import '../../../../styles/layout.css';

:root {
  --iconDimension: 24px;
}

.section-container {
  box-sizing: border-box;
  display: flex;
  padding: 0 0.75rem 0 1.5rem;
}

.right-container {
  display: flex;
  flex-flow: column;
  margin-left: 1.25rem;
  width: 100%;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.5rem;
}

.left {
  display: flex;
  align-items: center;
}

.right {
  display: flex;
  align-items: center;
}

.section-title {
  font-size: var(--fs-md);
  font-weight: 500;
}

.total-count {
  margin-left: 1ch;
}

.form-tile-list {
  margin-left: 0.5rem;
}

.empty-section-text {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  color: var(--gray);
}
