@import '../../../styles/fonts';
@import '../../../styles/colors';
@import '../../../styles/layout';

.asset-group-header-container {
  height: 3.5em;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset var(--containerShadow), var(--containerShadow);
}

.asset-group-header-label {
  color: var(--dark);
  padding-left: 1.5rem;

  &.error {
    color: var(--red);
  }
}

.asset-group-header-edit-icon {
  margin: 0 1em;
  color: var(--gray);
}

.attribute-list-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  column-gap: 1rem;
}

.attribute-list-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
  width: 48%;
  min-width: 40rem;
  margin-left: 0.3125em;
  padding-bottom: 20px;

  /* 35px matches the height of the icons if they appear(.pending-attribute-control) */
  margin-top: 0;
  margin-bottom: 0;
}

.attribute-label {
  color: var(--gray);
  min-width: 15rem;
  overflow-wrap: break-word;

  &.edit {
    padding-top: 0.5rem;
    margin-right: 0.5rem;
  }

  &.error {
    color: var(--red);
  }
}

.attribute-value {
  flex-grow: 1;
  color: var(--dark);
  white-space: pre-line;
  display: block;
  text-align: left;
  overflow: hidden;
  overflow-wrap: break-word;
  position: relative;

  & div {
    overflow-wrap: anywhere;
  }
}

.attribute-approval-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
}

.pending-attribute-label {
  color: var(--gray);
  font-size: var(--fs-sm);
  margin-right: 1rem;
  display: flex;
}

.pending-attribute-control {
  display: flex;
}

.attribute-group-header-container {
  position: relative;
}

.spinner-container {
  position: absolute;
  top: 0;
  right: 12rem;
}

.change-indicator-container {
  position: relative;
}

.change-indicator {
  height: 0.3125rem;
  width: 0.3125rem;
  background: var(--blue);
  position: absolute;
  top: 0;
  right: -0.75rem;
  border-radius: 50%;
}
