@import '../../styles/colors.css';
@import '../../styles/fonts.css';
@import '../../styles/layout.css';

:root {
  --navigationBarHeight: 7.5rem;
  --headerOffset: 180px;
  --containerHeight: calc(100vh - 180px);
}

.search-navigation-bar-container {
  display: flex;
  position: absolute;
  top: 0;
  margin-top: var(--topNavHeight);
  z-index: 200;
  width: 100%;
  flex-flow: column nowrap;
  justify-content: space-between;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  height: var(--navigationBarHeight);
}

.search-back-navigation-container {
  display: flex;
  flex-flow: row nowrap;
  line-height: 3.125rem;
  height: 3.125rem;
  width: 12rem;
  align-items: center;
  cursor: pointer;
  user-select: none;

  & .back-button-icon {
    display: flex;
    padding-left: 1.625rem;
  }

  & .back-button-text {
    display: flex;
    font-size: var(--fs-lg);
    padding-left: 1rem;
  }
}

.search-entity-type-container {
  display: flex;
  flex-flow: row nowrap;
  padding-left: 2rem;
  padding-bottom: 1rem;

  & .search-entity-view-control {
    cursor: pointer;
    text-transform: uppercase;
    padding: 0 2rem;
  }
}

.search-portal-results-container {
  margin-top: var(--headerOffset);
  position: absolute;
  display: flex;
  width: 100%;
  min-height: var(--containerHeight);
  justify-content: center;
  background-color: var(--fog);
}

.search-portal-results {
  width: 70%;
  background-color: var(--white);
  display: flex;
  flex-flow: column nowrap;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.search-portal-results-heading {
  padding-left: 1rem;
  border-bottom: 1px solid var(--typhoon);
  height: 3.25rem;
  line-height: 3.25rem;
}

.search-entity-results-container {
  display: flex;
  flex-flow: column nowrap;
  font-size: var(--fs-md);
}

.search-entity-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2.25rem;
  padding: 0.5rem 2.25rem;
  cursor: pointer;
  border-bottom: 1px solid var(--typhoon);

  &.work {
    padding: 0.5rem 2.5rem;
  }

  &:hover {
    background-color: var(--fog);
    cursor: pointer;
  }

  &.additional-options {
    justify-content: space-between;
  }
}

.search-entity-row-icon-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search-entity-row .search-entity-row-text-container {
  line-height: 1.25rem;
  margin: 0 1rem;

  & .search-entity-row-text {
    color: var(--dark);
  }

  & .search-entity-row-subtext {
    font-size: var(--fs-sm);
    color: var(--gray);
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
