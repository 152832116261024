@import '../../../styles/fonts';
@import '../../../styles/colors';
@import '../../../styles/layout';

.section-title {
  font-size: var(--fs-sm);
  color: var(--dim);
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.type-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.type-tile {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0.5rem;
  margin-right: 1rem;
  color: var(--gray);

  &:hover {
    cursor: pointer;
  }

  &.selected {
    color: var(--blue);
    background-color: var(--blueHighlight);
  }
}

.type-tile-text {
  padding-left: 0.5rem;
  font-size: var(--fs-md);
}

.location-icon-container {
  padding-left: 1rem;
}

.color-container {
  border: 1px solid var(--ash);
  padding: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: space-between;
  flex-flow: row wrap;
}

.schema-icon {
  font-size: var(--fs-xxxl);
  border: 6px solid var(--white);
  border-radius: 2rem;
  width: 2.25rem;
  height: 2.25rem;
  margin: 1px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  &.selected {
    border-color: rgba(0, 125, 239, 0.3);
  }

  &:hover {
    border-color: rgba(0, 125, 239, 0.3);
    cursor: pointer;
  }
}
