@import '../../styles/colors.css';
@import '../../styles/fonts.css';

.boolean-attribute {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.number-container {
  position: relative;
  width: 80%;
}

.units-text {
  color: var(--dark);
  position: absolute;
  width: 20%;
  right: calc(-20% - 1em);
  bottom: 1em;
}

.currency-container {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding-left: 2ch;
  font-size: var(--fs-lg);
}

.currency-container:before {
  content: '$';
  position: absolute;
  left: 0;
  top: 0.5em;
}
