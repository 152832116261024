@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.customize-dashboard-options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.customize-dashboard-option {
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  border: 1px solid var(--ash);
  border-radius: 4px;
  cursor: pointer;
}

.option-icon {
  background: var(--fog);
  border-radius: 4px;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
}

.customize-dashboard-label {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.customize-option-heading {
  font-weight: 500;
  font-size: var(--fs-md);
}

.customize-option-subheading {
  font-size: 0.7rem;
  color: var(--gray);
}

.customize-option-right {
  flex: 1;
  text-align: right;
}

.add-customize-embed-form {
  display: flex;
  flex-direction: column;
}

.add-customize-embed-form > * {
  margin-bottom: 1rem;
}

.menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 100%;
  gap: 1rem;
}

.modal-header.custom-embed {
  flex: 0 1 auto;
}

.modal-header > span {
  cursor: pointer;
}
