:root {

  /* Brand Colors */
  --red: #ED3620;
  --blue: #0283EA;
  --turquoise: #00ACC1;
  --teal: #26A69A;
  --orange: #FF8F00;
  --green: #3FA142;
  --purple: #9209AE;
  --violet: #3E4EB8;
  --navy: #0048C7;
  --deep-blue: #4285F4;

  /* Neutral Colors */
  --white: #FFF;
  --mist: #FAFAFA;
  --fog: #F5F5F5;
  --typhoon: #EEE;
  --ash: #DDD;
  --silver: #CCC;
  --gray: #999;
  --dim: #555;
  --dark: #333;
  --dark-gray: #222;
  --black: #000;

  /* Opacity gradient colors */
  --black0_9: rgba(0, 0, 0, 0.9);
  --black0_8: rgba(0, 0, 0, 0.8);
  --black0_6: rgba(0, 0, 0, 0.6);
  --black0_4: rgba(0, 0, 0, 0.4);
  --black0_2: rgba(0, 0, 0, 0.2);
  --black0_0: rgba(0, 0, 0, 0);
  --white0_8: rgba(255, 255, 255, 0.8);
  --white0_6: rgba(255, 255, 255, 0.6);
  --white0_4: rgba(255, 255, 255, 0.4);
  --white0_2: rgba(255, 255, 255, 0.2);

  /* Work Status Colors */
  --requested: #8F66F7;
  --unassigned: #FF5722;
  --assigned: #FFA726;
  --inprogress: #1565C0;
  --completed: #4CAF50;
  --inreview: #1BBCD7;
  --closed: var(--dim);

  /* Work Calendar Status Colors */
  --calendarRequested: #D6BEFF;
  --calendarUnassigned: #FEB7A1;
  --calendarAssigned: #FEDBA1;
  --calendarInprogress: #9BC2EA;
  --calendarCompleted: #A9E1B3;
  --calendarClosed: #D7D7D7;
  --calendarInReview: #8DDDEB;

  /* Work Priority Colors */
  --none: var(--gray);
  --low: #24A42E;
  --medium: #E9A303;
  --high: #D25D5D;

  /* Utility colors */
  --positive: var(--green);
  --negative: var(--red);
  --error: #CC4B3C;
  --errorBackground: #FFEBEE;
  --highlight: rgba(0, 0, 0, 0.05);
  --link: var(--blue);
  --boxshadow: var(--black0_2);
  --disabled: var(--gray);
  --activeBlue: #007DEF;
  --blueHighlight: #F2F9FE;
  --lightblueHighlight: rgba(0, 125, 239, 0.08);
  --lightGreenHighlight: rgba(63, 161, 66, 0.1);
  --lightOrangeHighlight: rgba(255, 143, 0, 0.1);

  /* CI Colors */
  --ci-excellent: #018563;
  --ci-good: #8DC94D;
  --ci-fair: #F1B028;
  --ci-poor: #D25D5D;

  /* Material UI error */
  --mui-error: #D32F2F;
}
