@import '../../../../styles/colors.css';
@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';

.work-type-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  padding: 2rem 2.5rem;
  max-height: 50vh;
}

.work-type-title {
  color: var(--black);
  font-weight: 400;
  font-size: var(--fs-md);
  line-height: var(--lh-lg);
}

.work-type-hint {
  color: var(--dim);
  font-weight: 400;
  font-size: var(--fs-sm);
  line-height: var(--lh-md);
  padding: 0.625rem 0;
}
