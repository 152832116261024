@import '../../../../../styles/fonts.css';
@import '../../../../../styles/colors.css';
@import '../../../../../styles/layout.css';

.section-container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 0.75rem 0 1.5rem;
}

.tile-list {
  display: flex;
  flex-flow: row wrap;
  margin-left: 4.25rem;
}

.buttons-container {
  margin-left: 0.5rem;
}

.section-title {
  margin-left: 1.25rem;
  font-size: var(--fs-md);
  font-weight: 500;
}

.empty-section-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  color: var(--gray);
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-title {
  font-size: var(--fs-md);
  color: var(--dark);
  font-weight: 500;
}

.view-subtext {
  font-size: var(--fs-md);
  color: var(--gray);
  padding-bottom: 1rem;
}

.schema-title {
  padding-left: 0.5rem;
}

.schema-row {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}

.sub-list {
  padding-left: 2rem;
}

.schema-list-section {
  margin-left: 4.25rem;
}

.schema-list {
  display: flex;
  flex-flow: row wrap;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
