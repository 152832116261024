@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';
@import '../../../styles/layout.css';

.header-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid var(--ash);
  padding: 0.5rem;
}

.title {
  color: var(--dark);
  font-size: var(--fs-xl);
  font-weight: 500;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--ash);
  padding-left: 1.25rem;
}

.section-content {
  border-bottom: 1px solid var(--ash);
  padding: 1rem 0 1rem 2.5rem;

  &.location {
    height: 15rem;
    padding: 0;
  }

  &.custom {
    border-bottom: none;
    padding: 0;
  }
}

.section-title {
  font-size: var(--fs-md);
}

.info-section {
  height: calc(100% - 64px);
  overflow: auto;
}

.info-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  height: auto;
  font-size: var(--fs-md);
  padding-bottom: 0.5rem;
}

.row-label {
  width: 50%;
  color: var(--gray);
}

.info-value {
  width: 50%;
}

.local-display-date {
  font-size: var(--fs-md);
  opacity: 0.5;
}

.no-summary {
  color: var(--gray);
  font-size: var(--fs-md);
}

.summary-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.125rem;
  font-weight: 500;
}

.summary-item-content {
  line-height: 24px;
  padding-bottom: 1.25rem;
  white-space: pre-line;
  overflow-wrap: break-word;
}
