@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';
@import '../../../../styles/layout.css';

:root {
  --iconDimension: 24px;
}

.section-container {
  box-sizing: border-box;
  display: flex;
  padding: 0 0.75rem 0 1.5rem;
}

.right-container {
  display: flex;
  flex-flow: column;
  margin-left: 1.25rem;
  width: 100%;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.5rem;
}

.left {
  display: flex;
  align-items: center;
}

.right {
  display: flex;
  align-items: center;
}

.section-title {
  font-size: var(--fs-md);
  font-weight: 500;
}

.total-count {
  margin-left: 1ch;
}

.empty-section-text {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  color: var(--gray);
}

.change-indicator-container {
  position: relative;
}

.change-indicator {
  height: 0.3125rem;
  width: 0.3125rem;
  background: var(--blue);
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
}

.tile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  height: 3rem;
  padding-left: 1rem;
  padding-right: 0.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 1px 3px 0 var(--black0_2);
  background-color: var(--fog);
  border-radius: 2px;
  cursor: pointer;
}

.tile-content-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tile-icon-container {
  width: var(--iconDimension);
  height: var(--iconDimension);
}

.tile-content {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  white-space: nowrap;
  overflow: hidden;
}

.tile-title {
  font-size: var(--fs-md);
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
