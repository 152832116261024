@import '../../../styles/colors.css';
@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';

:root {
  --modalTopToolbarHeight: 3.125rem;
  --sideBarContainerWidth: 23.75rem;
  --photoHeight: 8.75rem;
  --photoTileWidth: 15.625rem;
}

.wrapper-container {
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

.modal-container {
  background-color: var(--black0_9);
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1100;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  overflow: hidden;
}

.carousel-container {
  box-sizing: border-box;
  padding: 1rem 3.5rem;
  height: calc(100vh - var(--modalTopToolbarHeight));
}

.left-pane-container {
  flex: 1 1 auto;
  height: 100%;
}

.modal-top-toolbar {
  box-sizing: border-box;
  display: flex;
  padding: 0 1rem;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: var(--modalTopToolbarHeight);
}

.file-name {
  font-weight: 500;
  color: var(--white);
  font-size: var(--fs-lg);
}

.back-button-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.toolbar-button-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.side-bar-container {
  width: var(--sideBarContainerWidth);
  height: 100%;
  background-color: var(--dark-gray);
  transition: margin-right 0.2s ease;
}

.side-bar-header {
  box-sizing: border-box;
  padding-left: 1rem;
  height: var(--modalTopToolbarHeight);
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--dim);
}

.side-bar-header-title {
  font-size: var(--fs-lg);
  color: var(--white);
  font-weight: 500;
}

.carousel-slide {
  height: calc(100vh - 7rem);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.arrow-container {
  box-sizing: border-box;
  text-align: center;
  background-color: var(--white);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2.5rem;
  margin-top: -2rem;
  padding-top: 0.5rem;

  &.prev {
    margin-left: -1.3rem;
  }
}

.side-bar-content-container {
  padding: 1.25rem;
  display: flex;
  flex-flow: column nowrap;
}

.side-bar-content-row {
  display: flex;
  flex-flow: row nowrap;
  padding-bottom: 1rem;
  font-size: var(--fs-md);
}

.side-bar-content {
  font-size: var(--fs-md);

  &.label {
    width: 30%;
    color: var(--gray);
    white-space: nowrap;
  }

  &.value {
    width: 70%;
    color: var(--white);
  }
}

.description-row {
  line-height: 1.5rem;
  color: var(--white);

  &:hover {
    cursor: pointer;
  }
}

.input-container {
  width: 100%;
}

.button-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.button {
  display: flex;
  justify-content: center;
  width: 3rem;
  text-transform: uppercase;
  font-weight: 500;

  &.save {
    color: var(--white);
  }

  &.cancel {
    color: var(--gray);
    margin-right: 1rem;
  }

  &:hover {
    cursor: pointer;
  }
}

.checkbox-container {
  width: 100%;
  display: none;

  &.checked {
    display: inline-block;
  }
}

.image-mask:hover .checkbox-container {
  display: inline-block;
}

.image-mask {
  position: absolute;
  top: 0;
  height: var(--photoHeight);
  width: var(--photoTileWidth);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  &.checked {
    cursor: pointer;
    background-image: linear-gradient(to bottom, var(--black0_4), var(--black0_0));
  }

  &:hover {
    cursor: pointer;
    background-image: linear-gradient(to bottom, var(--black0_4), var(--black0_0));
  }
}

.carousel-click-mask {
  width: 100%;
  height: 5.625rem;
}
