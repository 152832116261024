@import '../../styles/layout.css';
@import '../../styles/fonts.css';
@import '../../styles/colors.css';

:root {
  --interiorContainerHeight: calc(100vh - var(--detailFixedHeaderOffset));
}

.body-container {
  display: flex;
  flex-flow: row nowrap;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 6.875rem;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  margin-top: var(--topNavHeight);
  z-index: 200;
  width: 100vw;
  background-color: var(--purple);
  height: var(--subNavHeight);
}

.header-buttons {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: var(--white);
  font-size: var(--fs-lg);
}

.left-body-pane-fixed,
.right-body-pane-fill {
  display: inline-block;
  height: 100%;
  overflow: hidden;
}

.left-body-pane-fixed {
  width: var(--sidePaneWidth);
  height: 100%;
  background-color: var(--typhoon);
}

.right-body-pane-fill {
  width: calc(100% - var(--sidePaneWidth));
}

.requests-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  border-bottom: 1px solid var(--ash);
  padding: 0 1rem;
}

.progress-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
