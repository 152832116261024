@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

.field-drag-container {
  display: flex;
  align-items: center;
  max-width: 33.75rem;
  padding: 0.5rem;
}

.section-content {
  padding: 0 1.25rem 0 3.75rem;

  &.summary {
    padding: 1rem 0.75rem 1rem 2.8125rem;
    border-bottom: 1px solid var(--ash);
  }

  &.fields {
    padding: 0.5rem 0.75rem 1rem 0.5rem;
    border-bottom: 1px solid var(--ash);
  }

  &.hide {
    display: none;
  }
}

.enum-single-nested-container {
  display: flex;
  flex-flow: row wrap;
}

.modal-title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.modal-title-path {
  padding-left: 0.5rem;
  font-size: var(--fs-md);
  font-weight: 400;
}

.menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.field-summary {
  flex: 1;
}

.field-summary-title {
  width: 100%;
  font-weight: 500;
  align-self: center;
}

.field-summary-value {
  font-size: var(--fs-md);
  padding-top: 0.5rem;
  white-space: pre-line;
  overflow-wrap: break-word;
}

.hyperlink-container {
  display: 'flex';
  flex-direction: 'column';
}

.hyperlink-title {
  margin-bottom: '0.5rem';
  font-size: var(--fs-sm);
  color: var(--gray);
}
