@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.sidebar-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  box-sizing: border-box;
  height: calc(100vh - 110px);
  overflow: auto;
  width: var(--sidePaneWidth);
  background-color: var(--white);
  border-right: 1px solid var(--ash);
}

.sidebar-section {
  border-bottom: 1px solid var(--ash);
}

.section-header {
  height: 3.125rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-title {
  font-weight: 500;
  padding-left: 1rem;
}

.row-title {
  color: var(--dark);
  font-size: var(--fs-md);
  margin-left: 1rem;
  text-transform: capitalize;
}

.sidebar-row {
  display: flex;
  align-items: center;
  height: 3.125rem;
  border-left: 4px solid transparent;
  padding: 0 1.25rem;

  &.selected {
    border-left: 4px solid var(--blue);
    background-color: var(--fog);
  }

  &.sub {
    padding-left: 3.5rem;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--fog);
  }
}

.sidebar-row-time-group {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  min-height: 3.125rem;
  border-left: 4px solid transparent;
  padding: 0.5rem 1.25rem;

  &.selected {
    border-left: 4px solid var(--blue);
    background-color: var(--fog);
  }

  &.sub {
    padding-left: 3.5rem;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--fog);
  }
}
