@import '../../styles/fonts.css';
@import '../../styles/colors.css';
@import '../../styles/layout.css';

:root {
  --interiorContainerHeight: calc(100vh - var(--fixedHeaderOffset));
  --topMargin: calc(var(--fixedHeaderOffset) + 1rem);
}

.container {
  padding: var(--topMargin) 1.5rem 0.75rem;
  position: relative;
}

.comparison-menu-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--fs-md);
  font-weight: 500;
  position: absolute;
  top: 1rem;
  left: 1rem;
  right: 1rem;
}

.comparison-none-selected {
  padding: 1rem;
  width: 28rem;
}

.category-name-cell {
  font-weight: 500;
}

.budget-table-cell {
  font-size: var(--fs-md);
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.edit-hover {
  width: 95%;
  height: 100%;
  padding: 0.5rem 0.15rem;
  border-radius: 0.25rem;

  &:hover {
    outline: 1px solid var(--ash);
  }
}

.budget-total-cell {
  font-weight: 500;
}

.unit-nav-item-container:hover {
  background: var(--lightblueHighlight);
}

.clickable {
  cursor: pointer;
}

.text-no-wrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
