@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

:root {
  --interiorContainerHeight: calc(100vh - var(--detailFixedHeaderOffset));
  --headerHeight: 50px;
}

.center-pane {
  height: var(--interiorContainerHeight);
  background-color: var(--white);
  width: 70%;
  margin-left: 15%;
  overflow: auto;
}

.child-types-container {
  height: var(--interiorContainerHeight);
  background-color: var(--typhoon);
}

.header-label {
  box-sizing: border-box;
  height: 3.125rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-bottom: 1px solid var(--ash);
}
