@import '../../styles/layout.css';
@import '../../styles/colors.css';
@import '../../styles/fonts.css';

:root {
  --interiorContainerHeight: calc(100vh - var(--detailFixedHeaderOffset));
}

.tab {
  width: 100%;
  height: var(--interiorContainerHeight);
  margin-top: var(--detailFixedHeaderOffset);
  display: inline-block;
  overflow: hidden;
}
