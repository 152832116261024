@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

.tile-container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 2rem;
  border: 1px solid var(--gray);
  padding-left: 8px;
  border-radius: 32px;
  font-size: var(--fs-md);
  color: var(--dim);
  cursor: pointer;
  margin-right: 0.625rem;
  margin-bottom: 0.625rem;
}

.content {
  padding: 0 0.5rem;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
}
