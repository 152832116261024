@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.modal-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.policies-title {
  margin-top: 1rem;
  font-size: var(--fs-sm);
  color: var(--dark);
}

.policy-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
