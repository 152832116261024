@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.subtext {
  font-size: var(--fs-sm);
  color: var(--gray);
}

.header-date-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  line-height: 15px;
}

.header-date-day {
  font-weight: 600;
  font-size: var(--fs-sm);
}

.header-date {
  font-size: var(--fs-xs);
}

.status-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.status-title {
  text-transform: capitalize;
  margin-left: 0.5rem;
}

.empty-title {
  font-style: italic;
}

.name-cell:hover {
  cursor: pointer;
}
