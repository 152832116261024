.map-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.map-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 60;
}
