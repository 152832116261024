@import '../../styles/layout.css';
@import '../../styles/fonts.css';
@import '../../styles/colors.css';

.empty-list-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.empty-list-icon {
  color: var(--ash);
}

.empty-list-text {
  color: var(--dim);
}
