@import '../../../../styles/fonts';
@import '../../../../styles/colors';
@import '../../../../styles/layout';

.element-detail-spinner-container {
  position: relative;
  height: 50vh;
}

.icon-row {
  padding-left: 0.25rem;
  padding-top: 0.5rem;
  box-shadow: inset var(--containerShadow), var(--containerShadow);
  background-color: var(--white);
  position: sticky;
  top: 0;
  z-index: 100;
}

.element-name {
  margin-top: 0.5rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  color: var(--dark);
  font-size: var(--fs-lg);
  font-weight: 500;
}
