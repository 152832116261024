@import '../../styles/colors.css';

.signs-section-title {
  font-weight: 500;
  font-size: 1.125rem;
  padding-bottom: 0.5rem;
}

.signs-image {
  height: 3.75rem;
  width: 3.75rem;
}

.table-wrapper {
  page-break-after: always;

  & table {
    width: 100%;
    border-collapse: collapse;
  }
  
  & tr {
    border-bottom: solid 1px var(--ash);
  }

  & th {
    width: 9.375rem;
    text-align: left;
  }

  & td {
    height: 3.75rem;
    vertical-align: middle;
  }
}
