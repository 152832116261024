@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--fs-xxl);
  width: 100%;
  height: 2rem;
}

.right-header-container {
  display: flex;
  align-items: center;
}
