@import '../../../../styles/colors.css';
@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';

.map-tools-wrapper {
  position: absolute;
  top: 0;
  right: 0.625rem;
  display: flex;
}

.spinner-container {
  display: flex;
  align-items: center;
  height: 2.25rem;
  width: 1.25rem;
  margin-top: 5px;
}

.center-button-container {
  position: absolute;
  bottom: 12rem;
  right: 0.625rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
  background-color: var(--white);
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
}

.icon-container {
  padding: 0 0.25rem;
  cursor: pointer;
  z-index: 0;
  user-select: none;
}
