@import '../../../../../../../styles/colors.css';
@import '../../../../../../../styles/layout.css';
@import '../../../../../../../styles/fonts.css';

.user-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
}
