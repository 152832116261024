@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.header-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.25rem;
  padding-right: 0.5rem;
  height: 3.125rem;
  border-bottom: 1px solid var(--ash);
}

.modal-container {
  display: flex;
  flex-flow: column nowrap;
  padding: 1.5rem;
  overflow: hidden;
}

.left-textfield {
  width: 60%;
}

.right-textfield {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 35%;
}

.prefix-icon {
  margin-top: 28px;
  margin-right: 5px;
}

.cert-list-container {
  box-sizing: border-box;
  height: calc(100vh - 275px);
  padding: 0 3rem 1rem 3rem;
  overflow: auto;
}

.cert-tile {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0.5rem 0.75rem 1rem;
  border: 1px solid var(--ash);
  margin-top: 1rem;
  min-height: 4.125rem;
}

.cert-title-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.cert-name-container {
  padding-left: 1rem;
}

.cert-name {
  font-size: var(--fs-md);
  font-weight: 500;
}

.subtext-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.subtext {
  color: var(--gray);
  font-size: var(--fs-md);
  padding-left: 0.5rem;
  text-transform: capitalize;
}
