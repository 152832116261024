@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.tab-container {
  background-color: var(--white);
  display: flex;
  align-items: center;
  padding-bottom: 1.25rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.tab {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: var(--gray);
  font-size: var(--fs-md);
  font-weight: 500;

  &.active {
    color: var(--blue);
  }

  &:hover {
    cursor: pointer;
  }
}

.tab-count {
  margin-left: 0.5rem;
}
