@import '../../../styles/colors.css';
@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';

.wrapper {
  position: absolute;
  top: 3.5rem;
  right: 0.625rem;
}

.container {
  box-sizing: border-box;
  background: var(--white);
  padding: 0 1rem;
  width: 18rem;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.5rem;
}

.title-content {
  display: flex;
  align-items: center;
}

.main-title {
  font-size: var(--fs-lg);
  font-weight: 500;
  margin-right: 0.5rem;
}

.row-value {
  display: flex;
  align-items: center;
  height: 1.5rem;
}

.content-row {
  padding-bottom: 1rem;
}

.row-title {
  color: var(--gray);
  font-weight: 500;
}

.message-row {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;

  &.orange {
    color: var(--orange);
  }

  &.green {
    color: var(--green);
  }

  &.hidden {
    display: none;
  }
}
