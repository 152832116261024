@import '../../../styles/colors.css';
@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';

:root {
  --navSidePaneWidth: 60px;
  --spinnerPadding: 30px;
  --listSidePaneWidth: calc(var(--sidePaneWidth) - var(--navSidePaneWidth));
  --widgetHeight: calc(100% - var(--dashboardWidgetTitleHeight));
  --listHeight: 100%;
  --divider: 1px solid var(--typhoon);
  --listIndent: 1.25rem;
}

.info-container {
  border-top: var(--divider);
  height: 100%;
  background-color: var(--white);
}

.info-detail-section {
  padding: 1rem;
  display: flex;
  height: 100%;
  flex-direction: column;
  border-top: var(--divider);
}

.spinner-container {
  height: var(--listHeight);
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: var(--spinnerPadding);
}

.tray-container {
  height: 100%;
  background-color: var(--white);
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
}

.header-container {
  border-top: var(--divider);
  padding: 1rem 0 0.5rem 0;
  vertical-align: middle;
  flex: 0 0 auto;
  position: sticky;
  display: flex;
  flex-flow: column nowrap;
  top: 0;
  z-index: 100;
  background-color: var(--white);
  box-shadow: 4px 0 10px -6px rgba(0, 0, 0, 0.2);
}

.exit-container {
  display: flex;
  justify-content: flex-end;
  flex-basis: 100%;
  width: 100%;
}

.profile-container {
  display: inline-flex;
  flex-flow: row wrap;
}

.header-profile-image {
  height: 5rem;
  padding-left: 1.25rem;
}

.profile-image {
  width: 3.75rem;
  height: 3.75rem;
  cursor: pointer;
  border-radius: 50%;
}

.header-title-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.25rem;
  padding-left: 1.25rem;
}

.header-name {
  color: var(--dark);
  font-size: var(--fs-lg);
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.header-subtext {
  color: var(--gray);
  line-height: var(--lh-xl);
}

.tray-content-container {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 auto;
}

.summary-image-container {
  width: var(--sidePaneWidth);
}

.container {
  width: var(--sidePaneWidth);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
}

.work-container {
  border-top: var(--divider);
  box-shadow: inset 1px 4px 9px -7px;
  height: 100%;
  background-color: var(--white);
}

.work-detail-section {
  border-top: var(--divider);
  display: flex;
  flex-direction: column;
}
