@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

.create-controls-container {
  display: flex;
}

.menu-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  font-size: var(--fs-md);
  box-sizing: border-box;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    background-color: var(--fog);
  }
}

.menu-header {
  text-transform: uppercase;
  color: var(--gray);
  font-size: var(--fs-sm);
  font-weight: 500;
  padding: 0.5rem 1rem;
}

.content-container {
  height: 25rem;
  overflow: auto;
}

.search-content {
  box-sizing: border-box;
  padding-bottom: 1.25rem;
}

.search-box {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.empty-task-template-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
}

.empty-title {
  color: var(--dim);
  font-size: var(--fs-md);
  padding: 1rem 0;
}

.empty-subtext {
  color: var(--gray);
  font-size: var(--fs-sm);
}

.template-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 5px 1rem;

  &:hover {
    cursor: pointer;
    background-color: var(--blueHighlight);
  }
}

.template-row-selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 5px 1rem;
  background-color: var(--blueHighlight);

  &:hover {
    cursor: pointer;
  }
}

.template-label-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.template-title {
  color: var(--dark);
  font-weight: 500;
  padding-left: 1rem;

  &.selected {
    color: var(--blue);
  }

  &.disabled {
    color: var(--silver);
  }
}

.template-subtext {
  color: var(--gray);
  font-size: var(--fs-sm);
  padding-left: 1rem;

  &.disabled {
    color: var(--silver);
  }
}

.button-spacer {
  width: 3rem;
  height: 3rem;
}
