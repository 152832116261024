@import '../../../../../styles/fonts.css';
@import '../../../../../styles/colors.css';
@import '../../../../../styles/layout.css';

:root {
  --iconDimension: 24px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-left {
  display: flex;
  align-items: center;
}

.section-right {
  display: flex;
  align-items: center;
}

.section-title {
  font-size: var(--fs-md);
  font-weight: 500;
}

.total-count {
  margin-left: 1ch;
}

.empty-section-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  color: var(--gray);
}

.tile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  height: 3rem;
  margin-bottom: 0.5rem;
}

.tile-content-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 16.5rem;
}

.tile-icon-container {
  width: var(--iconDimension);
  height: var(--iconDimension);
}

.tile-content {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  white-space: nowrap;
  overflow: hidden;
}

.tile-title {
  font-size: var(--fs-md);
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tile-subtext {
  font-size: var(--fs-sm);
  color: var(--gray);
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
}

.category-section {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 0.5rem;
}

.lock-container {
  margin-left: 1.125rem;
  z-index: 100;
}

.category-lock-container {
  margin-top: 3px;
  margin-right: 0.5rem;
}
