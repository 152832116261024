@import '../../../../../styles/colors.css';
@import '../../../../../styles/layout.css';
@import '../../../../../styles/fonts.css';

.modal-header {
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  height: 2rem;
  font-size: var(--fs-xxl);
  color: var(--black);
}

.modal-content {
  display: flex;
  flex-flow: row nowrap;
}

.map-section {
  flex: 1 1 auto;
  height: 44.75rem;
}

.map-container {
  flex: 1 1 auto;
  height: 41.75rem;
}

.tree-container {
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  height: 41.75rem;
  overflow: auto;
}

.tab-header {
  display: flex;
  box-sizing: border-box;
  height: 3rem;
  border-bottom: 1px solid var(--ash);
  padding-left: 0.5rem;
}

.asset-tab {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 47px;
  border-bottom: 2px solid var(--white);
  margin: 0 0.5rem;
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }

  &.active {
    color: var(--blue);
    border-bottom: 2px solid var(--blue);
  }
}

.sidebar-container {
  width: 21.25rem;
  height: 44.75rem;
  border-right: 1px solid var(--ash);
}

.section-header {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1.5rem;
  border-bottom: 1px solid var(--ash);
  height: 3rem;
}

.title-container {
  display: flex;
  align-items: center;
}

.title {
  font-weight: 500;
  padding-right: 1rem;
}

.header-action-buttons {
  display: flex;
  align-items: center;
}

.asset-list {
  box-sizing: border-box;
  overflow: auto;
  height: calc(100% - 48px);
  padding-top: 0.5rem;
}

.error-container {
  display: flex;
  flex-flow: row nowrap;
  border-radius: 4px;
  background: var(--errorBackground);
  margin: 0.5rem 1.5rem;
  padding: 0.5rem 0.75rem;
}

.content-container {
  padding-left: 0.75rem;
}

.additional-info {
  padding-left: 0.5rem;
}

.empty-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.empty-content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
  padding: 0 1rem;
}

.empty-text {
  color: var(--dim);
  padding-top: 1rem;
}
