@import '../../styles/colors.css';
@import '../../styles/fonts.css';
@import '../../styles/layout.css';

:root {
  --toolbarHeight: 2.5rem;
  --headerOffset: 6.875rem;
  --formContentHeight: calc(100vh - var(--headerOffset));
  --sidebarWidth: 15rem;
  --fhwaFormHeaderHeight: 3.125rem;
  --fhwaFormRowHeight: 3.125rem;
  --fhwaTableHeaderHeight: 2.5rem;
  --fhwaFormMediaModalWidth: 53.75rem;
  --fhwaHeaderOffset: 13rem;
  --fhwaContentHeight: calc(100vh - var(--fhwaHeaderOffset));
}

.spinner-container {
  position: relative;
  height: 50vh;
}

.form-instance-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding-top: var(--headerOffset);
  height: var(--formContentHeight);
}

/* 10% padding on left and right allows for a preview area
with dynamic width including spacing */

.form-preview-section {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  padding: 1.875rem 10%;
  width: 100%;
  overflow: auto;

  &.module {
    padding: 0;
    overflow: hidden;
  }
}

.form-pages-container {
  width: var(--sidebarWidth);
  border-right: 1px solid var(--ash);
}

.field-block {
  width: 100%;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
}

.asset-container {
  width: 100%;
}

.title-field-text {
  font-size: var(--fs-xl);
  font-weight: 500;
  color: var(--black);
}

.description-field-text {
  font-size: var(--fs-md);
}

.field-description {
  color: var(--gray);
}

.short-description-container {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.long-description-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.short-textfield-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  margin-top: -0.5rem;
}

.required:after {
  content: '*';
  color: var(--red);
  padding-top: 1.5rem;
  padding-left: 0.5rem;
}

.long-textfield-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: -0.5rem;
}

.field-unit-text {
  padding-top: 1.5rem;
  padding-left: 0.5rem;
}

.field-currency-text {
  padding-top: 1.5rem;
  padding-right: 0.5rem;
}

.radio-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
}

.file-tile-container {
  padding-top: 0.5rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

.media-tile {
  border: 1px solid var(--ash);
  padding: 0.5rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.media-name {
  color: var(--dark);
  font-size: var(--fs-md);
  line-height: var(--lh-sm);
  font-weight: 500;
  padding-left: 0.625rem;
}

.asset-field-block {
  padding: 0.625rem 1rem;
  margin-bottom: 0.5rem;
  border: 1px solid var(--ash);
  border-radius: 0.3125rem;
}

.asset-field-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.left-asset-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.right-asset-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.asset-title-block {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 1rem;
}

.asset-name {
  font-size: var(--fs-md);
  color: var(--dark);

  &.error {
    color: var(--red);
  }
}

.asset-type {
  font-size: var(--fs-sm);
  color: var(--gray);
}

.attribute-group-block {
  margin-top: 1.5rem;
}

.attribute-group-name {
  font-size: var(--fs-md);
  color: var(--dark);
  padding-bottom: 0.5rem;
}

.attribute-field-block {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5rem;
}

.attribute-name {
  font-size: var(--fs-md);
  color: var(--gray);
  width: 16.875rem;

  &.error {
    color: var(--red);
  }
}

.attribute-input {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 18.75rem;
}

.currency:before {
  content: '$';
  color: var(--dark);
  padding-right: 0.5rem;
}

.unit-text {
  padding-left: 0.5rem;
}

.asset-content-block {
  padding-left: 2.75rem;
}

.files-section-title {
  font-size: var(--fs-md);
  color: var(--dark);
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.fhwa-container {
  width: 100%;
}

.fhwa-rows-container {
  height: var(--fhwaContentHeight);
  overflow: auto;
}

.fhwa-header {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.25rem;
  min-height: var(--fhwaFormHeaderHeight);
}

.fhwa-icons {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.fhwa-right-icons {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.fhwa-title {
  font-size: var(--fs-xl);
  color: var(--dark);
  font-weight: 500;
  padding-left: 0.625rem;
}

.fhwa-table-header {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0;
  min-height: var(--fhwaTableHeaderHeight);
  background-color: var(--fog);
  color: var(--gray);
  font-size: var(--fs-sm);
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 1px solid var(--silver);
}

.element-column {
  box-sizing: border-box;
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  min-width: 16.25rem;

  &.header {
    padding-left: 1.875rem;
  }
}

.normal-column {
  min-width: 5rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}

.large-column {
  min-width: 6.75rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;

  &.environment {
    padding-right: 0.5rem;
  }
}

.cs1-header-column {
  box-sizing: border-box;
  min-width: 4rem;
  padding-right: 1rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}

.cs1-column {
  box-sizing: border-box;
  min-width: 4rem;
  padding-right: 1rem;
  display: flex;
  justify-content: flex-end;
  flex-flow: row nowrap;
  align-items: center;
  color: var(--dark);
  font-size: var(--fs-md);
}

.attach-column {
  min-width: 3.5rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-left: 0.5rem;

  &.additional {
    padding-left: 0;
    min-width: 1.5rem;
  }

  &.right {
    padding-left: 0;
    min-width: 2.5rem;
  }
}

.fhwa-row-block {
  display: flex;
  flex-flow: column nowrap;
  border-bottom: 1px solid var(--silver);
}

.fhwa-table-row {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0;
  min-height: var(--fhwaFormRowHeight);
}

.fhwa-left-icon-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.column-suffix-text {
  padding-left: 5px;
  text-transform: uppercase;
  color: var(--gray);
}

.percentage-icon {
  width: '24px';
  height: '24px';

  &:hover {
    cursor: pointer;
  }
}

.element-name {
  color: var(--dark);
  font-size: var(--fs-md);

  &.red {
    color: var(--red);
  }
}

.modal-container {
  padding: 0 2.5rem;
}

.fhwa-photo-modal-content {
  flex: 1 1 auto;
  border-top: 1px solid var(--ash);
  border-bottom: 1px solid var(--ash);
  padding: 1.25rem 0 1.25rem 1.25rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  overflow-y: auto;
  max-height: var(--formModuleMediaModalHeight);
}

.fhwa-media-tile-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  width: 11.25rem;
  height: 11.875rem;
  margin-right: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid var(--ash);
  border-radius: 6px;
}

.fhwa-media-image-container {
  width: 100%;
  height: 8.75rem;
  background-color: var(--fog);
}

.fhwa-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.fhwa-file-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
}

.fhwa-media-tile-info {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
}

.page-name-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 11rem;
}

.page-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 11rem;

  &.error {
    width: 9.5rem;
  }
}

.iframe-preview {
  display: flex;
  justify-content: center;
}

.iframe-title {
  color: var(--dark);
  font-size: var(--fs-lg);
  font-weight: 500;
  margin-bottom: 1rem;
}

.media-tile-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  width: 11.25rem;
  height: 11.875rem;
  margin-right: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid var(--ash);
  border-radius: 6px;
}

.media-image-container {
  width: 100%;
  height: 8.75rem;
  background-color: var(--fog);
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.file-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
}

.media-tile-info {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
}
