@import '../../../../../../styles/layout.css';
@import '../../../../../../styles/fonts.css';
@import '../../../../../../styles/colors.css';

.modal-content-container {
  max-height: 31.25rem;
  padding: 1rem 1.5rem;
  overflow: auto;
}

.element-name-text {
  font-weight: 500;
}

.tree-container {
  margin-top: 1rem;
  padding: 1rem 0;
  background-color: var(--typhoon);
}

.group-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 0.5rem 0 1rem;
  height: 3.125rem;
  min-height: 3.125rem;

  &.selected {
    background-color: var(--fog);
  }
}

.element-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 3.125rem;
  min-height: 3.125rem;
  padding-right: 2rem;

  &:hover {
    cursor: pointer;
    background-color: var(--typhoon);
  }

  &.selected {
    background-color: var(--fog);
  }
}

.row-name-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.row-name {
  font-weight: 500;
  padding-left: 1rem;

  &.group {
    padding-left: 0;
    text-transform: uppercase;
    color: var(--gray);
  }
}

.textfield-container {
  width: 4rem;
}

.spinner-container {
  height: 4rem;
}
