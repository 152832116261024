@import '../../../styles/detail-header.css';
@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.header-container {
  position: fixed;
  margin-top: var(--topNavHeight);
  z-index: 500;
  width: 100vw;
  background-color: var(--blue);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  height: var(--detailSubNavHeight);
  color: var(--white);
}

.title-bar {
  box-sizing: content-box;
  display: inline-block;
  font-size: var(--fs-lg);
  margin: 0.875rem 0 1rem 0;
  font-weight: 500;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 1rem 1rem;
}

.icon-bar {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0.5rem;
  top: 0.25rem;
}

.additional-options {
  display: inline-block;
  width: 2rem;
}

.info-boxes-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  height: 2.5rem;
}

.apply-history-modal-content {
  color: var(--dark);
  line-height: var(--lh-xl);

  & .strong {
    font-weight: 500;

    &:before {
      content: ' ';
    }

    &:after {
      content: ' ';
    }
  }
}

.modal-subtext {
  margin-top: 1rem;
}

.icon-row {
  margin-top: 0.3rem;
  margin-left: 3rem;
}

.icon-container {
  cursor: pointer;
  text-align: center;
  display: inline-block;
  width: 5rem;
  opacity: 0.4;
  padding-bottom: 0.6rem;
  margin-right: 1.25rem;

  &.active {
    opacity: 1;
    border-bottom: 3px solid var(--white);
  }

  & .label {
    font-size: var(--fs-sm);
    text-transform: uppercase;
    margin: -0.5rem 0;
  }
}

.status-pill-container {
  cursor: pointer;
}

.report-text {
  color: var(--dark);
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.export-options-text {
  color: var(--dark);
  margin-top: 1.5rem;
}

.report-name-control {
  position: relative;
  margin-bottom: 1.75rem;
  margin-right: 1rem;

  &:after {
    content: '*';
    color: var(--red);
    position: absolute;
    right: -1em;
    top: 50%;
  }
}

.modal-content-container {
  padding: 0 1.5rem 3rem;
}

.radio-label-container {
  display: flex;
}

.icon-modal-container {
  display: inline-block;
}

.modal-container {
  padding: 0 2.5rem 2.5rem 2.5rem;
}

.modal-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.required-modal {
  position: relative;
  width: 100%;

  &:after {
    content: '*';
    color: var(--red);
    position: absolute;
    right: -1em;
    top: 50%;
  }
}

.toggle-container {
  margin-top: 0.5rem;
}

.modal-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.status-menu-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
  padding-left: 2rem;
  font-weight: 500;
  width: 14rem;
  box-sizing: border-box;

  &.disabled {
    color: var(--gray);
    cursor: default;
  }

  &:hover {
    background: var(--ash);
  }
}

.header-info-section {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  font-size: var(--fs-md);
  padding-left: 3rem;
  padding-top: 2px;
  white-space: nowrap;
}

.info-section-block {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-right: 2.5em;
}

.info-label-container {
  margin-right: 1rem;
}

.inventory-name:hover {
  cursor: pointer;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
}
