@import '../../../styles/colors.css';
@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';

:root {
  --navSidePaneWidth: 60px;
  --spinnerPadding: 30px;
  --listSidePaneWidth: calc(var(--sidePaneWidth) - var(--navSidePaneWidth));
  --widgetHeight: calc(100% - var(--dashboardWidgetTitleHeight));
  --listHeight: 100%;
  --divider: 1px solid var(--typhoon);
  --listIndent: 1.25rem;
}

.spinner-container {
  height: var(--listHeight);
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: var(--spinnerPadding);
}

.container {
  width: var(--sidePaneWidth);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
}

.tray-container {
  height: 100%;
  background-color: var(--white);
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
}

.tray-content-container {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 auto;
}

.header-container {
  border-top: var(--divider);
  vertical-align: middle;
  position: sticky;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  top: 0;
  z-index: 100;
  color: var(--dark);
  font-size: var(--fs-lg);
  font-weight: 500;
  background-color: var(--white);
  box-shadow: 4px 0 10px -6px rgba(0, 0, 0, 0.2);
}

.header-title-container {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.25rem;
}

.header-title {
  width: 70%;
  align-self: center;
  padding-left: 1.25rem;
}

.status-container {
  width: 100%;
  padding-left: 1.25rem;
}

.summary-image-container {
  width: var(--sidePaneWidth);
  height: 160px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.section-heading-container {
  color: var(--dark);
  padding-left: var(--listIndent);
  height: 3.125rem;
  line-height: 3.125rem;
}
