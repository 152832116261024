@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

:root {
  --headerHeight: 50px;
  --headerOffset: calc(var(--detailFixedHeaderOffset) + var(--headerHeight));
  --detailContentHeight: calc(100vh - var(--headerOffset));
}

.work-order-asset-container {
  width: 100%;
}

.header-container {
  padding-right: 1rem;
  height: var(--headerHeight);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--ash);
}

.changes-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.back-button-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.work-order-content {
  display: flex;
  flex-flow: row nowrap;
  height: var(--detailContentHeight);
  overflow: hidden;
}

.pane {
  box-sizing: border-box;
  height: 100%;
  width: 50%;

  &.left {
    border-right: 1px solid var(--ash);
    overflow: auto;
  }

  &.right {
    overflow: hidden;
  }
}
