@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.container {
  padding: 0 2.5rem;
  color: var(--dark);
  height: auto;
  margin: 0 auto;
  width: 52rem;
}

.task-container {
  page-break-after: always;
}

.task-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

.task-title {
  font-size: var(--fs-sm);
  font-weight: 500;
  color: var(--blue);
}

.task-title-subtext {
  font-size: var(--fs-sm);
  line-height: var(--lh-xs);
}

.actual-cost-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  font-size: var(--fs-xs);
}

.actual-cost-value {
  font-size: var(--fs-xs);
  font-weight: 700;
  padding-left: 5px;
}

.section-container {
  width: 100%;
  margin-bottom: 5px;
  border: 1px solid var(--typhoon);
}

.section-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1.5rem;
  background: var(--lightblueHighlight);
  border-bottom: 1px solid var(--typhoon);
  padding: 0 0.5rem;
  font-size: var(--fs-xs);
  font-weight: 700;
}

.team-lead {
  color: var(--blue);
  padding-right: 5px;
}

.material-table-header {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.5rem;
  padding-left: 0.5rem;
  font-size: var(--fs-xs);
  font-weight: 700;
  border-bottom: 1px solid var(--typhoon);
  border-top: 1px solid var(--typhoon);
  background-color: var(--mist);
  page-break-inside: avoid;
}

.material-table-sub {
  display: flex;
  align-items: center;
}

.material-header-type {
  padding-right: 0.5rem;
  text-align: left;
  width: 4rem;
}

.material-header-name {
  flex: 1 1 auto;
  border-left: 1px solid var(--typhoon);
  padding: 0 0.5rem;
  text-align: left;
}

.material-header-cell {
  border-left: 1px solid var(--typhoon);
  padding: 0 0.5rem;
  text-align: right;
  width: 4rem;

  &.used {
    width: 3rem;
  }
}

.unit {
  text-transform: uppercase;
}

.custom-field-section {
  width: 100%;
  border: 1px solid var(--typhoon);
  margin-bottom: 5px;
}

.custom-field-row {
  display: flex;
  flex-flow: row wrap;
  border-bottom: 1px solid var(--typhoon);
  page-break-inside: avoid;

  &:last-child {
    border-bottom: none;
  }
}

.custom-field-tile {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  width: 33.33%;
  line-height: var(--lh-xs);
  padding: 0.3125rem 0.5rem;
  font-size: 11px;
  font-weight: 400;
  border-right: 1px solid var(--typhoon);

  &:nth-child(3) {
    border: none;
  }
}

.empty-value {
  visibility: hidden;
}

.users-table-header {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.5rem;
  padding-left: 0.5rem;
  font-size: var(--fs-xs);
  font-weight: 700;
  border-top: 1px solid var(--typhoon);
  background-color: var(--mist);
  page-break-inside: avoid;
}

.users-table-sub-header {
  display: flex;
  align-items: center;
}

.users-header-cell {
  border-left: 1px solid var(--typhoon);
  padding: 0 0.5rem;
  text-align: left;
  min-width: 4rem;

  &.name {
    border: none;
    width: 7.5rem;
    padding: 0;
  }

  &.cost {
    text-align: right;
    width: 4rem;
  }
}

.users-table-row {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.5rem;
  font-size: 11px;
  border-top: 1px solid var(--typhoon);
  page-break-inside: avoid;
}

.users-table-sub-row {
  display: flex;
  align-items: center;
}

.users-row-cell {
  border-left: 1px solid var(--typhoon);
  padding: 0 0.5rem;
  text-align: left;
  min-width: 4rem;
  min-height: 1.5rem;

  &.name {
    border: none;
    width: 7.5rem;
    padding: 0;
  }

  &.cost {
    text-align: right;
    width: 4rem;
  }
}

.basic-container {
  box-sizing: border-box;
  font-size: 11px;
  font-weight: 400;
  padding: 0.5rem;
  min-height: 1.75rem;
}

.unified-data-row {
  display: flex;
  align-items: center;
}

.data-cell {
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 400;
  text-align: left;
  min-height: 1.5rem;
  padding-left: 0.5rem;
  width: 33.33%;
  border-right: 1px solid var(--typhoon);

  &:last-child {
    border-right: none;
  }
}

.location-table-header-container {
  display: flex;
  align-items: center;
}

.location-table-header {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.5rem;
  padding-left: 0.5rem;
  font-size: var(--fs-xs);
  font-weight: 700;
  border-bottom: 1px solid var(--typhoon);
  border-top: 1px solid var(--typhoon);
  border-right: 2px solid var(--typhoon);
  background-color: var(--mist);
  page-break-inside: avoid;
  width: 100%;

  &:last-child {
    border-left: 2px solid var(--typhoon);
  }
}

.location-table-sub {
  display: flex;
  align-items: center;
}

.location-header-route {
  flex: 1 1 auto;
}

.location-header-cell {
  border-left: 1px solid var(--typhoon);
  padding: 0 0.5rem;
  width: 4rem;
}

.location-row-container {
  display: flex;
  width: 100%;
}

.location-row {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  height: 1.5rem;
  padding-left: 0.5rem;
  font-size: 11px;
  border-bottom: 1px solid var(--typhoon);
  border-right: 2px solid var(--typhoon);
  page-break-inside: avoid;
  width: 50%;

  &:last-child {
    border-right: none;
    border-left: 2px solid var(--typhoon);
  }
}

.location-row-sub {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.5rem;
  padding-left: 0;
  font-size: 11px;
  border-bottom: 1px solid var(--typhoon);
  border-right: 1px solid var(--typhoon);
  page-break-inside: avoid;
}

.location-cell {
  border-left: 1px solid var(--typhoon);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.5rem;
  width: 4rem;
  height: 1.5rem;
}

.asset-table-header {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.5rem;
  padding-left: 0.5rem;
  font-size: var(--fs-xs);
  font-weight: 700;
  border-bottom: 1px solid var(--typhoon);
  border-top: 1px solid var(--typhoon);
  background-color: var(--mist);
  page-break-inside: avoid;
  width: 100%;
}

.asset-table-sub {
  display: flex;
  align-items: center;
}

.asset-header-name {
  flex: 1 1 auto;
}

.asset-header-cell {
  border-left: 1px solid var(--typhoon);
  padding: 0 0.5rem;
  width: 4rem;

  &.asset-route {
    width: 9.75rem;
  }
}

.start-end-row {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  border-bottom: 1px solid var(--typhoon);
  page-break-inside: avoid;
  min-height: 1.5rem;

  &:last-child {
    border-bottom: none;
  }
}

.start-end-type {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  width: 4rem;
  padding: 2px 0.5rem;
}

.start-end-name {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  border-left: 1px solid var(--typhoon);
  padding: 2px 0.5rem;
}

.start-end-sub-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.start-end-row-sub {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--typhoon);

  &:first-child {
    height: 100%;
    border-top: none;
  }
}

.start-end-value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 4rem;
  border-left: 1px solid var(--typhoon);
  padding: 2px 0.5rem;

  &.used {
    width: 3rem;
  }
}

.material-row {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  border-bottom: 1px solid var(--typhoon);
  page-break-inside: avoid;
  min-height: 1.5rem;

  &:last-child {
    border-bottom: none;
  }
}

.material-type {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  width: 4rem;
  padding: 2px 0.5rem;
}

.material-name {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  border-left: 1px solid var(--typhoon);
  padding: 2px 0.5rem;
}

.material-row-sub {
  display: flex;
  justify-content: space-between;

  &:first-child {
    height: 100%;
  }
}

.material-value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 4rem;
  border-left: 1px solid var(--typhoon);
  padding: 2px 0.5rem;

  &.used {
    width: 3rem;
  }
}

.asset-row {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  border-bottom: 1px solid var(--typhoon);
  page-break-inside: avoid;
  min-height: 1.5rem;

  &:last-child {
    border-bottom: none;
  }
}

.asset-type {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  width: 4rem;
  padding: 2px 0.5rem;
}

.asset-name {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  border-left: 1px solid var(--typhoon);
  padding: 2px 0.5rem;
}

.asset-row-sub {
  display: flex;
  justify-content: space-between;

  &:first-child {
    height: 100%;
  }
}

.asset-value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 4rem;
  border-left: 1px solid var(--typhoon);
  padding: 2px 0.5rem;

  &.route {
    width: 9.75rem;
  }
}
