@import '../../../../styles/layout.css';
@import '../../../../styles/colors.css';
@import '../../../../styles/fonts.css';

.header-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 41rem;
}

.title {
  font-size: var(--fs-xl);
  color: var(--dark);
  font-weight: 500;
}

.settings-tooltip {
  font-size: var(--fs-sm);
  font-weight: 400;
  padding: 0.625rem;
  padding-bottom: 0;
}

.settings-tooltip-row {
  display: flex;
  flex-flow: row;
  padding-bottom: 0.625rem;
}

.settings-tooltip-content {
  padding-left: 0.625rem;
}

.settings-content-row {
  margin-bottom: 1rem;
  font-size: var(--fs-md);
  color: var(--dark);
}

.selection-title {
  color: var(--dark);
  font-size: var(--fs-sm);
  padding-bottom: 1rem;
}

.settings-buttons-container {
  display: flex;
  flex-flow: row nowrap;
  padding-bottom: 1rem;
}

.settings-button {
  font-size: var(--fs-sm);
  color: var(--gray);
  padding-left: 0.625rem;

  &.enabled {
    color: var(--blue);
  }

  &.left {
    border-right: 1px solid var(--silver);
    padding-right: 0.625rem;
    padding-left: 0;
  }

  &:hover {
    cursor: pointer;
  }
}

.section-title {
  font-weight: 500;
  color: var(--dark);
  font-size: var(--fs-md);
  padding-bottom: 0.5rem;
}

.selection-container {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 0.5rem;
}
