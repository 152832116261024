@import '../../../../styles/layout.css';
@import '../../../../styles/colors.css';
@import '../../../../styles/fonts.css';

.detail-header-container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.125rem;
  padding: 0 1rem;
  font-weight: 500;
  white-space: nowrap;
}

.detail-header-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.attribute-group-row {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.125rem;
  border-bottom: 1px solid var(--ash);
  border-top: 1px solid var(--ash);
  padding: 0 1rem;
  white-space: nowrap;
}

.attribute-group-row-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-container {
  display: flex;
  align-items: center;
}

.expand-default-text {
  font-size: 0.75rem;
  padding-right: 1rem;
  color: var(--gray);
}

.attribute-group-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
