@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

.location-tab-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  flex: 1 1 auto;
  padding: 1rem;
}

.tab-title {
  font-weight: 500;
  line-height: 1.25rem;
  margin-right: 1rem;
}

.empty-line-state {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  margin: 0.5rem 0;
  background-color: var(--lightblueHighlight);
  border-radius: 4px;
  height: 40px;
}

.line-location-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0;
  height: 40px;
}

.subtext {
  font-size: var(--fs-sm);
  color: var(--gray);
}

.map-container {
  height: 33rem;
  width: 38rem;
}
