@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

.location-tab-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  flex: 1 1 auto;
  padding: 1rem;
}

.tab-title {
  font-weight: 500;
  line-height: 1.25rem;
  margin-right: 1rem;
}

.title-subtext {
  color: var(--gray);
  margin-bottom: 0.5rem;
}

.map-container {
  height: 17.25rem;
  width: 38rem;
}

.inputs-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.input-row {
  flex: 1 1 auto;
  margin-top: 0.5rem;
}

.input-subtext {
  font-size: var(--fs-sm);
  color: var(--gray);
  margin-top: 0.5rem;

  &.error {
    color: var(--red);
  }
}

.input-container {
  display: inline-flex;
  align-items: center;
  border-bottom: var(--gray) 1px solid;
  height: 2rem;
  width: 100%;

  &.error {
    border-bottom: var(--red) 1px solid;
  }
}
