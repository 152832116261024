@import '../../../../styles/layout.css';
@import '../../../../styles/colors.css';
@import '../../../../styles/fonts.css';

.attribute-header-container {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 3.125rem;
}

.attribute-header-text {
  padding-right: 1rem;
  font-weight: 500;
}

.attribute-section {
  display: flex;
  flex-flow: column;
  padding: 0.5rem 1rem;
}
