@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

.header-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.header-title {
  font-size: var(--fs-lg);
  line-height: var(--fs-xxl);
  font-weight: 500;
}

.header-path {
  font-size: var(--fs-md);
  color: var(--gray);
  line-height: var(--fs-xxl);
  text-transform: capitalize;
}

.title-container {
  padding-left: 1rem;
}

.modal-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  margin: 1.25rem 0;
}

.modal-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.work-time-label {
  padding-left: 1.5rem;
  color: var(--black);
  font-size: var(--fs-md);
}

.snapshot-suffix {
  padding-left: 1ch;
  color: var(--gray);
}
