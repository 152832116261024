@import '../../styles/layout.css';
@import '../../styles/colors.css';
@import '../../styles/fonts.css';

:root {
  --interiorContainerHeight: calc(100vh - var(--fixedHeaderOffset));
}

.portal-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: var(--topNavHeight);
  overflow: hidden;
}

.map-container {
  width: 100%;
  height: var(--interiorContainerHeight);
}

.content-container {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;
}

.right-pane {
  height: 100%;
  overflow: hidden;
  width: calc(100% - var(--sidePaneWidth));
  background-color: var(--white);
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1.25rem;
  height: var(--subNavHeight);
  background-color: var(--red);
}

.name-container {
  color: var(--white);
  font-size: var(--fs-lg);
  font-weight: 500;
}
