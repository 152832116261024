@import '../../styles/layout.css';
@import '../../styles/fonts.css';
@import '../../styles/colors.css';

.search-box {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--typhoon);
  height: 35rem;
  overflow-y: scroll;
  margin-top: 1rem;
}

.search-box-spacing {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0.5rem 0.5rem 0 0.5rem;
}

.progress {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.search-input {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 1px solid var(--silver);
}

.select-all-button-container {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  padding-top: 0.625rem;
}

.select-all-button {
  cursor: pointer;
  font-size: 0.75rem;
}

.custom-fields-description {
  font-style: 'italic';
  color: var(--silver);
}

.field-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 1.875rem;
}
