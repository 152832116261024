@import '../../../../styles/fonts';
@import '../../../../styles/colors';
@import '../../../../styles/layout';

.body-container {
  width: 100%;
}

.left-body-pane-half {
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
  overflow: auto;
  background-color: var(--white);
  width: 50%;
  position: relative;
  overflow-x: hidden;
  border-right: 1px solid var(--typhoon);
}

.right-body-pane-half {
  display: inline-block;
  height: 100%;
  overflow: auto;
  width: 50%;
}

.element-detail-spinner-container {
  position: relative;
  height: 50vh;
}

.icon-row {
  padding-left: 0.25rem;
  padding-top: 0.5rem;
  box-shadow: inset var(--containerShadow), var(--containerShadow);
  background-color: var(--white);
  position: sticky;
  top: 0;
  z-index: 100;
}

.element-name {
  margin-top: 0.5rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  color: var(--dark);
  font-size: var(--fs-lg);
  font-weight: 500;
}

.tree-header {
  color: var(--dark);
  padding: 0 1.25rem;
  user-select: none;
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: inset var(--containerShadow), var(--containerShadow);
}

.list-container {
  padding: 1em 1.25rem 1em 3.75em;
}
