@import '../../../../styles/layout.css';
@import '../../../../styles/colors.css';
@import '../../../../styles/fonts.css';

.attribute-header-container {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 3.125rem;
}

.attribute-header-text {
  padding-right: 1rem;
  font-weight: 500;
}

.attribute-section {
  display: flex;
  flex-flow: column;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--ash);
}

.attribute-section-controls {
  display: flex;
  flex-flow: column;
  padding: 1rem;
  border-bottom: 1px solid var(--ash);
}

.section-header {
  font-weight: 500;
  padding-bottom: 0.625rem;
}

.checkbox-row {
  display: flex;
  align-items: center;
}

.label-container {
  height: 1.25rem;
}

.label-subtext {
  color: var(--gray);
}

.data-type-option-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.data-type-option-title {
  font-size: var(--fs-md);
  padding-left: 0.5rem;
}

.type-settings-container {
  padding-bottom: 0.5rem;
}

.options-input-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 2.5rem;
}

.options-number {
  padding-right: 1rem;
  white-space: nowrap;
}

.data-type-builder-container {
  padding: 0.5rem 0;
}

.default-value-title {
  font-size: var(--fs-sm);
  color: var(--gray);
}

.default-value-container {
  padding-bottom: 1rem;
}
