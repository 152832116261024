@import '../../../../styles/colors.css';

:root {
  --listHeight: calc(100% - 19px);
}

.container {
  display: inline-block;
  height: var(--listHeight);
  overflow: auto;
  width: 3.75rem;
  background-color: var(--white);
  color: var(--gray);
  z-index: 300;
  position: relative;
  box-shadow: inset -1px 1px 0 0 var(--ash);
}

.nav-link-container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
}

.nav-link {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 3.75rem;

  &.active {
    background-color: var(--highlight);
    border-left: 3px solid var(--blue);
    padding-right: 3px;
  }
}
