@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

:root {
  --interiorContainerHeight: calc(100vh - var(--detailFixedHeaderOffset));
  --sectionHeaderHeight: 50px;
}

.info-container {
  display: flex;
  justify-content: row nowrap;
  overflow: hidden;
  background-color: var(--typhoon);
}

.pane {
  height: var(--interiorContainerHeight);
  background-color: var(--white);
  width: 50%;
  overflow: hidden;

  &.left {
    overflow: auto;
  }
}

.no-location-pane {
  height: var(--interiorContainerHeight);
  background-color: var(--white);
  width: 70%;
  margin-left: 15%;
  overflow: auto;
  box-shadow: var(--muiDropdownBoxShadow);
}

.section-content {
  padding: 0 1.25rem 0 3.75rem;

  &.summary {
    padding: 1rem 0.75rem 1rem 2.8125rem;
    border-bottom: 1px solid var(--ash);
  }
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.75rem 0 1.25rem;
  height: var(--sectionHeaderHeight);
  border-bottom: 1px solid var(--ash);
}

.drag-icon-spacer {
  height: 24px;
  width: 24px;
  margin-right: 5px;
}

.no-summary {
  color: var(--gray);
  font-size: var(--fs-md);
}

.summary-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--sectionHeaderHeight);
  font-weight: 500;
}

.summary-item-content {
  line-height: 24px;
  padding-bottom: 1.25rem;
  white-space: pre-line;
  overflow-wrap: break-word;
}

.detail-content {
  padding: 1.5rem 3.5rem 0 3.5rem;
}

.info-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  height: auto;
  font-size: var(--fs-md);
  padding-bottom: 0.5rem;
}

.row-label {
  width: 13.75rem;
  color: var(--gray);
}

.info-value {
  width: 50%;
}

.local-display-date {
  font-size: var(--fs-md);
  opacity: 0.5;
}

.field-container {
  max-width: 33.75rem;
}

.field-row-content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  margin: 1rem 0;

  &.short-text {
    max-width: calc(65% + 2.5rem);
  }

  &.date {
    max-width: 25%;
  }

  &.datetime {
    max-width: 40%;
  }

  &.enum-single {
    flex-flow: row wrap;
    margin: 0 -0.5rem;
  }

  &.enum-multiple {
    flex-flow: row wrap;
    margin: 0 -0.5rem;
  }

  &.number {
    max-width: calc(65% + 2.5rem);
  }
}

.summary-value {
  font-size: var(--fs-md);
  padding-top: 0.5rem;
}

.title {
  font-size: var(--fs-lg);
  white-space: nowrap;
  padding-right: 0.5rem;
}

.data-type-option-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.data-type-option-title {
  font-size: var(--fs-md);
  padding-left: 1rem;
}

.field-modal-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 20rem;
}

.option-input-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1rem;
}

.option-number {
  padding-right: 1rem;
  white-space: nowrap;
  color: var(--black);
  font-size: var(--fs-md);
}

.label {
  color: var(--gray);
}

.helper-text {
  text-align: right;
}
