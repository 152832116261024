@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

.status-indicator {
  height: 1rem;
  width: 1rem;
  margin-right: 1rem;
  border-radius: 50%;
}

.option-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.option-title {
  display: flex;
  flex-flow: row nowrap;
}
