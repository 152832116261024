@import '../../styles/layout.css';
@import '../../styles/colors.css';
@import '../../styles/fonts.css';

:root {
  --interiorContainerHeight: calc(100vh - var(--fullHeaderHeight));
  --interiorContainerHeightPreview: calc(100vh - var(--detailFixedHeaderOffset));
}

.view {
  width: 100%;
  height: var(--interiorContainerHeight);
  margin-top: var(--fullHeaderHeight);
  display: inline-block;
  overflow: hidden;

  &.preview {
    height: var(--interiorContainerHeightPreview);
    margin-top: var(--detailFixedHeaderOffset);
  }
}
