@import '../../../../../styles/layout.css';
@import '../../../../../styles/fonts.css';
@import '../../../../../styles/colors.css';

.row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  width: 100%;
}

.row-content {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 2.5rem;
  overflow: hidden;

  &.sub-row {
    padding-right: 1rem;
  }
}

.left-content {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 2.5rem;
}

.group-icon-and-name {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    cursor: pointer;
  }
}

.name-container {
  display: flex;
  flex-flow: column;
  padding-left: 0.75rem;
}

.title {
  font-size: var(--fs-md);
  font-weight: 500;
  color: var(--dim);
  text-transform: capitalize;
  padding-right: 0.75rem;

  &.selected {
    color: var(--blue);
  }

  &.disabled {
    color: var(--ash);
  }
}

.subtext {
  font-size: var(--fs-sm);
  color: var(--gray);
  padding-right: 0.75rem;

  &.disabled {
    color: var(--ash);
  }
}

.icon {
  cursor: pointer;
}
