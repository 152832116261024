@import '../../styles/colors.css';
@import '../../styles/fonts.css';

.text-limit {
  color: var(--gray);
  font-size: var(--fs-sm);
  white-space: nowrap;

  &.red {
    color: var(--red);
  }
}
