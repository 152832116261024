@import '../../../../../styles/colors.css';
@import '../../../../../styles/layout.css';
@import '../../../../../styles/fonts.css';

.modal-content {
  display: flex;
  flex-flow: row nowrap;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--fs-xxl);
  width: 100%;
  height: 2rem;
}

.sidebar-container {
  width: 21.25rem;
  height: 44.75rem;
  border-right: 1px solid var(--ash);
  overflow: auto;
}

.map-container {
  flex: 1 1 auto;
  height: 44.75rem;
}

.section-header {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1.5rem;
  border-bottom: 1px solid var(--ash);
  height: 3rem;
}

.sidebar-section:not(:first-child) {
  border-top: 1px solid var(--ash);
}

.header-action-buttons {
  display: flex;
  align-items: center;
}

.location-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  height: 3rem;
  padding: 0 1rem 0 1.5rem;

  &:hover {
    background-color: var(--fog);
  }
}

.row-title {
  display: flex;
  align-items: center;
}

.title {
  margin-left: 1rem;
}

.action-buttons {
  display: none;
}

.location-row:hover > .action-buttons {
  display: inline-block;
  white-space: nowrap;
}
