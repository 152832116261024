@import '../../../../styles/colors.css';
@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';

:root {
  --divider: 1px solid var(--typhoon);
  --listIndent: 1.25rem;
}

.detail-row-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.75rem;
}

.detail-row-label {
  color: var(--gray);
  width: 50%;
}

.detail-row-value {
  color: var(--dark);
  width: 50%;
}

.info-container {
  border-top: var(--divider);
  height: 100%;
  background-color: var(--white);
}

.info-detail-section {
  padding: 1rem;
  display: flex;
  height: 100%;
  flex-direction: column;
  border-top: var(--divider);
}

.work-container {
  border-top: var(--divider);
  height: 100%;
  background-color: var(--white);
}

.work-heading-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  color: var(--dark);
  padding-left: var(--listIndent);
  height: 3.125rem;
  line-height: 3.125rem;
}

.work-heading-title {
  padding-left: 0.5rem;
}

.work-detail-section {
  border-top: var(--divider);
  display: flex;
  flex-direction: column;
}

.summary-button {
  flex: 0 0 auto;
  height: 2.75rem;
  width: var(--sidePaneWidth);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blue);
  color: var(--white);
  text-transform: uppercase;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
}

.icon-row {
  display: flex;
  flex-direction: row;
  background-color: var(--white);
}

.icon-container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--gray);

  &.active {
    color: var(--blue);
    border-bottom: 2px solid var(--blue);
  }

  & .label {
    font-size: var(--fs-sm);
    text-transform: uppercase;
    margin: -0.5rem 0;
    white-space: nowrap;
  }
}

.section-heading-container {
  color: var(--dark);
  padding-left: var(--listIndent);
  height: 3.125rem;
  line-height: 3.125rem;
}

.work-row-container {
  padding: 1rem 1rem 1rem 1.25rem;
  display: flex;
  align-items: center;
  border-bottom: var(--divider);
  cursor: pointer;
}

.work-row-content {
  width: 100%;
}

.work-row-name {
  color: var(--dark);
}

.work-detail-content {
  display: inherit;

  &:not(:last-child):after {
    content: ' • ';
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}

.work-row-details-container {
  color: var(--gray);
  display: flex;
  flex-direction: row;
  line-height: var(--fs-sm);
  font-size: var(--fs-sm);
}

.basic-info {
  padding: 1rem 1rem 0 1rem;
  border-bottom: 1px solid var(--typhoon);
}

.basic-info-row {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
}

.data-container {
  padding-left: 1rem;
}

.data-line {
  color: var(--dark);
  font-size: var(--fs-md);
}

.data-sub-line {
  font-size: var(--fs-md);
  color: var(--gray);
}

.attribute-group-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--typhoon);
  padding: 0 1rem;
  height: 3.125rem;
  color: var(--dark);
}

.attributes-container {
  padding: 1rem 1rem 0 1rem;
  border-bottom: 1px solid var(--typhoon);
}

.attribute-row {
  display: flex;
  padding-bottom: 1rem;
}

.attribute-content {
  overflow-wrap: anywhere;
}

.attribute-label {
  box-sizing: border-box;
  color: var(--gray);
  padding-right: 0.5rem;
  min-width: 45%;
  max-width: 45%;
}

.pending-attribute-label {
  vertical-align: middle;
  color: var(--gray);
  font-size: var(--fs-sm);
  margin-right: 1rem;
}

.change-indicator-container {
  position: relative;
}

.change-indicator {
  height: 0.3125rem;
  width: 0.3125rem;
  background: var(--blue);
  position: absolute;
  top: 0;
  right: -0.75rem;
  border-radius: 50%;
}
