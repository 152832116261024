@import '../../styles/colors.css';
@import '../../styles/layout.css';
@import '../../styles/fonts.css';

:root {
  --navSidePaneWidth: 60px;
  --listSidePaneWidth: calc(var(--sidePaneWidth) - var(--navSidePaneWidth));
  --widgetHeight: calc(100% - var(--dashboardWidgetTitleHeight));
  --listHeight: calc(100vh - 6.75rem);
  --divider: 1px solid var(--typhoon);
  --listIndent: 1.5em;
}

.list-container {
  height: 100%;
}

.sidepane-list-container {
  width: var(--sidePaneWidth);
  height: 100%;
}

.list-header {
  border-bottom: var(--divider);
  border-top: var(--divider);
  padding: 1rem 0;
  text-indent: var(--listIndent);
  vertical-align: middle;
  position: sticky;
  top: 0;
  z-index: 100;
  color: var(--dark);
  background-color: var(--white);
  box-shadow: 4px 0 10px -6px rgba(0, 0, 0, 0.2);
}

.list-item {
  padding-left: var(--listIndent);
  padding-bottom: 1em;
  border-bottom: var(--divider);
  cursor: pointer;
}

.map-container {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.map-container.expanded {
  padding-left: 340px;
}

.no-items-container {
  height: 100%;
  width: 100%;
  background-color: var(--typhoon);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 0 1.25rem;
  box-sizing: border-box;
}

.list-item-container {
  background-color: var(--white);
  overflow-y: scroll;
  height: 100%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.no-items-text {
  color: var(--gray);
  text-align: center;
  line-height: 1.3125rem;
  font-size: var(--fs-md);
  margin: 0;
}

.work-detail-content {
  display: inherit;

  &:not(:last-child):after {
    content: ' • ';
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}

.work-row-details-container {
  color: var(--gray);
  display: flex;
  flex-direction: row;
  line-height: var(--fs-sm);
  font-size: var(--fs-md);
}

.user-row-container {
  padding: 1rem 1rem 1rem 1.25rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  border-bottom: var(--divider);
  cursor: pointer;
}

.user-thumbnail-container {
  width: 25%;
}

.user-detail-content {
  width: 65%;
  padding-left: 0.5rem;
}

.user-name-container {
  color: var(--dark);
}

.user-role-container {
  padding-top: 0.25rem;
  color: var(--gray);
}

.asset-row-container {
  padding: 1rem 1rem 1rem 1.25rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  border-bottom: var(--divider);
  cursor: pointer;
}

.asset-list-legend-container {
  display: flex;
  padding: 0 1.25rem 0 0;
}

.asset-name-container {
  color: var(--dark);
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.asset-content-container {
  font-size: 0.8125rem;
  line-height: 1.25rem;
  color: var(--gray);
}

.priority-status-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.priority-status-icon {
  margin-right: 0.5rem;
}

.container {
  box-sizing: border-box;
  width: 100%;
  padding-top: var(--topNavHeight);
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--white);
  box-shadow: inset 0 3px 0 0 var(--white), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.title {
  font-size: var(--fs-lg);
  height: var(--dashboardWidgetTitleHeight);
  padding-left: 1.1em;
  padding-top: 1em;
  padding-bottom: 0.75em;
  box-sizing: border-box;
  color: var(--white);
  font-weight: 500;
  background-color: var(--teal);
}

.analytic-tile-section {
  width: 100%;
  z-index: 500;
  background-color: var(--white);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.analytics-tile-container {
  display: flex;
  flex-flow: column nowrap;
  min-height: 34rem;
  min-width: 38rem;
  box-shadow: inset 0 3px 0 0 var(--white), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--white);
  margin: 0.625rem;
}

.dashboard-container {
  display: flex;
  flex-grow: 1;
  height: calc(100% - 60px);
}

.side-nav-container {
  position: relative;
  z-index: 300;
}
