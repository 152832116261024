@import '../../../styles/colors.css';
@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';

.tree-container {
  padding-top: 0.5rem;
  box-sizing: border-box;
  height: 500px;
  overflow: auto;
}

.row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
}

.row-content {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 2.5rem;
  overflow: hidden;

  &.sub-row {
    padding-right: 1rem;
  }
}

.left-content {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 2.5rem;
}

.name-container {
  display: flex;
  flex-flow: column;

  &:hover {
    cursor: pointer;
  }
}

.title {
  font-size: var(--fs-md);
  color: var(--dark);
  text-transform: capitalize;
  white-space: nowrap;
  margin: 0;
}

.group-icon-container {
  margin-right: 1rem;
}

.subtext {
  font-size: var(--fs-sm);
  color: var(--gray);
  white-space: nowrap;
  margin: 0;
}

.footer {
  display: flex;
  align-items: center;
}
