/* stylelint-disable */
@import './colors.css';
@import './layout.css';
@import './fonts.css';

:root {
  --interiorContainerHeight: calc(100vh - var(--fixedHeaderOffset));
  --tableCellHeight: 2.5rem;
  --listViewTableWidth: calc(100% - var(--sidePaneWidth));
}

.hidden {
  visibility: collapse;
}

.detail {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: var(--tableCellHeight);
}

.list-view {
  width: var(--listViewTableWidth);
  height: calc(var(--interiorContainerHeight) - var(--paginationHeight));
  margin-top: var(--fixedHeaderOffset);
  overflow: auto;
}

.row-icon {
  width: 1.25rem;
}

.row-icon-svg {
  width: 2rem;
}

.detail,
.list-view,
.main-pane {
  display: inline-block;
  background-color: var(--white);

  & table {
    border-collapse: collapse;
    cursor: default;
    width: 100%;
    table-layout: auto;
    padding-left: 2.5rem;
  }

  & table.sticky {
    position: sticky;
    z-index: 100;
    top: 0;
  }

  & table.sticky.footer {
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background: var(--white);
  }

  & tr.title-header {
    background-color: var(--white);
    z-index: 100;
    position: relative;
  }

  & tr.title-header:hover {
    background-color: var(--white);
  }

  & tr:hover {
    background-color: var(--highlight);
  }

  & th {
    font-size: var(--fs-sm);
    font-weight: 500;
    color: var(--gray);
    text-transform: uppercase;
    background-color: var(--fog);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: var(--tableCellHeight);
    padding-left: 1.25rem;
    border-bottom: 1px solid var(--typhoon);
    vertical-align: middle;
    text-align: left;
  }

  & th.is-sortable {
    cursor: pointer;
  }

  & th.is-sortable:after {
    font-family: 'Material Icons';
    content: '\E5DB'; /* arrow_downward */
    vertical-align: middle;
    margin-left: 1em;
    font-size: var(--fs-md);
  }

  & th.is-sortable.sort-descending,
  & th.is-sortable.sort-ascending {
    color: var(--dark);
    font-weight: 500;
  }

  & th.is-sortable.sort-ascending:after {
    content: '\E5D8'; /* arrow_upward */
  }

  & td {
    height: var(--tableCellHeight);
    padding-left: 1.25rem;
    border-bottom: 1px solid var(--typhoon);
    vertical-align: middle;
    text-align: left;
    font-weight: 400;
    color: var(--dark);
  }

  & td.load-more-wrapper {
    padding: 0;
    height: inherit;
  }

  & td.additional-options {
    text-align: right;
    padding-right: 1rem;
    min-width: 2rem;
    max-width: 2rem;
  }

  & td.icon {
    text-align: left;
    min-width: 1rem;
    max-width: 1rem;
  }

  & td.button-controls {
    text-align: right;
    padding-right: 2rem;
    min-width: 5rem;
    max-width: 5rem;
  }

  & td.header-buttons {
    text-align: right;
    min-width: 2rem;
    position: absolute;
    padding-right: 1rem;
    right: 0;
  }

  & td.priority {
    & .priority-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      & .value-priority {
        padding-left: 0.5rem;
      }
    }
  }

  & td.status-pill {
    position: relative;
    min-width: 5rem;
    max-width: 5rem;
  }

  & td.assigned-users {
    min-width: 12rem;
    max-width: 12rem;
  }

  & td.clickable {
    cursor: pointer;
    font-weight: 500;
    overflow: visible;
    white-space: nowrap;
  }

  & .clickable-link {
    color: inherit;
    text-decoration: none;
  }

  & .selected-items {
    color: var(--blue);
  }

  & .pagination-container {
    position: fixed;
    bottom: 0;
    left: var(--sidePaneWidth);
    right: 0;
    width: calc(100% - var(--sidePaneWidth));
    background-color: var(--white);
    border: none;
  }

  /* stylelint-disable declaration-no-important, selector-max-compound-selectors */

  /* [ATOM-1206] Workaround for removing IconButton default height for hidden table only and fixing stylelint errors */

  & .hidden td,
  & .hidden td > *,
  & .hidden th {
    height: 0;
    line-height: 0;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
  }

  & .hidden td.checkbox > button {
    height: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.status-pill-container {
  height: var(--tableCellHeight);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  &.left-aligned {
    justify-content: flex-start;
  }
}

.status-cell {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.status-text {
  padding-left: 0.5rem;
}

.usage-text {
  text-transform: uppercase;
}
