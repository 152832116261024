@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

.modal-content {
  height: 31rem;
  overflow: auto;
  padding: 0 3rem 1.5rem 3rem;
  display: flex;
  flex-flow: column nowrap;
}

.search-box {
  padding-top: 0.5rem;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-bottom: 1px solid var(--ash);
}

.form-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--ash);
}

.form-title-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.form-title-content {
  padding-left: 1rem;
  display: flex;
  flex-flow: column nowrap;
}

.form-row-title {
  font-size: var(--fs-md);
  font-weight: 500;

  &.selected {
    color: var(--blue);
  }

  &.disabled {
    color: var(--silver);
  }
}

.title-subtext {
  font-size: var(--fs-md);
  color: var(--gray);

  &.disabled {
    color: var(--silver);
  }
}
