@import '../../../styles/colors.css';
@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';

:root {
  --navSidePaneWidth: 60px;
  --listSidePaneWidth: calc(var(--sidePaneWidth) - var(--navSidePaneWidth));
  --widgetHeight: calc(100% - var(--dashboardWidgetTitleHeight));
  --listHeight: calc(100vh - 6.75rem);
  --divider: 1px solid var(--typhoon);
  --listIndent: 1.5em;
}

.sidepane-list-container {
  width: var(--sidePaneWidth);
  height: 100%;
}

.list-header {
  border-right: var(--divider);
  border-top: var(--divider);
  padding: 1rem 0;
  text-indent: var(--listIndent);
  vertical-align: middle;
  top: 0;
  z-index: 100;
  color: var(--dark);
  background-color: var(--white);
  box-shadow: 4px 0 10px -6px rgba(0, 0, 0, 0.2);
  position: relative;
}

.list-header .close-button {
  position: absolute;
  right: 0;
  top: 0;
}
/* stylelint-disable */
.list-header .close-button span {
  color: rgb(153, 153, 153) !important;
}
/* stylelint-enable */

.list-item {
  padding-left: var(--listIndent);
  padding-bottom: 1em;
  border-bottom: var(--divider);
  cursor: pointer;
}

.name {
  margin-top: 1rem;
  color: var(--dark);
  font-weight: 500;
}

.list-item-container {
  background-color: var(--white);
  overflow-y: scroll;
  height: calc(100% - 53px);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.asset-row-container {
  padding: 1rem 1rem 1rem 1.25rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  border-bottom: var(--divider);
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
}

.asset-row-container:hover {
  background: var(--typhoon);
}

.asset-list-legend-container {
  display: flex;
  padding: 0 1.25rem 0 0;
}

.asset-name-container {
  color: var(--dark);
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.asset-content-container {
  font-size: 0.8125rem;
  line-height: 1.25rem;
  color: var(--gray);
}

.no-items-container {
  height: 100%;
  width: 100%;
  background-color: var(--typhoon);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 0 1.25rem;
  box-sizing: border-box;
}

.no-items-container-v2 {
  height: 100%;
  width: 100%;
  background-color: var(--white);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 0 1.25rem;
  box-sizing: border-box;
}

.no-items-text {
  color: var(--gray);
  text-align: center;
  line-height: 1.3125rem;
  font-size: var(--fs-md);
  margin: 0;
}

.no-items-text-v2 {
  color: #9E9E9E;
  text-align: center;
  line-height: var(--lh-xxl);
  font-size: var(--fs-lg);
  font-weight: 400;
}

.no-items-subtext-v2 {
  color: #9E9E9E;
  text-align: center;
  line-height: var(--lh-lg);
  font-size: var(--fs-md);
  font-weight: 400;
}

.priority-status-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.priority-status-icon {
  margin-right: 0.5rem;
}

.work-detail-content {
  display: inherit;

  &:not(:last-child):after {
    content: ' • ';
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}

.work-row-details-container {
  color: var(--gray);
  display: flex;
  flex-direction: row;
  line-height: var(--fs-sm);
  font-size: var(--fs-md);
}

.drawer-content-list {
  background: var(--white);
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 53px);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid var(--ash);
  border-left: 0;
}

.drawer-content-list-header {
  font-size: var(--fs-md);
  font-weight: 400;
  color: var(--dark);
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 1.25rem;
  z-index: 100;
  box-shadow: inset 0 -1px 0 var(--ash);
}

.drawer-content-list-header-title {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.drawer-content-list-header-action {
  display: flex;
  align-items: center;
  flex-grow: 0;
}

.list-item-v2 {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid var(--ash);
  cursor: pointer;
  transition: background-color 0.2s ease-in;
  min-height: 100px;
}

.list-item-v2:hover {
  background: var(--typhoon);
}

.list-item-v2 .icon-container {
  width: 60px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.list-item-v2 .icon-container img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.list-item-v2 .description-container {
  align-items: center;
}

.description-container .heading {
  line-height: 1.5;
  color: var(--dark);
  font-weight: 600;
  font-size: var(--fs-md);
}

.description-container .heading.margin {
  margin-bottom: 5px;
}

.description-container .subheading {
  line-height: 1.5;
  font-size: var(--fs-md);
  color: var(--dark);
}

.description-container .secondary {
  font-size: var(--fs-sm);
  line-height: var(--lh-sm);
  color: var(--gray);
}

.description-container .secondary.tall {
  line-height: var(--lh-lg);
}

.description-container .secondary.medium {
  display: flex;
  align-items: center;
  line-height: var(--lh-md);
}

.description-container .details-container {
  display: flex;
}

.details-container > div ~ div:before {
  content: '•';
  margin: 0 4px;
}

.image-container {
  min-width: 60px;
  height: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 2px;
}

.container {
  width: var(--sidePaneWidth);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
}

.tray-container {
  height: 100%;
  width: 100%;
  background-color: var(--white);
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  box-shadow: 4px 0 10px -6px rgba(0, 0, 0, 0.2);
}

.kml-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--ash);
  box-sizing: border-box;
  background-color: var(--white);
}

.kml-header-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.kml-header-title {
  font-size: var(--fs-md);
  font-weight: 600;
}

.kml-attribute {
  font-size: var(--fs-md);
  color: var(--gray);

  &.label {
    min-width: 45%;
    max-width: 45%;
  }
}

.kml-attributes {
  width: 100%;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
}

.kml-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.5rem 0;
}

.kml-value {
  font-size: var(--fs-md);
}
