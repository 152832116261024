@import '../../../../styles/colors.css';
@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';

.title {
  background-color: var(--white);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 1rem 1.25rem;
  margin: 0;
  border-bottom: 1px solid var(--ash);
}

.title-label {
  color: var(--black);
  font-family: Roboto, sans-serif;
  font-size: var(--fs-xxl);
  font-weight: 500;
  line-height: 1.6;
}

.title-subtext {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  color: var(--dim);
  line-height: var(--lh-lg);
  font-size: var(--fs-md);
  font-weight: 400;
  padding: 0;
}

.footer {
  background-color: var(--white);
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0.25rem 0.75rem;
  gap: 1.25rem;
  justify-content: space-between;
}

.footer-error {
  line-height: var(--lh-lg);
  font-size: var(--fs-md);
  font-weight: 400;
  color: var(--red);
  margin-right: 2rem;
}

.footer-full {
  width: 100%;
}

.footer-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1.25rem;
}
