@import '../../../styles/colors.css';
@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';

.upload-name-container {
  display: flex;
  width: 100%;
}

.dropzone {
  height: 4.375rem;
  line-height: 4.375rem;
  text-align: center;
  border-radius: 5px;

  &.dashed {
    border: 2px dashed var(--silver);
  }

  &.solid {
    border: 2px solid var(--blue);
  }

  &.drag {
    border: 2px dashed var(--blue);
  }
}

.tile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0 0.5rem 0 1.5rem;
  justify-content: space-between;
}

.icon {
  display: flex;
  align-items: center;
  margin-right: 1rem;

  &.close {
    cursor: pointer;
  }
}

.loading {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  border: 5px solid var(--blue);
  box-sizing: border-box;
}

.file-name {
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: initial;
}
