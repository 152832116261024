@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.work-type-creation-modal-content {
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.work-type-text-content {
  color: var(--dark);
  font-size: var(--fs-md);
  margin-bottom: 1rem;
}

.modal-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding-top: 1rem;

  &.title {
    font-size: var(--fs-lg);
    font-weight: 500;
  }
}

.tile-container {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  height: 3.125rem;
  width: 100%;
}

.choose-button {
  display: flex;
  align-items: center;
  width: 100%;
  height: 3.125rem;
  margin-left: 1rem;
  color: var(--blue);

  &:hover {
    cursor: pointer;
  }

  &.align-left {
    margin-left: 0;
  }
}

.work-type-inventory-selection-container {
  display: flex;
  flex-flow: column nowrap;
  margin: 0;
  margin-left: 2rem;
}

.schema-menu-item-title {
  padding-left: 0.5rem;
  font-size: var(--fs-md);

  &.empty {
    color: var(--gray);
  }
}

.empty-icon {
  color: var(--gray);
  padding: 0 14px;
}

.schema-menu-item-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
}

.work-type-creation-modal-subtext {
  font-size: var(--fs-sm);
  color: var(--dark);
}

.radio-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &.right {
    margin-left: 3rem;
  }
}

.tooltip-content {
  position: relative;
  width: 18rem;
  display: flex;
  padding: 0.5rem;
  color: var(--dark);
}

.inheritance-content {
  padding-left: 1rem;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-content {
  display: flex;
  flex-flow: row nowrap;
  height: 27.25rem;
}

.stepper-container {
  box-sizing: border-box;
  padding: 1rem 1.5rem;
  width: 12.5rem;
  border-right: 1px solid var(--ash);
}

.content-container {
  padding: 0 1.5rem;
}

.radio-button-row {
  box-sizing: border-box;
  width: 100%;
  max-width: 27.25rem;
  border: 2px solid var(--ash);
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  border-radius: 4px;

  &.selected {
    border: 2px solid var(--blue);
  }

  &:hover {
    cursor: pointer;
  }
}

.radio-button-label {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.radio-button-row-icon {
  margin-right: 1rem;
}

.radio-button-row-title {
  font-size: var(--fs-md);
  font-weight: 500;
}

.radio-button-row-subtext {
  font-size: var(--fs-sm);
  color: var(--dim);
}

.inheritance-subtext {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  max-width: 27.25rem;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  border-radius: 4px;
  background-color: var(--mist);
}

.icon-container {
  margin-right: 1rem;
}
