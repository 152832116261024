@import '../../../styles/fonts';
@import '../../../styles/colors';
@import '../../../styles/layout';

:root {
  --leftSpacing: 0.6125rem;
  --elementRightSpacing: 2.125em;
  --elementListRowHeight: 3.5em;
  --iconWidth: 24px;
  --interiorContainerHeight: calc(100vh - var(--detailFixedHeaderOffset));
  --headerHeight: 50px;
  --folderTreeHeight: 350px;
  --innerFolderTreeHeight: 300px;
  --containerIndent: 1.25rem;
  --breadcrumbHeight: 3rem;
  --tableCellHeight: 2.5rem;
  --listViewTableWidth: calc(100% - var(--sidePaneWidth));
}

/* stylelint-disable selector-no-qualifying-type */

.header-container {
  height: 50px;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset var(--containerShadow), var(--containerShadow);
}

.group-container {
  height: 3.5rem;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: flex-start;

  &:not(.modal):hover {
    background-color: var(--highlight);
  }
}

.group-icon {
  display: flex;
  margin-left: var(--leftSpacing);
  padding-bottom: 0.4375rem;
}

.group-label {
  color: var(--gray);
  margin: 0 0.375rem;
  user-select: none;
  text-transform: uppercase;
}

.header-label {
  color: var(--dark);
  margin: 0 1.25rem;
  user-select: none;
}

.header-icon {
  margin: 0 0.5em;
  color: var(--gray);
}

.attribute-list-container {
  padding: 1em 3.75em;
  display: flex;
  flex-direction: column;
}

.spinner-container {
  position: absolute;
  top: 1rem;
  right: 7rem;
  width: 100%;
}

.element-list-container {
  padding: 0 2.125em 0 3em;
  display: flex;
  flex-direction: column;
}

.sub-element-list-container {
  padding-left: 3em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  page-break-before: always;
}

/* TODO: [ATOM-2536] Refactor hover behavior to cover entire row and be simpler */

.element-label {
  width: 450px;
  line-height: var(--lh-lg);
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  position: relative;
  white-space: normal;
}

.element-label-text {
  flex-grow: 1;
  cursor: pointer;
  padding: 0 0.375rem;

  & .element-asset-type {
    font-weight: 500;
    padding-left: 2.5rem;
    margin-top: -0.75rem;
    color: var(--gray);
    font-size: var(--fs-sm);
  }
}

.element-list-row {
  height: var(--elementListRowHeight);
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  position: relative;
  min-width: 7rem;

  &.disabled {
    color: var(--disabled);
  }

  &.disabled,
  &.modal {
    cursor: default;
  }

  &:not(.disabled, .modal):hover:before {
    content: '';
    background-color: var(--highlight);
    width: 100vw;
    height: var(--elementListRowHeight);
    margin-left: -50vw;
    position: absolute;
    left: 0;
    pointer-events: none;
  }
}

.element-icon {
  align-self: center;
  margin-top: -0.4375rem;
  width: var(--iconWidth);
  position: relative;
}

.element-spacing {
  width: var(--iconWidth);
}

.schema-element-icon {
  display: inline-block;
  margin-left: -1.75em;
  margin-top: 0.5em;
  width: var(--iconWidth);
  position: relative;
  cursor: pointer;
}

.element-list-row-container {
  min-height: var(--elementListRowHeight);
  padding-left: var(--leftSpacing);
  padding-right: var(--elementRightSpacing);
  display: flex;
  flex-direction: column;
}

.element-detail-spinner-container {
  position: relative;
  height: 50vh;
}

.element-name {
  margin-top: 0.5rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  color: var(--dark);
  font-size: var(--fs-lg);
  font-weight: 500;
}

.element-display-name {
  padding-left: 1rem;

  &.new {
    color: var(--blue);
  }
}

.element {
  height: 100%;
  margin-top: 0.25rem;
  display: inline-flex;
  align-self: center;
}

.icon-bar {
  position: absolute;
  right: 0.5rem;
  top: 0.25rem;
}

.icon-row {
  padding-left: 0.25rem;
  padding-top: 0.5rem;
  box-shadow: 0 1px 4px 0 var(--silver);
  background-color: var(--white);
  position: sticky;
  top: 0;
  z-index: 300;
}

.icon-container {
  cursor: pointer;
  text-align: center;
  display: inline-block;
  width: 5.125rem;
  margin-right: 1.5rem;

  &.active {
    border-bottom: 2px solid var(--blue);
  }
}

.action-container {
  padding: 0;
}

.element-modal-spinner-container {
  padding: 2.5rem;
  height: 50vh;
}

.element-modal-container {
  padding: 2.5rem 4.25rem 2.5rem 2.5rem;
  border-top: 1px solid var(--ash);
  border-bottom: 1px solid var(--ash);
}

.element-modal-subheading {
  color: var(--dim);
  background: var(--white);
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
  padding: 1rem 0;
}

.element-modal-asset-name {
  font-weight: 500;

  &:before {
    content: ' ';
  }

  &:after {
    content: '.';
  }
}

.element-modal-selection-backdrop {
  width: 100%;
  color: var(--dim);
  background: var(--fog);
  font-size: var(--fs-md);
  line-height: 2.5em;
  margin-top: 1.5rem;
  padding: 1em;
  box-sizing: border-box;
}

.files-container {
  width: 100%;
}

.files-header {
  height: 3.125rem;
  padding: 0 1.25rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.files-controls {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.dropdown-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}

.media-total-text {
  padding-left: 2rem;
  text-transform: capitalize;
}

.hidden {
  visibility: hidden;
}

.detail {
  width: 100%;
  height: calc(100% - var(--headerHeight));
  overflow: auto;
}

.name-row-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.name-row-icon {
  margin-right: 1rem;
}

.detail,
.main-pane {
  display: inline-block;
  height: calc(100% - var(--headerHeight));
  background-color: var(--white);

  & table {
    border-collapse: collapse;
    cursor: default;
    width: 100%;
    table-layout: auto;
    padding-left: 2.5rem;
  }

  & table.sticky {
    position: sticky;
    z-index: 100;
    top: 0;
  }

  & table.sticky.footer {
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background: var(--white);
  }

  & tr.title-header {
    background-color: var(--white);
    z-index: 100;
    position: relative;
  }

  & tr:hover {
    background-color: var(--highlight);
  }

  & th {
    font-size: var(--fs-sm);
    font-weight: 500;
    color: var(--gray);
    text-transform: uppercase;
    background-color: var(--fog);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: var(--tableCellHeight);
    padding-left: 1.25rem;
    border-bottom: 1px solid var(--typhoon);
    vertical-align: middle;
    text-align: left;
  }

  & td {
    height: var(--tableCellHeight);
    padding-left: 1.25rem;
    border-bottom: 1px solid var(--typhoon);
    vertical-align: middle;
    text-align: left;
    font-weight: 400;
    color: var(--dark);
  }

  & td.additional-options {
    text-align: right;
    padding-right: 1rem;
    min-width: 2rem;
    max-width: 2rem;
  }

  & td.clickable {
    cursor: pointer;
    font-weight: 500;
    overflow: visible;
    white-space: nowrap;
  }

  & .clickable-link {
    color: inherit;
    text-decoration: none;
  }

  & .selected-items {
    color: var(--blue);
  }

  /* stylelint-disable declaration-no-important, selector-max-compound-selectors */

  & .hidden td,
  & .hidden td > *,
  & .hidden th {
    height: 0;
    line-height: 0;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
  }

  & .hidden td.checkbox > button {
    height: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.download-link {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

.element-right-icon-container {
  display: flex;
  flex-flow: row nowrap;
}

.pending-approval-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
}

.pending-element-label {
  color: var(--gray);
  font-size: var(--fs-sm);
  text-transform: capitalize;
  margin-right: 1rem;
  display: flex;
}

.pending-controls-container {
  display: flex;
  margin-right: 1rem;
}

.pending-element-control {
  display: flex;
}
