@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.header-container {
  box-sizing: border-box;
  position: fixed;
  margin-top: var(--topNavHeight);
  z-index: 200;
  width: 100vw;
  background-color: var(--red);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  height: calc(var(--teamDetailSubNavHeight) + 22px);
  color: var(--white);
  font-weight: 500;
}

.top-bar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.title-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.title {
  font-size: var(--fs-lg);
  font-weight: 500;
}

.description {
  margin-left: 3rem;
  width: 70%;
  height: 1.375rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navigation-bar {
  margin-top: 0.5rem;
  margin-left: 1.5rem;
}

.icon-container {
  cursor: pointer;
  text-align: center;
  display: inline-block;
  min-width: 6rem;
  opacity: 0.4;
  padding-bottom: 0.6rem;
  margin-right: 0.5rem;

  &.active {
    opacity: 1;
    border-bottom: 3px solid var(--white);
  }

  & .label {
    font-size: var(--fs-sm);
    text-transform: uppercase;
    margin: -0.5rem 0;
    white-space: nowrap;
  }
}

.color-title {
  font-size: var(--fs-sm);
  color: var(--dim);
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.color-container {
  border: 1px solid var(--ash);
  padding: 0.5rem;
  display: flex;
  align-items: space-between;
  flex-flow: row wrap;
}

.color-swatch {
  font-size: var(--fs-xxxl);
  border: 18px solid var(--white);
  border-radius: 2rem;
  width: 1.5rem;
  height: 1.5rem;
  margin: 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  &.selected {
    border-color: rgba(0, 125, 239, 0.3);
  }

  &:hover {
    border-color: rgba(0, 125, 239, 0.3);
    cursor: pointer;
  }
}
