@import '../../styles/colors.css';

.pagination-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  color: var(--dim);
}

.pagination-limit {
  padding-left: 1rem;
}

.limit-option {
  padding-right: 2.5rem;
}
