@import '../../../../styles/colors.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/layout.css';

:root {
  --headerOffset: 6.875rem;
}

.spinner-container {
  position: relative;
  height: 50vh;
}

.container {
  width: 100%;
}

.header {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.25rem;
  min-height: 3.125rem;
}

.title-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.title {
  font-size: var(--fs-xl);
  color: var(--dark);
  font-weight: 500;
  margin: 0 0.625rem;
}

.subtitle {
  font-size: var(--fs-md);
  color: var(--gray);
  margin-left: 0.625rem;
}

.score-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.score-title {
  font-size: var(--fs-lg);
  color: var(--dark);
  font-weight: 500;
  margin-right: 1rem;
}

.score {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 5rem;
  font-size: var(--fs-lg);
  color: var(--white);
  font-weight: 600;
}

.table-header {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  min-height: 2.5rem;
  background-color: var(--fog);
  color: var(--gray);
  font-size: var(--fs-sm);
  font-weight: 500;
  text-transform: uppercase;
  border-top: 1px solid var(--silver);
  border-bottom: 1px solid var(--silver);
  padding: 0 1.875rem;
}

.description-column {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  box-sizing: border-box;
  width: 30rem;
  min-height: 2.25rem;
  padding: 0.3125rem;
}

.large-column {
  min-width: 16.25rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.3125rem;
  box-sizing: border-box;
}

.normal-column {
  min-width: 8rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.3125rem;
  box-sizing: border-box;

  &.rating {
    flex: 1 1 0;
  }
}

.small-column {
  min-width: 5rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 0.3125rem;
  box-sizing: border-box;

  &.points {
    width: 5rem;
  }
}

.table-rows-container {
  height: calc(100vh - 13rem);
  overflow-y: auto;
  overflow-x: hidden;
}

.table-row {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  min-height: 3.125rem;
  border-bottom: 1px solid var(--silver);
  padding: 0 1.875rem;
}

.modal-description {
  font-size: var(--fs-md);
  color: var(--dark);
  margin-bottom: 2rem;
}

.modal-body {
  border-top: 1px solid var(--ash);
  border-bottom: 1px solid var(--ash);
  padding: 1rem 2rem;
}

.description-row {
  width: 100%;
  min-height: 2.25rem;
  background: var(--fog);
  cursor: pointer;
  padding: 0.625rem;
  box-sizing: border-box;
}

.column-suffix-text {
  padding-left: 5px;
  text-transform: uppercase;
  color: var(--gray);

  &.blue {
    color: var(--blue);
  }
}

.photo-modal-content {
  flex: 1 1 auto;
  border-top: 1px solid var(--ash);
  border-bottom: 1px solid var(--ash);
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  overflow-y: auto;
  max-height: var(--formModuleMediaModalHeight);
}

.media-tile-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  width: 11.25rem;
  height: 11.875rem;
  margin-right: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid var(--ash);
  border-radius: 6px;
}

.media-image-container {
  width: 100%;
  height: 8.75rem;
  background-color: var(--fog);
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.file-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
}

.media-tile-info {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
}
