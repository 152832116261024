@import '../../../../../../styles/colors.css';
@import '../../../../../../styles/layout.css';
@import '../../../../../../styles/fonts.css';

.field {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-basis: 50%;
  padding: 0.625rem 1.25rem 0.375rem 1.25rem;
  width: 100%;
}

.field-icon {
  min-width: 1.5rem;
  padding: 0 1.375rem 0 0;
}

.field-content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0.3125rem 0 0.3125rem 0;
  width: 100%;
}

.field-title {
  color: var(--dim);
  font-size: var(--fs-sm);
  line-height: var(--lh-md);
  font-weight: 400;
  padding: 0 0 0.125rem 0;
}

.field-component {
  color: var(--dark);
  font-size: var(--fs-md);
  line-height: var(--lh-lg);
  font-weight: 500;
  width: 100%;
}
