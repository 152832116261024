@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';
@import '../../../styles/layout.css';

.task-section-container {
  height: calc(100vh - 297px);
  overflow: auto;
  overflow-x: hidden;
}

.task-container {
  border-bottom: 1px solid var(--ash);
  padding: 0.5rem 1rem 0.5rem 0;
}

.task-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem 0 1.25rem;
  border-bottom: 1px solid var(--ash);
  min-height: 3.125rem;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.25rem;
  padding-right: 0.5rem;
}

.left-header-section {
  display: flex;
  align-items: center;
}

.right-header-section {
  display: flex;
  align-items: center;
}

.task-header-title {
  flex: 1 1 auto;
  font-size: var(--fs-lg);
  color: var(--dim);
  font-weight: 500;
  border-bottom: 1px solid var(--white);
  padding: 5px 0;
  margin-left: 1rem;
  width: 19.5rem;
}

.task-header-title-hover {
  flex: 1 1 auto;
  font-size: var(--fs-lg);
  color: var(--dim);
  font-weight: 500;
  border-bottom: 1px solid var(--white);
  padding: 5px 0;
  margin-left: 1rem;
  width: 19.5rem;

  &:hover {
    cursor: pointer;
    border-bottom: 1px solid var(--silver);
    background: var(--typhoon);
  }
}

.change-indicator-container {
  position: relative;
}

.change-indicator {
  height: 0.3125rem;
  width: 0.3125rem;
  background: var(--blue);
  position: absolute;
  top: 0;
  right: -0.1875rem;
  border-radius: 50%;
}

.task-name-textfield-container {
  position: relative;
  padding-left: 1rem;
  width: 16rem;
}

.button-container {
  right: 0;
  bottom: -1.5rem;
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background: var(--white);
  z-index: 300;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  margin-left: 0.25rem;
  box-shadow: var(--muiDropdownBoxShadow);

  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.2) 0 1px 6px, rgba(0, 0, 0, 0.2) 0 1px 4px;
  }
}

.content-container {
  display: flex;
  padding-top: 1rem;
}

.left-column {
  width: 25rem;
}

.right-column {
  width: 18.75rem;
  margin-left: 20px;
}

.task-detail-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding: 0 1.25rem 1.25rem 1.5rem;
}

.task-detail-row-content {
  width: 100%;
  padding-left: 1.25rem;
}

.time-detail-row {
  display: flex;
  padding-top: 0.625rem;
  padding-left: 1.5rem;
}

.time-row-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-left: 1.25rem;
}

.time-picker-container {
  margin-top: -3px;
}

.time-row-item {
  padding-right: 0.5rem;
  height: 3rem;
  padding-bottom: 1.25rem;
  width: 6.25rem;
}

.label {
  font-size: var(--fs-sm);
  white-space: nowrap;
  padding-right: 0.5rem;
}

.label-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.date-display {
  padding-top: 2px;
  white-space: nowrap;
}

.tooltip-content {
  font-size: var(--fs-sm);
}

.cost-container {
  display: flex;
  padding: 0.625rem 1.25rem;
  background-color: var(--fog);
}

.cost-content-left {
  width: 60%;
}

.cost-content-right {
  width: 40%;
}

.estimated-cost-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 19px;
}

.estimated-cost-value {
  padding-right: 1.5rem;
}

.task-cost-textfield-container {
  flex: 1 1 auto;
  position: relative;
  margin-top: -12px;
}
