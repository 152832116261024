@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

.icon-modal-container {
  display: inline-block;
}

.modal-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;

  &.name {
    margin-bottom: 2rem;
  }

  &.due-date {
    margin-bottom: 2rem;
  }

  &.no-icon {
    padding-left: 3.5rem;
  }

  &.asset {
    min-height: 4.5rem;
  }
}

.toggle-container {
  margin-top: 0.5rem;
}
