@import '../colors.css';

.rbc-header {
  height: 34px;
  color: var(--gray);
  font-weight: 500;
  text-transform: uppercase;
  font-family: Roboto, arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rbc-header + .rbc-header {
  border: none;
  border-bottom: 1px solid var(--ash);
}

.rbc-date-cell {
  font-weight: 500;
  text-align: center;
}

.rbc-date-cell + .rbc-date-cell {
  font-weight: 500;
  text-align: center;
}

.rbc-time-view {
  overflow: auto;
}
