@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

.modal-content {
  display: flex;
  flex-flow: row nowrap;
  height: 27.25rem;
}

.stepper-container {
  box-sizing: border-box;
  padding: 1rem 1.5rem;
  width: 12.5rem;
  border-right: 1px solid var(--ash);
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step-subtext {
  font-size: var(--fs-sm);
  color: var(--gray);
  font-weight: 400;
}

.cost-subtext {
  text-transform: uppercase;
}

.title-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.tab-title {
  font-weight: 500;
  line-height: 1.25rem;
  margin-right: 1rem;
}

.title-subtext {
  color: var(--gray);
  margin-bottom: 0.5rem;
}

.tab-container {
  flex: 1 1 auto;
  padding: 1rem 1rem 0 1rem;
}

.list-container {
  height: 360px;
  overflow: auto;

  &.folder {
    height: 370px;
  }
}

.inventory-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-top: 1rem;
}

.cost-input-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 29.0625rem;
}

.separator {
  margin-bottom: -1rem;
  padding: 0 0.5rem;
}

.quantity-suffix {
  margin-left: 1rem;
  white-space: nowrap;
  text-transform: uppercase;
}
