@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

:root {
  --interiorContainerHeight: calc(100vh - var(--detailFixedHeaderOffset));
  --headerHeight: 3.125rem;
  --photoTileHeight: 14rem;
  --photoTileWidth: 15.625rem;
  --photoHeight: 8.75rem;
}

.body-container {
  height: var(--interiorContainerHeight);
  background-color: var(--typhoon);
}

.center-pane {
  height: var(--interiorContainerHeight);
  background-color: var(--white);
  width: 70%;
  margin-left: 15%;
}

.photo-block {
  box-sizing: border-box;
  padding: 1.25rem;
  height: calc(100% - 100px);
  max-width: calc(100vw - var(--sidePaneWidth));
  overflow: auto;
}

.photo-tiles-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.photo-tile {
  display: flex;
  flex-flow: column nowrap;
  height: var(--photoTileHeight);
  width: var(--photoTileWidth);
  border: 1px solid var(--ash);
  border-radius: 6px;
  margin-bottom: 0.625rem;
  margin-right: 0.625rem;
  position: relative;
}

.tile-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  height: var(--photoHeight);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--ash);
}

.image-info-container {
  padding: 0.625rem;
}

.image-name {
  color: var(--dark);
  font-size: var(--fs-md);
  font-weight: 500;
  width: calc(var(--photoTileWidth) - 1.875rem);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.additional-info-container {
  display: flex;
  flex-flow: column nowrap;
  color: var(--gray);
}

.image-title-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.pagination-container {
  border-top: 1px solid var(--ash);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  color: var(--dim);
  height: 50px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.checkbox-container {
  width: 100%;
  display: none;

  &.checked {
    display: inline-block;
  }
}

.image-mask:hover .checkbox-container {
  display: inline-block;
}

.image-mask {
  position: absolute;
  top: 0;
  height: var(--photoHeight);
  width: var(--photoTileWidth);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  &.checked {
    cursor: pointer;
    background-image: linear-gradient(to bottom, var(--black0_4), var(--black0_0));
  }

  &:hover {
    cursor: pointer;
    background-image: linear-gradient(to bottom, var(--black0_4), var(--black0_0));
  }
}
