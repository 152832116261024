@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';
@import '../../../styles/colors.css';

:root {
  --interiorContainerHeight: calc(100vh - var(--detailFixedHeaderOffset));
  --leftPaneWidth: 480px;
  --taskListHeaderHeight: 50px;
  --leftPaneHeight: calc(100% - 55px);
  --taskListItemHeight: 110px;
}

.tasks-container {
  display: flex;
  justify-content: row nowrap;
  overflow: hidden;
}

.pane {
  box-sizing: border-box;
  height: var(--interiorContainerHeight);
  width: 50%;
  overflow: hidden;

  &.left {
    width: var(--leftPaneWidth);
    overflow: auto;
  }

  &.right {
    padding: 0;
    width: calc(100% - var(--leftPaneWidth));
    overflow: auto;
    overflow-x: hidden;
    border-left: 1px solid var(--ash);
  }
}

.task-list-header {
  height: var(--taskListHeaderHeight);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--ash);
  padding: 0 0.5rem 0 1.25rem;
}

.task-list-container {
  display: flex;
  position: relative;
  flex-direction: column;
  height: var(--leftPaneHeight);
}

.task-list-loading-container {
  position: absolute;
  height: var(--leftPaneHeight);
  margin: 0 auto;
  left: 0;
  right: 0;
}

.task-list-item-loading-mask {
  opacity: 0.5;
}

.task-list-item-container {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  box-sizing: border-box;
  cursor: pointer;
  min-height: var(--taskListItemHeight);
  border-bottom: 1px solid var(--ash);
  border-left: 3px solid var(--white);
  padding: 0.75rem;
  z-index: 100;

  &.selected {
    background-color: var(--typhoon);
    border-left: 3px solid var(--blue);
  }
}

.task-list-content-container {
  display: flex;
  flex-flow: column nowrap;
  padding-left: 0.75rem;
}

.task-title {
  font-weight: 500;
  padding-bottom: 0.75rem;
}

.task-dates {
  padding-top: 0.75rem;
  display: flex;
  flex-flow: row nowrap;
  color: var(--gray);
}

.no-assignees-message {
  color: var(--gray);
}
