@import '../../../../../styles/layout.css';
@import '../../../../../styles/fonts.css';
@import '../../../../../styles/colors.css';

.field-drag-container {
  display: flex;
  align-items: center;
  width: 36.75rem;
  padding: 0.5rem 0.5rem 0.5rem 0;
}

.enum-single-nested-container {
  display: flex;
  flex-flow: row wrap;
}

.lock-container {
  z-index: 100;
}
