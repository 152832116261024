@import '../../../../../styles/colors.css';
@import '../../../../../styles/layout.css';
@import '../../../../../styles/fonts.css';

.layer-controls-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 2.25rem;
  margin-top: var(--mapControlsTopOffset);
  margin-left: 0.625rem;
  background-color: var(--white);
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
}

.icon-container {
  padding: 0 0.25rem;
  cursor: pointer;
  user-select: none;
}

.line-length-container {
  padding: 0.3125rem 0.625rem;
  background: var(--blue);
  color: var(--white);
  font-size: var(--fs-md);
  border-radius: 0.625rem;
  font-weight: 500;
}

.delete-container {
  height: 2.25rem;
  background-color: var(--white);
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  margin-right: -3px;
}

/* stylelint-disable */
.icon-container > button > div > div {
  pointer-events: none;
}
/* stylelint-enable */
