@import '../../../styles/colors.css';
@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';

.favorites-list {
  padding: 0.5rem 0;
}

.empty-favorites-list {
  display: flex;
  padding: 0.75rem;
  font-size: var(--fs-md);
  font-weight: 400;
  background: var(--fog);
  gap: 0.5rem;
}

.icon-col {
  width: 1.5rem;
}

.new-favorite-list-container {
  color: var(--blue);
}
