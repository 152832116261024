@import '../../../../../styles/layout.css';
@import '../../../../../styles/fonts.css';
@import '../../../../../styles/colors.css';

.asset-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem 0 1rem;
  height: 3.125rem;
  min-height: 3.125rem;
  border-left: 3px solid transparent;
  position: relative;

  &:hover {
    cursor: pointer;
    background-color: var(--typhoon);
  }

  &.selected {
    background-color: var(--fog);
    border-left: 3px solid var(--blue);
  }
}

.asset-name-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: 1;
  max-width: 75%;
}

.row-name {
  font-weight: 500;

  &.blue {
    color: var(--blue);
  }

  &.group {
    text-transform: uppercase;
    color: var(--gray);
    position: relative;
  }

  &.subtext {
    font-weight: 400;
    color: var(--gray);
    font-size: var(--fs-sm);
  }

  &.deleted {
    text-decoration: line-through;
    text-decoration-color: var(--blue);
  }
}

.row-name-text {
  position: relative;

  &.deleted {
    text-decoration: line-through var(--blue);
  }
}

.row-name-container {
  display: flex;
  flex-flow: column;
  padding-left: 0.75rem;
  max-width: 75%;
}

.controls-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 0.5rem;
  height: 3.125rem;
}

.group-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 0.5rem 0 1rem;
  height: 3.125rem;
  min-height: 3.125rem;

  &:hover {
    cursor: pointer;
    background-color: var(--typhoon);
  }
}

.pending-approval-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
}

.pending-element-label {
  color: var(--gray);
  font-size: var(--fs-sm);
  text-transform: capitalize;
  margin-right: 1rem;
  display: flex;
}

.pending-controls-container {
  display: flex;
  margin-right: 1rem;
}

.pending-element-control {
  display: flex;
}

.change-indicator {
  height: 0.3125rem;
  width: 0.3125rem;
  background: var(--blue);
  position: absolute;
  top: 0;
  right: -0.75rem;
  border-radius: 50%;

  &.icon {
    right: 0;
  }
}

.icon-container {
  position: relative;
}
