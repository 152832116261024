@import '../../../../styles/layout.css';
@import '../../../../styles/colors.css';
@import '../../../../styles/fonts.css';

.input-container {
  margin-bottom: 1.5rem;
}

.label {
  font-size: var(--fs-sm);
  color: var(--gray);
  margin-bottom: 0.5rem;
}

.tile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected {
  display: flex;
  align-items: center;
}

.back-icon {
  margin-right: 1rem;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.action-exists {
  font-style: italic;
}
