@import '../../styles/detail-header.css';
@import '../../styles/layout.css';
@import '../../styles/fonts.css';
@import '../../styles/colors.css';

.inventory-detail-container {
  height: 100%;
}

.spinner-container {
  position: absolute;
  left: 0;
  top: var(--topNavHeight);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.additional-options {
  display: inline-block;
  width: 2rem;
  margin-left: 1rem;
}
