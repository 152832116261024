@import '../../../../styles/layout.css';
@import '../../../../styles/colors.css';
@import '../../../../styles/fonts.css';

.tree-container {
  box-sizing: border-box;
  border-bottom: 1px solid var(--ash);
}

.row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border-left: 4px solid transparent;
  height: 2.5rem;

  &:hover {
    cursor: pointer;
    background-color: var(--fog);
  }

  &.selected {
    border-left: 4px solid var(--blue);
    background-color: var(--fog);
  }

  &.root {
    height: 3.125rem;
  }
}

.row-content {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3.125rem;
  overflow: hidden;

  &.sub-row {
    padding-right: 1rem;
    height: 2.5rem;
  }
}

.left-content {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3.125rem;
}

.name-container {
  display: flex;
  flex-flow: column;
  padding-left: 0.75rem;
  white-space: nowrap;
}

.title {
  font-size: var(--fs-md);
  color: var(--dim);
  text-transform: capitalize;

  &.root {
    font-weight: 500;
  }
}

.button-container {
  display: flex;
}
