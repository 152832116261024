@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 8rem;
}

.subtext {
  font-size: var(--fs-sm);
  color: var(--gray);
}

.header-date-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  line-height: 15px;
}

.header-date-day {
  font-weight: 600;
  font-size: var(--fs-sm);
}

.header-date {
  font-size: var(--fs-xs);
}

.tooltip-container {
  padding: 0.5rem 0.5rem 0 0.5rem;
}

.tooltip-row {
  font-size: var(--fs-md);
  padding-bottom: 0.5rem;
}

.type-option {
  text-transform: capitalize;
}

.input-container {
  min-width: 6rem;

  &.search {
    min-width: 11rem;
  }

  &.userGroup {
    min-width: 7.5rem;
  }
}

.table-footer {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 3.125rem;
  padding: 0 1rem;
  border-top: 1px solid var(--ash);
  border-bottom: 1px solid var(--ash);
}

.option-title {
  font-size: var(--fs-md);
  font-weight: 400;
}

.option-subtext {
  font-size: var(--fs-sm);
  color: var(--gray);
  font-weight: 400;
}

.snapshot-suffix {
  padding-left: 1ch;
  color: var(--gray);
}

.work-template-clear-button {
  font-size: var(--fs-md);
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 0.5rem;

  &:hover {
    cursor: pointer;
  }
}

.search-group-header {
  font-size: var(--fs-sm);
  color: var(--gray);
  padding: 0.625rem;
}
