@import '../../../../styles/colors.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/layout.css';

:root {
  --headerOffset: 6.875rem;
}

.spinner-container {
  position: relative;
  height: 50vh;
}

.container {
  width: 100%;
  overflow: scroll;
}

.header {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.25rem;
  min-height: 3.125rem;
  border-bottom: 1px solid var(--ash);
}

.alert-container {
  padding: 0.5rem 1.25rem;
  width: 43rem;
}

.title-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.title {
  font-size: var(--fs-xl);
  color: var(--dark);
  font-weight: 500;
  margin: 0 0.625rem;
}

.subtitle {
  color: var(--dark);
  font-size: var(--fs-md);
  font-weight: 500;
}

.details-container {
  padding: 1rem 2rem;
  border-bottom: 1px solid var(--ash);
}

.detail-content {
  display: flex;
  flex-direction: row;
  margin: 1rem -1.5rem;
}

.detail {
  margin: 0 1.5rem;
  font-size: var(--fs-sm);
  color: var(--dim);
}

.detail-value {
  color: var(--dark);
  font-size: var(--fs-md);
}

.inputs-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.inspection-container {
  padding: 1rem 2rem;

  &.checked {
    border-bottom: 1px solid var(--ash);
  }
}

.label {
  color: var(--dark);
  font-size: var(--fs-md);
  margin: 1.5rem 0;
}

.distress-container {
  margin-bottom: 1rem;
}

.distress-label {
  color: var(--dark);
  font-size: var(--fs-md);
}

.distress-description {
  color: var(--gray);
  font-size: var(--fs-sm);
  line-height: var(--fs-sm);
  margin-bottom: 0.3125rem;
}

.distress-subtext-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.distress-bullet {
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background: var(--gray);
  margin-left: 0.5rem;
}

.distress-subtext {
  color: var(--gray);
  font-size: var(--fs-sm);
  margin-left: 1rem;
  line-height: var(--fs-lg);
}

.no-distress-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.no-distress-label {
  color: var(--dark);
  font-size: var(--fs-md);
}

.table-header {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  min-height: 2.5rem;
  background-color: var(--fog);
  color: var(--gray);
  font-size: var(--fs-sm);
  font-weight: 500;
  text-transform: uppercase;
  border-top: 1px solid var(--silver);
  border-bottom: 1px solid var(--silver);
  padding: 0 1.875rem;
}

.column {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.3125rem;
  box-sizing: border-box;
  margin-right: 1rem;

  &.distress {
    width: 21.875rem;
  }

  &.severity {
    width: 8rem;
  }

  &.quantity {
    width: 6rem;
  }

  &.notes {
    width: 100%;
    flex: 1 1 0;
  }

  &.photos {
    width: 3.125rem;
  }

  &.delete {
    width: 3.125rem;
  }

  &.summary {
    font-size: var(--fs-md);
    color: var(--dark);
  }

  &.distress-summary {
    width: 17.5rem;
    padding: 0.5rem 0;
  }

  &.severity-summary {
    width: 10rem;
    padding: 0.5rem 0;
  }

  &.quantity-summary {
    width: 100%;
    flex: 1 1 0;
    padding: 0.5rem 0;
  }
}

.table-rows-container {
  overflow-y: auto;
  overflow-x: hidden;
}

.table-row {
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--silver);
  padding: 0.5rem 1.875rem;
}

.add-distress-button {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 0 0.3125rem;
  color: var(--gray);
  margin-left: 2rem;
  margin-top: 1rem;
}

.column-suffix-text {
  padding-left: 5px;
  text-transform: uppercase;
  color: var(--gray);

  &.blue {
    color: var(--blue);
  }
}

.photo-modal-content {
  flex: 1 1 auto;
  border-top: 1px solid var(--ash);
  border-bottom: 1px solid var(--ash);
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  overflow-y: auto;
  max-height: var(--formModuleMediaModalHeight);
}
