@import '../../../styles/colors.css';
@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';

.list-popover {
  width: 17.25rem;
}

.list-popover ul {
  max-height: 344px;
  overflow-y: auto;
}

.list-popover-header {
  line-height: 2rem;
  padding: 0 1rem;
  font-size: var(--fs-sm);
  color: var(--gray);
}

.list-button {
  gap: 1rem;
}

.list-button-text {
  flex: 1;
  color: var(--black);
  word-break: break-all;
}

.list-popover-footer {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-top: 1px solid var(--ash);
}

.list-popover-footer-icon {
  margin-right: 0.5rem;
}
