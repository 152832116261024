@import '../../../styles/colors.css';
@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';

.option-input-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1rem;
}

.option-number {
  padding-right: 1rem;
  white-space: nowrap;
  color: var(--black);
  font-size: var(--fs-md);
}
