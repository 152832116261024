@import '../../styles/layout.css';
@import '../../styles/colors.css';
@import '../../styles/fonts.css';

.icon-container {
  display: inline-block;
}

.modal-container {
  padding: 2rem;
}

.modal-spinner-container {
  padding: 2.5rem;
  height: 20vh;
}

.schema-menu-item-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
}

.schema-menu-item-title {
  padding-left: 0.3125rem;
}
