@import '../../styles/layout.css';
@import '../../styles/fonts.css';
@import '../../styles/colors.css';

.status-pill-container {
  cursor: pointer;
}

.priority-menu-item-row-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--fs-md);
  color: var(--dark);
  font-weight: 500;
}

.priority-menu-item-label {
  padding-left: 1em;

  &.selected {
    color: var(--blue);
  }
}
