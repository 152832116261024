@import '../../../../styles/layout.css';
@import '../../../../styles/fonts.css';
@import '../../../../styles/colors.css';

.content-container {
  height: 34.375rem;
  overflow: auto;
  padding: 0 1.25rem;
}

.search-content {
  box-sizing: border-box;
  padding-bottom: 1.25rem;
}

.search-box {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.selected-container {
  border-bottom: 1px solid var(--ash);
  margin-bottom: 1.25rem;
}

.selected-title {
  text-transform: uppercase;
  color: var(--gray);
  font-size: var(--fs-sm);
  padding-bottom: 1rem;
  font-weight: 500;
}

.cart-tile-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.side-tile-container {
  display: flex;
  align-items: center;
}

.tile-title {
  font-size: var(--fs-md);
  font-weight: 500;
  color: var(--dim);
  padding-left: 1rem;
}

.tile-subtext {
  font-size: var(--fs-md);
  color: var(--gray);
  padding-left: 1rem;

  &.path {
    text-transform: capitalize;
  }
}

.quantity-textfield-container {
  width: 6.25rem;
}

.close-icon {
  cursor: pointer;
}
