@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.range-container {
  display: flex;
  flex-flow: row wrap;
  column-gap: 0.5rem;

  & .title {
    color: var(--dark);
    font-size: var(--fs-md);
    width: 100%;
    margin-top: 2rem;
  }

  & .half {
    flex-basis: 40%;
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
  }
}

.units-text {
  color: var(--dark);
  padding: 0 1ch;
  align-self: flex-end;
  margin-bottom: 0.5em;
}
