@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.info-tab-container {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
}

.left-pane {
  width: 50%;
  height: 100%;
  box-shadow: var(--muiDropdownBoxShadow);
  display: flex;
  flex-direction: column;
}

.right-pane {
  width: 50%;
  height: 100%;
}

.info-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 3.125rem;
  padding-left: 1.25rem;
  padding-right: 0.5rem;
  font-size: var(--fs-md);
  border-bottom: 1px solid var(--ash);
}

.attribute-list-container {
  padding: 1em 3.75em;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.attribute-list-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 0.3125em 0;
}

.attribute-label {
  color: var(--gray);
  width: 40%;

  & .edit {
    padding-top: 0.5rem;
  }
}

.attribute-value {
  width: 60%;
  color: var(--dark);
  white-space: pre-line;
}

.spinner-container {
  position: absolute;
  top: 1rem;
  right: 7rem;
  width: 100%;
}

.group-value {
  margin-bottom: 0.5rem;
}

.attribute-group-value {
  margin-top: 0.5rem;
}
