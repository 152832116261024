@import '../../../styles/colors.css';
@import '../../../styles/layout.css';
@import '../../../styles/fonts.css';

.option-input-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1rem;
}

.option-number {
  padding-right: 1rem;
  white-space: nowrap;
  color: var(--black);
  font-size: var(--fs-md);
}

.tile-container {
  display: flex;
  align-items: center;
  width: 90.5%;
  justify-content: space-between;
  padding: 1rem 0;
}

.selected-folder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 0 1 auto;
}

.choose-button {
  color: var(--blue);
  cursor: pointer;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 1 auto;
  gap: 1rem;
}

.modal-header > span {
  cursor: pointer;
}

.embed-label {
  padding: 12px 0;
}

.form-fields-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
