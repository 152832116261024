@import '../../../styles/layout.css';
@import '../../../styles/colors.css';
@import '../../../styles/fonts.css';

.modal-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.page-title {
  font-size: var(--fs-lg);
  color: var(--dark);
  font-weight: 500;
  padding-bottom: 0.5rem;
}

.page-content {
  font-size: var(--fs-md);
  color: var(--black);
  padding-bottom: 0.5rem;
}

.page-image {
  display: flex;
  justify-content: center;
}

.page-navigation {
  display: flex;
  justify-content: center;
}
